import { Injectable } from '@angular/core';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { getCurrentUserSelector } from '../../store/user/user.selector';
import { switchMap, take } from 'rxjs/operators';
import { PlatformCardData } from '../../main/users/platform-list/platform-card/platform-card.component';
import { RetailerCenterNavigation } from 'app/navigation/navigation-routes/retailer/retailer-center.navigation';

@Injectable({
  providedIn: 'root',
})
export class SynceeAppsService {
  private baseUrl = '/Apps/Syncee';
  constructor(private springRestService: SpringRestService, private store: Store<AppState>) {}

  getAllApps(): Observable<SynceeAppsDto[]> {
    return this.springRestService.get(MicroserviceNames.USER, this.baseUrl, undefined, true);
  }

  getAllInstalledByUser(): Observable<InstalledAppsAndGroups> {
    return this.store.select(getCurrentUserSelector).pipe(
      take(1),
      switchMap((user) => {
        const url = `${this.baseUrl}/${user.id}`;
        return this.springRestService.get(MicroserviceNames.USER, url, undefined, true);
      })
    );
  }

  installByUser(synceeAppIds: number[]): Observable<any> {
    return this.store.select(getCurrentUserSelector).pipe(
      switchMap((user) => {
        const url = `${this.baseUrl}/${user.id}/Install`;
        return this.springRestService.post(MicroserviceNames.USER, url, synceeAppIds, undefined, true);
      })
    );
  }

  deleteByUser(synceeAppIds: number[]): Observable<any> {
    return this.store.select(getCurrentUserSelector).pipe(
      switchMap((user) => {
        const url = `${this.baseUrl}/${user.id}/Delete`;
        return this.springRestService.post(MicroserviceNames.USER, url, synceeAppIds, undefined, true);
      })
    );
  }

  mapToPlatformCardData(apps: SynceeAppsDto[]): PlatformCardData[] {
    return apps.map((app) => this.buildPlatformCardData(app));
  }

  private buildPlatformCardData(app: SynceeAppsDto): PlatformCardData {
    return {
      id: app.id,
      type: app.type,
      title: `SYNCEE_APPS.${app.type}.TITLE`,
      description: `SYNCEE_APPS.${app.type}.DESCRIPTION`,
      descriptionLong: `SYNCEE_APPS.${app.type}.DESCRIPTION_LONG`,
      url: this.getUrl(app.type),
      learnMoreUrlRetailer: this.getLearnMoreUrlRetailer(app.type),
      logoIconPath: this.getLogoIconPath(app.type),
      // logoImagePath: this.getLogoImagePath(app.type),
      needDialogForInstall: true,
    };
  }

  private getUrl(type: SynceeAppType): string {
    switch (type) {
      case SynceeAppType.ALIBABA: {
        return RetailerCenterNavigation.ALIBABA.ALIBABA_CONNECTION;
      }
      case SynceeAppType.DATAFEED_MANAGER_EXPORT: {
        return '/retailer-export-tasks';
      }
      case SynceeAppType.DATAFEED_MANAGER_IMPORT: {
        return '/retailer-import-tasks';
      }
      case SynceeAppType.DATAFEED_MANAGER_VARIANTS_UPDATE: {
        return '/retailer-variants-update-tasks';
      }
    }
  }

  private getLearnMoreUrlRetailer(type: SynceeAppType): string {
    switch (type) {
      case SynceeAppType.ALIBABA: {
        return 'https://help.syncee.co/en/?q=alibaba';
      }
      case SynceeAppType.DATAFEED_MANAGER_EXPORT: {
        return 'https://help.syncee.co/en/articles/4201300-product-export-how-to-make-a-product-export-task';
      }
      case SynceeAppType.DATAFEED_MANAGER_IMPORT: {
        return 'https://help.syncee.co/en/articles/3978535-how-to-import-products-by-using-the-syncee-datafeed-manager-overview';
      }
      case SynceeAppType.DATAFEED_MANAGER_VARIANTS_UPDATE: {
        return 'https://help.syncee.co/en/articles/4393684-how-to-update-product-variants-in-your-store';
      }
    }
  }

  private getLogoIconPath(type: SynceeAppType): string {
    switch (type) {
      case SynceeAppType.ALIBABA: {
        return '/assets/images/syncee-apps/alibaba_68x68.png';
      }
      case SynceeAppType.DATAFEED_MANAGER_EXPORT: {
        return '/assets/images/syncee-apps/datafeed_manager_68x68.png';
      }
      case SynceeAppType.DATAFEED_MANAGER_IMPORT: {
        return '/assets/images/syncee-apps/datafeed_manager_68x68.png';
      }
      case SynceeAppType.DATAFEED_MANAGER_VARIANTS_UPDATE: {
        return '/assets/images/syncee-apps/datafeed_manager_68x68.png';
      }
    }
  }
}

export enum SynceeAppType {
  ALIBABA = 'ALIBABA',
  DATAFEED_MANAGER_EXPORT = 'DATAFEED_MANAGER_EXPORT',
  DATAFEED_MANAGER_IMPORT = 'DATAFEED_MANAGER_IMPORT',
  DATAFEED_MANAGER_VARIANTS_UPDATE = 'DATAFEED_MANAGER_VARIANTS_UPDATE',
}

export interface SynceeAppsDto {
  id: number;
  synceeAppGroupsId: number;
  type: SynceeAppType;
  url: string;
  order: number;
}

export interface InstalledSynceeAppDto extends SynceeAppsDto {
  date: Date;
}

export interface SynceeAppGroupsDto {
  id: number;
  type: string;
  order: number;
  collapsable: boolean;
}

export interface InstalledAppsAndGroups {
  installedGroups: SynceeAppGroupsDto[];
  installedApps: InstalledSynceeAppDto[];
}
