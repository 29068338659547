import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RolesEnum } from '../../../vo/roles/roles';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  @Input() role: RolesEnum;
  @Input()
  overViewRecords: OverviewRecord[];
  @Input() currency$: Observable<string>;

  constructor() {}

  ngOnInit(): void {}

  protected readonly RolesEnum = RolesEnum;
}

export interface OverviewRecord {
  title: string;
  info: string;
  value: number;
  unitOfMeasurement: UnitOfMeasurement;
  tooltip?: string;
}

export type UnitOfMeasurement = 'CURRENCY' | 'PERCENT';
