<mat-card
  fxLayout="column"
  fxFlex="1 0 0"
  class="plan-upsell-box-container"
  [class.featured]="featured"
  [class.isAnnual]="isAnnual"
>
  <app-card-corner-badge
    *ngIf="hasCornerBadge"
    position="top-right"
    [bgColor]="cornerBadgeBgColor"
    [textColor]="cornerBadgeTextColor"
    [text]="cornerBadgeText ?? ('PLAN_UPSELL_BOXES.MOST_POPULAR' | translate)"
  ></app-card-corner-badge>
  <div fxLayout="column">
    <div class="pt-24 pb-40 block-container" fxLayout="column" fxLayoutGap="36px">
      <app-plan-upsell-box-header
        fxFlex="0 0 80px"
        [title]="plan.name | translate"
        [description]="plan.description | translate"
      ></app-plan-upsell-box-header>
      <app-plan-upsell-box-pricing
        [price]="isAnnual ? plan.amount.annual.perMonth : plan.amount.monthly"
        [isAnnual]="isAnnual"
        [trialDays]="plan.trialDays"
      ></app-plan-upsell-box-pricing>
    </div>
    <button
      (click)="buttonClicked.emit(plan)"
      mat-flat-button
      class="w-100p"
      [ngClass]="featured ? 'syncee-blue-button' : 'syncee-white-button'"
    >
      {{ buttonText ?? ('PLAN_UPSELL_BOXES.BUTTON.SUBSCRIBE' | translate) }}
    </button>
    <div class="pt-28 pb-28 block-container">
      <app-plan-upsell-box-feature-list [features]="plan.features"></app-plan-upsell-box-feature-list>
    </div>
  </div>
</mat-card>
<ng-template #currentPlan>
  <div class="current-plan">{{ 'PLANS.CURRENT_PLAN' | translate }}</div>
</ng-template>
