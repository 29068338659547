import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../app.state';
import { CreateEmptyCatalogService } from '../../../../../service/catalog/create-empty-catalog.service';
import { EcomVO } from '../../../../../service/ecom/ecom.service';
import { retailerEcomsSelector } from '../../../../../store/ecom/ecom.selector';
import { ClearRecentlyAddedProductsAction } from '../../../../../store/rcatalogs/rcatalogs.action';
import { Router } from '@angular/router';
import { RetailerCenterNavigation } from 'app/navigation/navigation-routes/retailer/retailer-center.navigation';
import { RetailerImportListService } from '../../../../../main/retailer-import-list/retailer-import-list.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-catalog-overlay',
  templateUrl: './catalog-overlay.component.html',
  styleUrls: ['./catalog-overlay.component.scss'],
})
export class CatalogOverlayComponent implements OnInit, OnDestroy {
  @Output() closeOverlay = new EventEmitter<void>();
  closeEcomSelector: Subject<void>;
  ecoms: Observable<EcomVO[]>;
  public readonly navigateToRetailerImportList = `/${RetailerCenterNavigation.IMPORT_LIST}`;

  constructor(
    private store: Store<AppState>,
    private createEmptyCatalogService: CreateEmptyCatalogService,
    private router: Router,
    private retailerImportListService: RetailerImportListService
  ) {
    this.closeEcomSelector = new Subject<void>();
    this.ecoms = this.store.select(retailerEcomsSelector);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.store.dispatch(new ClearRecentlyAddedProductsAction());
  }

  createNewCatalog(): void {
    this.createEmptyCatalogService
      .create()
      .pipe(filter((value: boolean) => !!value))
      .subscribe((): void => {
        this.retailerImportListService.mutateImportListItems();
      });
    this.closeOverlay.emit();
  }

  onMyStoresClicked(): void {
    this.router.navigate([`${RetailerCenterNavigation.SETTINGS.MY_SHOPS}`]).then(() => this.closeOverlay.emit());
  }

  public handleUpgrade(): void {
    this.closeOverlay.emit();
  }
}
