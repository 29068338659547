import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { camelCase } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppState } from '../../../app.state';
import {
  SupplierDashboardAnalyticsService,
  SupplierTimelineRangeDto,
} from '../../../service/analytics/supplier-dashboard-analytics.service';
import { CurrencyService } from '../../../service/currency-service/currency.service';
import { selectedSupplierEcomSelector } from '../../../store/ecom/ecom.selector';
import { StatisticType } from '../orders-chart/orders-chart.component';
import { SupplierDashboardService } from '../services/supplier-dashboard.service';

@Component({
  selector: 'app-supplier-numeric-card-list',
  templateUrl: './supplier-numeric-card-list.component.html',
  styleUrls: ['./supplier-numeric-card-list.component.scss'],
})
export class SupplierNumericCardListComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  dateFrom: Date;
  @Input()
  dateTo: Date;
  @Input()
  hidePercentage = false;
  dataSet: SupplierTimelineRangeDto;
  beforeDataSet: SupplierTimelineRangeDto;
  currency: Observable<string>;
  currentLang = 'en';

  private unsubscribeAll: Subject<void>;

  supplierCurrency$: Observable<string>;

  constructor(
    private supplierDashboardAnalyticsService: SupplierDashboardAnalyticsService,
    private store: Store<AppState>,
    private _supplierDashboardService: SupplierDashboardService
  ) {
    this.unsubscribeAll = new Subject<void>();
    this.supplierCurrency$ = this._supplierDashboardService.getSupplierPaymentCurrency();
  }

  ngOnInit(): void {
    this.currency = this.store.select(selectedSupplierEcomSelector).pipe(
      takeUntil(this.unsubscribeAll),
      map((selectedEcom) => CurrencyService.getCurrency(selectedEcom))
    );

    this.getDailyAnalytics();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.dateFrom && !changes.dateFrom.firstChange) || (changes.dateTo && !changes.dateTo.firstChange)) {
      this.getDailyAnalytics();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  getPercentage(type: StatisticType): number {
    if (!this.beforeDataSet || this.hidePercentage) {
      return null;
    }
    const propertyName = camelCase(type.toLowerCase());

    if (this.beforeDataSet[propertyName] !== 0) {
      return this.dataSet[propertyName] / this.beforeDataSet[propertyName];
    }

    switch (true) {
      case this.dataSet[propertyName] > 0:
        return 1;
      case this.dataSet[propertyName] === 0:
        return 0;
      default:
        return -1;
    }
  }

  private getDailyAnalytics(): void {
    this.supplierDashboardAnalyticsService
      .getDailyOrderAnalytics(this.dateFrom, this.dateTo, 1)
      .subscribe((dataSets) => (this.dataSet = dataSets[0]));
    this.getDailyAnalyticsBeforeRange();
  }

  private getDailyAnalyticsBeforeRange(): void {
    if (!!this.dateFrom) {
      const to = !!this.dateTo ? this.dateTo.getTime() : new Date().getTime();
      const dateBeforeFrom = new Date(this.dateFrom.getTime() - (to - this.dateFrom.getTime()));
      this.supplierDashboardAnalyticsService
        .getDailyOrderAnalytics(dateBeforeFrom, this.dateFrom, 1)
        .subscribe((dataSets) => (this.beforeDataSet = dataSets[0]));
    }
  }
}

// export interface NumericCardData {
//   title: string;
//   value: number;
//   percentage: number;
//   unitOfMeasurement: string;
//   digitsInfo: string;
//   type: StatisticType;
// }
