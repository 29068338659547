<form [formGroup]="supplierPublicInformationService.form" fxLayout="column" fxLayoutGap="20px" class="w-100p">
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" fxLayoutGap.lt-sm="12px">
    <mat-form-field fxFlex="1 0 0" appearance="fill" class="input-without-padding-except-error">
      <mat-label>{{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.COMPANY_NAME' | translate }}</mat-label>
      <input matInput [formControlName]="supplierPublicInformationService.inputNames.COMPANY_NAME" type="text" />
      <mat-error>{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="1 0 0" appearance="fill" class="input-without-padding-except-error">
      <mat-label>{{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.COMPANY_EMAIL' | translate }}</mat-label>
      <input matInput [formControlName]="supplierPublicInformationService.inputNames.COMPANY_EMAIL" type="text" />
      <mat-error *ngIf="supplierPublicInformationService.companyEmail.invalid">
        {{
          supplierPublicInformationService.companyEmail.errors?.email
            ? ('FORM_VALIDATORS.EMAIL' | translate)
            : ('FORM_VALIDATORS.REQUIRED' | translate)
        }}
      </mat-error>
    </mat-form-field>
  </div>
  <mat-form-field appearance="fill" class="input-without-padding-except-error">
    <mat-label>{{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.WEBSITE' | translate }}</mat-label>
    <input matInput [formControlName]="supplierPublicInformationService.inputNames.WEBSITE" type="text" />
    <mat-error>{{ 'FORM_VALIDATORS.REQUIRED' | translate }}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill" class="input-without-padding-except-error">
    <mat-label>{{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.DESCRIPTION' | translate }}</mat-label>
    <textarea
      matInput
      [formControlName]="supplierPublicInformationService.inputNames.DESCRIPTION"
      [placeholder]="'SUPPLIER_PUBLIC_INFORMATION_FORM.DESCRIPTION' | translate"
      cdkTextareaAutosize
      cdkAutosizeMinRows="3"
      cdkAutosizeMaxRows="6"
    ></textarea>
    <mat-error *ngIf="!!supplierPublicInformationService.description.errors?.['required']">{{
      'FORM_VALIDATORS.REQUIRED' | translate
    }}</mat-error>
    <mat-error *ngIf="!!supplierPublicInformationService.description.errors?.['minlength']">{{
      'FORM_VALIDATORS.MIN_LENGTH' | translate : { amount: 500 }
    }}</mat-error>
    <mat-error *ngIf="!!supplierPublicInformationService.description.errors?.['maxlength']">{{
      'FORM_VALIDATORS.MAX_LENGTH' | translate : { amount: 1500 }
    }}</mat-error>
  </mat-form-field>
  <mat-form-field class="input-without-padding-except-error custom-form-field-wrapper" appearance="standard">
    <app-single-country-selector-form-field
      [label]="'SUPPLIER_PUBLIC_INFORMATION_FORM.MAIN_WAREHOUSE' | translate"
      [formControlName]="supplierPublicInformationService.inputNames.MAIN_WAREHOUSE_LOCATION"
    >
    </app-single-country-selector-form-field>
    <mat-error *ngIf="supplierPublicInformationService.mainWarehouseLocation.hasError('required')">
      {{ 'FORM_VALIDATORS.REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field
    appearance="standard"
    class="input-without-padding-except-error custom-form-field-wrapper phone-form-field-wrapper"
  >
    <app-phone-input-form-field
      [formControlName]="supplierPublicInformationService.inputNames.PHONE"
    ></app-phone-input-form-field>
    <mat-error *ngIf="!!supplierPublicInformationService.phone.errors">{{
      'FORM_VALIDATORS.REQUIRED' | translate
    }}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill" class="input-without-padding-except-error">
    <mat-label>{{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.DATE' | translate }}</mat-label>
    <input
      matInput
      type="number"
      min="0"
      [max]="currentYear"
      [formControlName]="supplierPublicInformationService.inputNames.DATE"
    />
    <mat-error *ngIf="supplierPublicInformationService.date.errors?.['required']">{{
      'FORM_VALIDATORS.REQUIRED' | translate
    }}</mat-error>
    <mat-error
      *ngIf="supplierPublicInformationService.date.errors?.['min'] || supplierPublicInformationService.date.errors?.['max']"
    >
      {{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.INVALID_DATE' | translate }}
    </mat-error>
  </mat-form-field>
  <div fxLayout="column" fxLayoutGap="12px">
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <div [innerHTML]="'SUPPLIER_PUBLIC_INFORMATION_FORM.SOCIAL.TITLE' | translate"></div>
    </div>
    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      class="social-platforms"
      [formGroupName]="supplierPublicInformationService.inputNames.SOCIAL_LINKS"
    >
      <mat-form-field
        *ngFor="let socialPlatform of supplierPublicInformationService.socialPlatforms"
        class="social-platform"
        appearance="fill"
      >
        <mat-label>{{ socialPlatform.name | titlecase }}</mat-label>
        <input matInput [formControlName]="socialPlatform.name" type="text" />
      </mat-form-field>
    </div>
  </div>
</form>
<div fxLayoutAlign="center center">
  <button *ngIf="hasSaveButton" mat-flat-button color="primary" (click)="handleInnerSaveClicked()">
    {{ 'SUPPLIER_PUBLIC_INFORMATION_FORM.SAVE' | translate }}
  </button>
</div>
