import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TaskWizardSampleService } from '../../services/task-wizard-sample.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductField } from '../../../../../service/taskwizard/taskwizard.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-prod-sample-dialog',
  templateUrl: './prod-sample-dialog.component.html',
  styleUrls: ['./prod-sample-dialog.component.scss'],
})
export class ProdSampleDialogComponent implements OnInit, OnDestroy {
  productSample: ProductField[];
  selectedField: string;
  error = false;
  private unsubscribeAll: Subject<void>;
  index: BehaviorSubject<number>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ProdSampleDialogData,
    public taskWizardSampleService: TaskWizardSampleService,
    private matDialogRef: MatDialogRef<ProdSampleDialogComponent, ProdSampleDialogResult>
  ) {
    this.unsubscribeAll = new Subject<void>();
    this.index = new BehaviorSubject<number>(0);
  }

  ngOnInit(): void {
    this.subscribeToIndexChange();
    this.subscribeToSamples();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private subscribeToSamples(): void {
    this.taskWizardSampleService.productSample
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((sample) => (this.productSample = sample));
  }

  private subscribeToIndexChange(): void {
    this.index
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((index) =>
        this.taskWizardSampleService.updateProductSample(this.data.catalogId, this.data.fileId, index)
      );
  }

  onSelect(): void {
    if (!this.selectedField) {
      this.error = true;
    }
    this.matDialogRef.close({ selectedFieldName: this.selectedField, fileId: this.data.fileId });
  }

  nextProduct(): void {
    this.index.next(this.index.value + 1);
  }

  prevProduct(): void {
    this.index.next(this.index.value - 1);
  }
}

export interface ProdSampleDialogResult {
  selectedFieldName: string;
  fileId: number;
}

export interface ProdSampleDialogData {
  fileId: number;
  catalogId: number;
}
