import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MARKETPLACE_NAVIGATION } from 'app/navigation/navigation-routes/common/marketplace.navigation';
import { Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { AppState } from '../../../app.state';
import { SubscriptionHelperService } from '../../../main/billing-new/service/subscription-helper.service';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { MarketplaceEcomService } from '../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { SubscriptionFetchStartAction } from '../../../store/subscription/subscription.actions';
import { isLoadingSelector } from '../../../store/subscription/subscription.selector';
import { SYNCEE_PRODUCTS } from '../../../utils/Constants';
import { Utils } from '../../../utils/utils';
import { ConditionLoadingDirective } from '../../directives/condition-loading.directive';
import { CurrencyTransformPipe } from '../../pipes/currency-transform-pipe.pipe';
import { SingleAlertWithIconComponent } from '../single-alert-with-icon/single-alert-with-icon.component';

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    ConditionLoadingDirective,
    SingleAlertWithIconComponent,
    CurrencyTransformPipe,
  ],
})
export class ThankYouPageComponent implements OnInit, OnDestroy {
  @Input() ecomType: string;
  @Input() ecomId: string;
  @Input() selectedMarketplacePlanId: string = null;
  readonly navigateToMarketplace = MARKETPLACE_NAVIGATION.DEFAULT_PRODUCTS_PATH;

  public isLoaded = false;
  public errorMessage: string;
  public ecom: EcomVO;
  public synceeProducts = SYNCEE_PRODUCTS;
  public hasFreeTrial = false;
  public isOnlyMarketplace: boolean;
  private maxTry = 3;
  private _unsubscribeAll: Subject<void>;

  constructor(
    private router: Router,
    private subscriptionHelperService: SubscriptionHelperService,
    private store: Store<AppState>,
    private ecomMPService: MarketplaceEcomService,
    private _translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getPlanData();
    this.isOnlyMarketplace = this.subscriptionHelperService.isOnlyMarketplace(this.ecomType);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  resetData(): void {
    this.store.dispatch(new SubscriptionFetchStartAction({ ecomId: Number(this.ecomId), role: 2 }));
  }

  getPlanData(): void {
    this.resetData();
    this.store
      .select(isLoadingSelector)
      .pipe(takeWhile((loading) => loading, true))
      .subscribe(
        (loading) => {
          if (!loading) {
            let tryNumber = 0;
            this.ecomMPService
              .getEcomWithSubscriptionFromStore()
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((ecomVO: EcomVO) => {
                if (this.selectedMarketplacePlanId) {
                  if (this.selectedMarketplacePlanId != ecomVO.subscriptions.rmp.planId.toString()) {
                    if (tryNumber < this.maxTry) {
                      tryNumber = tryNumber + 1;
                      setTimeout(() => {
                        this.resetData();
                      }, 10000);
                    } else {
                      this.errorMessage = this._translateService.instant(
                        'BILLING.SUBSCRIPTION.THANK_YOU_PAGE.PLAN_ERROR'
                      );
                      this.isLoaded = true;
                    }
                  } else {
                    this.setEcom(ecomVO);
                  }
                } else {
                  this.setEcom(ecomVO);
                }
              });
          }
        },
        () => {
          this.errorMessage = this._translateService.instant('BILLING.SUBSCRIPTION.THANK_YOU_PAGE.PLAN_ERROR');
          this.isLoaded = true;
        },
        () => {}
      );
  }

  setEcom(ecom: EcomVO): void {
    this.ecom = ecom;
    this.hasFreeTrial =
      !Utils.isNullOrUndefined(this.ecom.subscriptions.rmp.freeTrialUntil) &&
      Date.parse(this.ecom.subscriptions.rmp.freeTrialUntil) > Date.now() &&
      (this.ecom.subscriptions.rmp.planId !== 120 || this.ecom.subscriptions.df.planId !== 100);
    this.isLoaded = true;
  }

  goToSearchProducts(): void {
    this.router.navigate(['/explore-products']);
  }
  calculatePrice(type, amount, originalPrice): number {
    if (originalPrice != 0) {
      switch (type) {
        case 'fixed':
          return originalPrice - amount;
        case 'percentage':
          return Utils.truncateByDecimalPlace(originalPrice - (originalPrice * amount) / 100, 2);
        default:
          return originalPrice;
      }
    } else {
      return originalPrice;
    }
  }
}
