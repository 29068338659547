import { Routes } from '@angular/router';

export const STATIC_PAGE_ROUTES: Routes = [
  {
    path: 'faq',
    loadChildren: () => import('app/main/faq-page/faq-page.module').then((m) => m.FaqPageModule),
    data: {
      hideSidebar: true,
      contentContainer: 'full-width-without-padding',
    },
  },
  {
    path: 'affiliates',
    loadChildren: () =>
      import('app/main/affiliate-landing/affiliate-landing.module').then((m) => m.AffiliateLandingModule),
    data: {
      hideSidebar: true,
      contentContainer: 'full-width-without-padding',
    },
  },
  {
    path: 'dropshipping',
    loadComponent: () => import('app/main/dropshipping/dropshipping.component').then((m) => m.DropshippingComponent),
    data: {
      hideSidebar: true,
      contentContainer: 'full-width-without-padding',
    },
  },
  {
    path: 'supplier-referral',
    loadComponent: () =>
      import('../../main/static/pages/supplier-referrals/supplier-referrals.component').then(
        (c) => c.SupplierReferralsComponent
      ),
    data: { hideSidebar: true, contentContainer: 'full-width-without-padding' },
  },
  {
    path: 'retailer-referral',
    loadComponent: () =>
      import('app/main/static/pages/retailer-referrals/retailer-referrals.component').then(
        (c) => c.RetailerReferralsComponent
      ),
    data: { hideSidebar: true, contentContainer: 'full-width-without-padding' },
  },
  {
    path: 'about-us',
    loadComponent: () => import('app/main/static/pages/about-us/about-us.component').then((c) => c.AboutUsComponent),

    data: {
      hideSidebar: true,
      contentContainer: 'full-width-without-padding',
    },
  },
  {
    path: 'suppliers',
    loadComponent: () =>
      import('app/main/static/pages/sell-on-syncee/sell-on-syncee.component').then((c) => c.SellOnSynceeComponent),
    data: { hideSidebar: true, contentContainer: 'full-width-without-padding' },
  },
  {
    path: 'integration',
    loadChildren: () =>
      import('app/main/static/pages/integrations/integrations.module').then((m) => m.IntegrationsModule),
    data: {
      hideSidebar: true,
      contentContainer: 'full-width-without-padding',
    },
  },
  {
    path: 'pricing',
    loadChildren: () =>
      import('app/main/static/pages/static-pricing/static-pricing.module').then((m) => m.StaticPricingModule),
    data: {
      hideSidebar: true,
      contentContainer: 'full-width-without-padding',
    },
  },
  {
    path: 'contact',
    loadComponent: () =>
      import('app/main/static/pages/contact-us/contact-us.component').then((m) => m.ContactUsComponent),
    data: {
      hideSidebar: true,
      contentContainer: 'full-width-without-padding',
    },
  },
];
