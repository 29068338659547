<div
  class="header"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayout.gt-xs="row"
  fxLayoutAlign.gt-xs="space-between center"
>
  <div class="logo pb-24 mb-sm-0" fxLayout="row" fxLayoutAlign="start center" fxFlex="30">
    <!--<mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">-->
    <!--assessment-->
    <!--</mat-icon>-->
    <!--        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{pageTitle | translate}}</span>-->
  </div>
</div>

<app-supplier-task-problem-alert></app-supplier-task-problem-alert>
<div
  *ngIf="taskListType === 'mytasks'; else addTaskBtn"
  class="py-12"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
    <button
      mat-button
      [class.syncee-black-button]="toggleView"
      [class.syncee-black-flat-button]="!toggleView"
      (click)="toggleViewClick(false)"
      matTooltip="Tasks list"
      [matTooltipPosition]="'after'"
    >
      <mat-icon>play_circle_outline</mat-icon>
    </button>
    <button
      mat-button
      [class.syncee-black-button]="!toggleView"
      [class.syncee-black-flat-button]="toggleView"
      (click)="toggleViewClick(true)"
      matTooltip="Task History Timeline"
      [matTooltipPosition]="'after'"
    >
      <mat-icon>timeline</mat-icon>
    </button>
  </div>
  <button mat-button class="syncee-blue-button" *ngIf="!toggleView" (click)="openDialog()">
    {{ addTaskBtnTitle }}
  </button>
</div>

<ng-template #addTaskBtn>
  <div
    class="py-20"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-md="center center"
    fxLayoutAlign.lt-sm="center stretch"
    fxLayoutGap="20px"
  >
    <div fxFlex="50" fxFlex.lt-md="1 0 0" class="filter-container">
      <mat-form-field
        appearance="fill"
        *ngIf="domains.length > 1 && taskListType !== 'mytasks'"
        class="w-100p input-without-padding"
      >
        <mat-label>{{ 'RETAILER_IMPORT_TASKS.FILTER' | translate }}</mat-label>
        <mat-select (selectionChange)="applyFilter($event.value)">
          <mat-option [value]="0">{{ 'RETAILER_IMPORT_TASKS.ALL' | translate }}</mat-option>
          <mat-option *ngFor="let domain of domains" [value]="domain.id">
            {{ domain.domain }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="50" fxFlex.lt-md="1 0 0" class="text-right add-new-task-btn-container">
      <button mat-button class="syncee-blue-button" *ngIf="!toggleView" (click)="openDialog()">
        {{ addTaskBtnTitle }}
        <!--<mat-icon matSuffix>add</mat-icon>-->
      </button>
    </div>
  </div>
</ng-template>

<div id="taskTable" *ngIf="!toggleView; else timeLine">
  <app-alert *ngIf="allCatalogRejected" [type]="'danger'" [text]="getRejectedText()"></app-alert>
  <!-- [@animate]="{value:'*',params:{opacity:'0',duration:'300ms'}}" -->
  <div class="py-12 pt-0">
    <div
      class="mb-12"
      *ngIf="
        actualRole === '2' && selectedEcom && selectedEcom.subscriptions && selectedEcom.subscriptions.df.planId === 100
      "
    >
      <app-alert-with-button
        type="warning"
        [alertButton]="{
          link: '/billing/subscription',
          textKey: isTrialValid
            ? 'RETAILER_CATALOGS.UPSELL_DATAFEED.TRIAL.BUTTON'
            : 'RETAILER_CATALOGS.UPSELL_DATAFEED.BUTTON',
          queryParams: {
            utm_source: 'inapp',
            utm_medium: 'button',
            utm_content: 'datafeed',
            utm_campaign: 'inappstarter'
          }
        }"
        [message]="
          isTrialValid
            ? ('RETAILER_CATALOGS.UPSELL_DATAFEED.TRIAL.TEXT' | translate)
            : ('RETAILER_CATALOGS.UPSELL_DATAFEED.TEXT' | translate)
        "
      ></app-alert-with-button>
    </div>
    <ng-container>
      <div class="task-table" *ngIf="!(ltLg | async); else mobile">
        <app-custom-table
          class="table-margin"
          [@animateStagger]="{ value: '50' }"
          [displayedColumns]="displayedColumns"
          [isServerSidePaginate]="false"
          [dataSource]="dataSource"
          (clickRow)="taskClicked($event)"
          [fetcherLogic]="false"
          (previousClicked)="handlePreviousClicked()"
          (nextClicked)="handleNextClicked()"
          (sizeChanged)="handleSizeChanged($event)"
          [page]="page.value"
          [size]="size.value"
          [allItemsCount]="tasks.length"
          [isLoading]="isLoading"
          [noResultComponent]="noResult"
        >
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let task" (click)="$event.stopPropagation()">
              <div class="status-cell-content" style="margin: 0 auto">
                <button
                  mat-button
                  class="syncee-white-button"
                  [disabled]="task.isDisabled || task.isStillRunning"
                  [matTooltip]="'Remaining runs today: ' + task.remaining"
                  [matTooltipDisabled]="!task.remaining && task.remaining !== 0"
                  matTooltipPosition="above"
                  (click)="runClicked(task)"
                >
                  <ng-container *ngIf="task.isStillRunning; then running; else notRunning"></ng-container>
                  <ng-template #notRunning> {{ 'RETAILER_IMPORT_TASKS.SYNC' | translate }} </ng-template>
                  <ng-template #running>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                      <mat-spinner [diameter]="24"></mat-spinner>
                      <span class="run-button-text">{{ 'RETAILER_IMPORT_TASKS.SYNC' | translate }}</span>
                    </div>
                  </ng-template>
                </button>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="icons">
            <th mat-header-cell *matHeaderCellDef>{{ 'RETAILER_IMPORT_TASKS.SOURCE_LABEL' | translate }}</th>
            <td mat-cell *matCellDef="let task">
              <div *ngIf="!!task.sourceType">{{ 'RETAILER_IMPORT_TASKS.SOURCE.' + task.sourceType | translate }}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ 'RETAILER_IMPORT_TASKS.NAME' | translate }}</th>
            <td mat-cell *matCellDef="let task">
              <div fxLayoutAlign="start" fxLayout="column">
                <span class="text-truncate">{{ task.name }}</span>
                <span
                  *ngIf="!!task.destinationEcomId || !!task.ecomId"
                  class="typography-overline syncee-grey-500-fg"
                  >{{ getDomainName(task.destinationEcomId ?? task.ecomId) }}</span
                >
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="history">
            <th mat-header-cell *matHeaderCellDef>{{ 'RETAILER_IMPORT_TASKS.HISTORY' | translate }}</th>
            <td
              mat-cell
              *matCellDef="let task"
              (click)="$event.stopPropagation(); showHistory(task.taskId)"
              class="history"
            >
              <div class="w-100p">
                <app-history-indicator [data]="task.progressbar"></app-history-indicator>
                <div
                  class="shopify-variants-limit"
                  (click)="openVariantsLimitHelp($event)"
                  *ngIf="task.statusCode === 'WARNING: VARIANTS_LIMIT_REACHED'"
                >
                  {{ 'RETAILER_IMPORT_TASKS.VARIANTS_LIMIT' | translate }}
                  <mat-icon>help</mat-icon>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>{{ 'RETAILER_IMPORT_TASKS.LAST_RUN_DATE' | translate }}</th>
            <td mat-cell *matCellDef="let task">
              <ng-container *ngIf="!!task.date; else notRun">{{ task.date | customDate }}</ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="nextTimeRun">
            <th mat-header-cell *matHeaderCellDef>{{ 'RETAILER_IMPORT_TASKS.NEXT_RUN_DATE' | translate }}</th>
            <td mat-cell *matCellDef="let task">
              <ng-container *ngIf="task.isActive === '1' && !!task.nextTimeRun; else notRun">
                {{ task.nextTimeRun | customDate }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="products">
            <th mat-header-cell *matHeaderCellDef>{{ 'RETAILER_IMPORT_TASKS.PRODUCTS' | translate }}</th>
            <td mat-cell *matCellDef="let task">
              {{ task.resultCount ?? 0 | number : '.0-2' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>{{ 'RETAILER_IMPORT_TASKS.DETAILS' | translate }}</th>
            <td mat-cell *matCellDef="let task">
              <app-task-badges [task]="task"></app-task-badges>
            </td>
          </ng-container>

          <ng-container matColumnDef="menu">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let task" (click)="$event.stopPropagation()" class="menu">
              <app-tasks-menu
                [menus]="menus"
                [task]="task"
                (menuItemClicked)="menuItemClick($event.id, $event.task)"
              ></app-tasks-menu>
            </td>
          </ng-container>
          <mat-header-row *matHeaderRowDef="this.displayedColumns"></mat-header-row>
        </app-custom-table>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #timeLine>
  <app-history-timeline [taskType]="{ supplierTask: taskTypes.supplierTask }"></app-history-timeline>
</ng-template>

<ng-template #notRun>-</ng-template>

<ng-template #mobile>
  <app-tasks-mobile-list
    [dataSource]="dataSource?.data"
    [page]="page.value"
    [size]="size.value"
    [allCount]="tasks.length"
    [menus]="menus"
    [role]="taskRole"
    (previousClicked)="handlePreviousClicked()"
    (nextClicked)="handleNextClicked()"
    (sizeChanged)="handleSizeChanged($event)"
    (syncClicked)="runClicked($event)"
    (menuItemClicked)="menuItemClick($event.id, $event.task)"
  ></app-tasks-mobile-list>
</ng-template>

<ng-template #noResult>
  <app-no-search-results></app-no-search-results>
</ng-template>
