import { Injectable } from '@angular/core';
import { IMPORT_LIST_PRICE_OPERATOR_TYPE } from 'app/shared/components/import-list-price-settings/types/operator.type';
import { PriceSettingsUpdateVo } from '../../../../service/pricing/pricing.service';
import { ImportListPrice } from '../../../../shared/components/import-list-price-settings/model/import-list-price';
import {
  RETAIL_DEFAULT_FIX_OPERATOR,
  RETAIL_DEFAULT_PERCENTAGE_OPERATOR,
  RETAIL_FIX_OPERATORS,
  RETAIL_PERCENTAGE_OPERATORS,
  WHOLESALE_DEFAULT_FIX_OPERATOR,
  WHOLESALE_DEFAULT_PERCENTAGE_OPERATOR,
  WHOLESALE_FIX_OPERATORS,
  WHOLESALE_PERCENTAGE_OPERATORS,
} from '../constants/supplier-pricing.constants';
import { PriceType } from '../enums/price-type.enum';
import { SupplierCategoriesAndPricesMapped } from './supplier-task-price-settings.service';
@Injectable()
export class SupplierTaskPricingService {
  // It is assuming, that the default selected pricing type is WHOLESALE;
  enabledPercentageOperators: IMPORT_LIST_PRICE_OPERATOR_TYPE[] = WHOLESALE_PERCENTAGE_OPERATORS;
  enabledFixOperators: IMPORT_LIST_PRICE_OPERATOR_TYPE[] = WHOLESALE_FIX_OPERATORS;
  defaultPercentageOperator: IMPORT_LIST_PRICE_OPERATOR_TYPE = WHOLESALE_DEFAULT_PERCENTAGE_OPERATOR;
  defaultFixOperator: IMPORT_LIST_PRICE_OPERATOR_TYPE = WHOLESALE_DEFAULT_FIX_OPERATOR;

  private pricingData: SupplierTaskPricingData = {
    pricing: [],
    roundingRule: 'none',
  };
  constructor() {}

  get priceSettingsUpdateVo(): PriceSettingsUpdateVo {
    return this.mapImportListPriceToPriceSettingsVo(this.pricing);
  }

  initPriceSettingsFromData(data: ImportListPrice[]): void {
    this.pricingData = { ...this.pricingData, pricing: data };
    this.setDefaultOperators(this.defaultPercentageOperator, this.defaultFixOperator);
  }

  initRoundingRuleFromData(data: string): void {
    this.pricingData = { ...this.pricingData, roundingRule: data };
  }

  setPriceSettings(data: ImportListPrice): void {
    const pricingItems = [...this.pricingData.pricing];
    const matchingItemIndex = pricingItems.findIndex((item) => item.collectionId === data.collectionId);
    if (matchingItemIndex >= 0) {
      pricingItems[matchingItemIndex] = data;
    } else {
      pricingItems.push(data);
    }
    this.pricingData = { ...this.pricingData, pricing: pricingItems };
  }

  setRoundingRule(data: string): void {
    this.pricingData = { ...this.pricingData, roundingRule: data };
  }

  get rounding(): string {
    return this.pricingData.roundingRule;
  }

  get pricing(): ImportListPrice[] {
    return this.pricingData.pricing;
  }

  private mapImportListPriceToPriceSettingsVo(priceSettingsItems: ImportListPrice[]): PriceSettingsUpdateVo {
    const priceRangesVo: PriceSettingsUpdateVo = {};
    priceSettingsItems.forEach((entry, index, items) => {
      if (!priceRangesVo[entry.collectionId]) {
        priceRangesVo[entry.collectionId] = {};
        items[index].priceItemList.forEach((item) => {
          if (!priceRangesVo[entry.collectionId][item.from]) {
            priceRangesVo[entry.collectionId][item.from] = {
              perc: item.percAmountOperator * item.percentage,
              fix: item.fixAmountOperator * item.fixAmount,
            };
          }
        });
      }
    });
    return priceRangesVo;
  }

  setDefaultOperators(defaultPercOperator: number, defaultFixOperator): void {
    this.pricingData = {
      ...this.pricingData,
      pricing: this.pricingData.pricing.map((pricing) => {
        return {
          ...pricing,
          priceItemList: pricing.priceItemList.map((pricingItem) => ({
            ...pricingItem,
            fixAmountOperator: pricingItem.fixAmountOperator || defaultFixOperator,
            percAmountOperator: pricingItem.percAmountOperator || defaultPercOperator,
          })),
        };
      }),
    };
  }

  setEnableAndDefaultOperators(
    value: SupplierCategoriesAndPricesMapped,
    mapToOperatorToSelectedPriceType: boolean = false
  ): SupplierCategoriesAndPricesMapped {
    return {
      ...value,
      pricing: mapToOperatorToSelectedPriceType
        ? this.mapPricingToSelectedDefaultOperators(
            this.pricing,
            this.defaultPercentageOperator,
            this.defaultFixOperator
          )
        : this.pricing,
      enabledFixOperators: this.enabledFixOperators,
      enabledPercentageOperators: this.enabledPercentageOperators,
      defaultFixOperator: this.defaultFixOperator,
      defaultPercentageOperator: this.defaultPercentageOperator,
    };
  }

  mapPricingToSelectedDefaultOperators(
    pricing: ImportListPrice[],
    defaultPercOperator: IMPORT_LIST_PRICE_OPERATOR_TYPE,
    defaultFixOperator: IMPORT_LIST_PRICE_OPERATOR_TYPE
  ): ImportListPrice[] {
    return pricing.map((price) => {
      return {
        ...price,
        priceItemList: price.priceItemList.map((item) => {
          return {
            ...item,
            percAmountOperator: defaultPercOperator,
            fixAmountOperator: defaultFixOperator,
          };
        }),
      };
    });
  }

  handleChangePriceType(priceType: PriceType): void {
    switch (priceType) {
      case PriceType.PRICE:
        this._setRetailPriceOperators();
        break;
      case PriceType.RETAIL_PRICE:
        this._setWholesalePriceOperators();
        break;
    }
  }

  private _setWholesalePriceOperators(): void {
    this.enabledPercentageOperators = WHOLESALE_PERCENTAGE_OPERATORS;
    this.enabledFixOperators = WHOLESALE_FIX_OPERATORS;
    this.defaultPercentageOperator = WHOLESALE_DEFAULT_PERCENTAGE_OPERATOR;
    this.defaultFixOperator = WHOLESALE_DEFAULT_FIX_OPERATOR;
  }

  private _setRetailPriceOperators(): void {
    this.enabledPercentageOperators = RETAIL_PERCENTAGE_OPERATORS;
    this.enabledFixOperators = RETAIL_FIX_OPERATORS;
    this.defaultPercentageOperator = RETAIL_DEFAULT_PERCENTAGE_OPERATOR;
    this.defaultFixOperator = RETAIL_DEFAULT_FIX_OPERATOR;
  }
}

export interface SupplierTaskPricingData {
  pricing: ImportListPrice[];
  roundingRule: string;
}
