import { Component, OnInit } from '@angular/core';
import { RestService } from '../../../service/rest/rest.service';
import { AuthenticationService } from '../../../service/authentication/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { CategoryService } from '../../../service/product-search/category.service';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { ComponentType } from '@angular/cdk/overlay';
import { footerStructure, gdprs, socialPlatforms, allRightsReserved } from './footer.config';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  protected readonly footerStructure = footerStructure;
  protected readonly socialPlatforms = socialPlatforms;
  protected readonly allRightsReserved = allRightsReserved;
  protected readonly gdprs = gdprs;
  darkOn = false;
  isMd = false;

  /**
   * Constructor
   */
  constructor(
    private restService: RestService,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private fuseConfigService: FuseConfigService,
    private categoryService: CategoryService,
    private screenManagerService: ScreenManagerService
  ) {}

  ngOnInit(): void {
    this.screenManagerService.observeBreakpoint(BreakPoint.md).subscribe((event) => {
      this.isMd = event.matches;
    });
  }

  test(): void {
    // this.dialog.open(FuseConfirmDialogComponent);
    this.fuseConfigService.setConfig({
      colorTheme: this.darkOn ? 'theme-default' : 'theme-pink-dark',
    });
    this.darkOn = !this.darkOn;
    // this.categoryService.test().subscribe(() => {});
  }
}

export type FooterItemType =
  | 'EXTERNAL_NAV'
  | 'EXTERNAL_NAV_WITH_LANGUAGE'
  | 'INNER_NAV'
  | 'DIALOG'
  | 'NON_CLICK'
  | 'EMAIL';

export interface FooterMenuItem {
  type: FooterItemType;
  key?: string;
  value?: string;
  source?: string;
  component?: ComponentType<any>;
}

export interface FooterGroup {
  name: string;
  items: FooterMenuItem[];
}

export interface FooterImage {
  src: string;
  alt: string;
  link: string;
}
