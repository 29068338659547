import { AsyncPipe, NgForOf, NgIf, UpperCasePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { PlatformDialogComponent } from 'app/main/users/platform-list/platform-dialog/platform-dialog.component';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';
import { currencies } from 'app/utils/Currencies';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AppState } from '../../../../app.state';
import { CurrencyService } from '../../../../service/currency-service/currency.service';
import { EcomManagerService } from '../../../../service/ecom/ecom-manager.service';
import { EcomVO } from '../../../../service/ecom/ecom.service';
import { CurrencyActionTypes, SetSelectedCurrencyStartAction } from '../../../../store/currency/currency.action';
import { LanguageActionTypes, LanguageChangeStart } from '../../../../store/language/language.actions';
import { getLanguages, getSelectedLanguageCode } from '../../../../store/language/language.selector';
import { UpdateUserStart } from '../../../../store/user/user.actions';
import { OnlyLoggedInDirective } from '../../../directives/only-logged-in.directive';
import { CustomDialogWrapperComponent } from '../../dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { LanguageOption } from '../language-and-currency-selector.component';

@Component({
  selector: 'app-language-and-currency-selector-dialog',
  templateUrl: './language-and-currency-selector-dialog.component.html',
  styleUrls: ['./language-and-currency-selector-dialog.component.scss'],
  standalone: true,
  imports: [
    CustomDialogWrapperComponent,
    MatInputModule,
    MatSelectModule,
    NgForOf,
    FlexModule,
    UpperCasePipe,
    TranslateModule,
    AsyncPipe,
    OnlyLoggedInDirective,
    MatButtonModule,
    NgIf,
  ],
})
export class LanguageAndCurrencySelectorDialogComponent implements OnInit, OnDestroy {
  currentLanguage$: Observable<LanguageOption>;
  currencies: Currency[];
  currency: Currency;
  languages$: Observable<LanguageOption[]>;
  selectedEcom$: Observable<EcomVO>;
  private unsubscribeAll: Subject<void>;
  private currentLanguage: LanguageOption;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LanguageAndCurrencySelectorDialogData,
    private store: Store<AppState>,
    private ecomManagerService: EcomManagerService,
    private dialogRef: MatDialogRef<PlatformDialogComponent>,
    private readonly actions$: Actions
  ) {
    this.selectedEcom$ = this.ecomManagerService
      .selectedEcomByUserRoles()
      .pipe(tap((selectedEcom) => this.setSelectedCurrency(selectedEcom)));
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.setCurrencies();
    this.setSelectedCurrency(undefined);
    this.setLanguages();
    this.setSelectedLanguage();
    this.subscribeToChangeSuccessAction();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  changeLanguage(event: any): void {
    this.currentLanguage$ = of(event);
  }

  changeCurrency(key: string): void {
    key === 'default'
      ? (this.currency = { key: 'default' } as Currency)
      : (this.currency = this.currencies.find((currency) => currency.key === key));
  }

  updatePreferences(): void {
    this.dispatchLanguageChange();
    if (this.data.isCurrencyActive) {
      this.dispatchCurrencyChange();
    }
  }

  private setSelectedLanguage(): void {
    this.currentLanguage$ = this.getCurrentLanguage();
  }

  getCurrentLanguage(): Observable<LanguageOption> {
    return this.store
      .select(getSelectedLanguageCode)
      .pipe(
        switchMap((selectedLanguageCode) =>
          this.store
            .select(getLanguages)
            .pipe(map((languages) => languages.find((lanuage) => lanuage.code === selectedLanguageCode)))
        )
      );
  }

  private setLanguages(): void {
    this.languages$ = this.store.select(getLanguages);
  }

  private setCurrencies(): void {
    this.currencies = currencies.map((currency) => {
      const key = Object.keys(currency)[0];
      return {
        key: key,
        name: currency[key],
      };
    });
  }

  private dispatchLanguageChange(): void {
    combineLatest([this.store.select(isAuthenticatedSelector), this.currentLanguage$])
      .pipe(
        tap(([isAuth, currentLanguage]) => {
          if (isAuth) {
            this.store.dispatch(new UpdateUserStart({ user: { language: currentLanguage.code } }));
          } else {
            this.store.dispatch(new LanguageChangeStart({ countryCode: currentLanguage.code, withNotification: true }));
          }
        }),
        take(1)
      )
      .subscribe();
  }

  private getEcomCurrency(): string {
    let currency;

    this.selectedEcom$
      .pipe(
        filter((e) => !!e),
        take(1)
      )
      .subscribe((res) => (currency = res?.options?.currency));

    return currency;
  }

  private dispatchCurrencyChange(): void {
    this.store.dispatch(
      new SetSelectedCurrencyStartAction({
        selectedCurrency: this.currency.key === 'default' ? this.getEcomCurrency() : this.currency.key,
        withNotification: true,
      })
    );
  }

  private setSelectedCurrency(selectedEcom: EcomVO): void {
    if (selectedEcom && !selectedEcom.options.preferredCurrency) {
      this.currency = { key: 'default' } as Currency;
    } else {
      this.currency = this.currencies.find((currency) => currency.key === CurrencyService.getCurrency(selectedEcom));
    }
  }

  private subscribeToChangeSuccessAction(): void {
    this.actions$
      .pipe(
        takeUntil(this.unsubscribeAll),
        ofType(
          LanguageActionTypes.GUEST_LANGUAGE_CHANGE_SUCCESS,
          LanguageActionTypes.USER_LANGUAGE_CHANGE_SUCCESS,
          CurrencyActionTypes.SELECTED_ECOM_SET_SELECTED_CURRENCY_SUCCESS,
          CurrencyActionTypes.NO_ECOM_SET_SELECTED_CURRENCY_SUCCESS
        )
      )
      .subscribe(() => this.dialogRef.close());
  }
}

export interface LanguageAndCurrencySelectorDialogData {
  isCurrencyActive: boolean;
  selectedLanguage: LanguageOption;
}

export interface Currency {
  key: string;
  name: string;
}
