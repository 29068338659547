import { CardContent } from '../../shared/components/feature-cards-row/feature-cards-row.component';
import { FeatureCardsWithSquareIconItem } from '../../static/components/feature-cards-with-square-icon/feature-cards-with-square-icon.component';

export const FEATURE_CARDS: CardContent[] = [
  {
    icon: 'public',
    iconColor: '#286DF8',
    iconBackgroundColor: '#E2EFFB',
    titleKey: 'EXPLORE_PRODUCTS.GUEST.SELLING_POINTS.PRODUCTS.TITLE',
    descriptionKey: 'EXPLORE_PRODUCTS.GUEST.SELLING_POINTS.PRODUCTS.DESCRIPTION',
  },
  {
    icon: 'sell',
    iconColor: '#E57373',
    iconBackgroundColor: '#FFEBEE',
    titleKey: 'EXPLORE_PRODUCTS.GUEST.SELLING_POINTS.BRANDS.TITLE',
    descriptionKey: 'EXPLORE_PRODUCTS.GUEST.SELLING_POINTS.BRANDS.DESCRIPTION',
  },
  {
    icon: 'star_border',
    iconColor: '#FFB300',
    iconBackgroundColor: '#FFECB3',
    titleKey: 'EXPLORE_PRODUCTS.GUEST.SELLING_POINTS.PREMIUM.TITLE',
    descriptionKey: 'EXPLORE_PRODUCTS.GUEST.SELLING_POINTS.PREMIUM.DESCRIPTION',
  },
  {
    icon: 'sync',
    iconColor: '#009D97',
    iconBackgroundColor: '#D7EEEE',
    titleKey: 'EXPLORE_PRODUCTS.GUEST.SELLING_POINTS.UP_TO_DATE.TITLE',
    descriptionKey: 'EXPLORE_PRODUCTS.GUEST.SELLING_POINTS.UP_TO_DATE.DESCRIPTION',
  },
];

export const HOMEPAGE_GUEST_FEATURE_CARDS: CardContent[] = [
  {
    icon: 'favorite_border',
    iconColor: '#286DF8',
    iconBackgroundColor: '#E2EFFB',
    titleKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS.CARDS.1.TITLE',
    descriptionKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS.CARDS.1.DESCRIPTION',
  },
  {
    icon: 'payments',
    iconColor: '#E57373',
    iconBackgroundColor: '#FFEBEE',
    titleKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS.CARDS.2.TITLE',
    descriptionKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS.CARDS.2.DESCRIPTION',
  },
  {
    icon: 'local_shipping',
    iconColor: '#FFB300',
    iconBackgroundColor: '#FFECB3',
    titleKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS.CARDS.3.TITLE',
    descriptionKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS.CARDS.3.DESCRIPTION',
  },
  {
    icon: 'handshake',
    iconColor: '#009D97',
    iconBackgroundColor: '#D7EEEE',
    titleKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS.CARDS.4.TITLE',
    descriptionKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS.CARDS.4.DESCRIPTION',
  },
];

export const HOMEPAGE_GUEST_FEATURE_CARDS_WITH_ACTION: CardContent[] = [
  {
    icon: 'lightbulb',
    iconColor: '#286DF8',
    iconBackgroundColor: '#E2EFFB',
    titleKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_ACTION.CARDS.1.TITLE',
    descriptionKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_ACTION.CARDS.1.DESCRIPTION',
    button: {
      type: 'external_redirect',
      url: 'https://help.syncee.co',
      textKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_ACTION.CARDS.1.BUTTON',
      buttonClass: ['syncee-white-button'],
    },
  },
  {
    icon: 'edit_note',
    iconColor: '#AFB82F',
    iconBackgroundColor: '#EBFCA2',
    titleKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_ACTION.CARDS.2.TITLE',
    descriptionKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_ACTION.CARDS.2.DESCRIPTION',
    button: {
      type: 'external_redirect',
      url: 'https://syncee.com/blog',
      textKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_ACTION.CARDS.2.BUTTON',
      buttonClass: ['syncee-white-button'],
    },
  },
  {
    icon: 'question_mark',
    iconColor: '#009D97',
    iconBackgroundColor: '#D7EEEE',
    titleKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_ACTION.CARDS.3.TITLE',
    descriptionKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_ACTION.CARDS.3.DESCRIPTION',
    button: {
      type: 'inner_redirect',
      url: '/faq',
      textKey: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_ACTION.CARDS.3.BUTTON',
      buttonClass: ['syncee-white-button'],
    },
  },
];

export const SELL_ON_SYNCEE_FEATURE_CARDS: CardContent[] = [
  {
    icon: 'money_off_csred',
    iconColor: '#286DF8',
    iconBackgroundColor: '#E2EFFB',
    titleKey: 'SELL_ON_SYNCEE.FEATURE_CARDS.CARDS.1.TITLE',
    descriptionKey: 'SELL_ON_SYNCEE.FEATURE_CARDS.CARDS.1.DESCRIPTION',
  },
  {
    icon: 'palette',
    iconColor: '#E57373',
    iconBackgroundColor: '#FFEBEE',
    titleKey: 'SELL_ON_SYNCEE.FEATURE_CARDS.CARDS.2.TITLE',
    descriptionKey: 'SELL_ON_SYNCEE.FEATURE_CARDS.CARDS.2.DESCRIPTION',
  },
  {
    icon: 'public',
    iconColor: '#FFB300',
    iconBackgroundColor: '#FFECB3',
    titleKey: 'SELL_ON_SYNCEE.FEATURE_CARDS.CARDS.3.TITLE',
    descriptionKey: 'SELL_ON_SYNCEE.FEATURE_CARDS.CARDS.3.DESCRIPTION',
  },
  {
    icon: 'groups',
    iconColor: '#009D97',
    iconBackgroundColor: '#D7EEEE',
    titleKey: 'SELL_ON_SYNCEE.FEATURE_CARDS.CARDS.4.TITLE',
    descriptionKey: 'SELL_ON_SYNCEE.FEATURE_CARDS.CARDS.4.DESCRIPTION',
  },
];

export const SELL_ON_SYNCEE_FEATURE_CARDS_WITH_ACTION: CardContent[] = [
  {
    icon: 'lightbulb',
    iconColor: '#286DF8',
    iconBackgroundColor: '#E2EFFB',
    titleKey: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_ACTION.CARDS.1.TITLE',
    descriptionKey: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_ACTION.CARDS.1.DESCRIPTION',
    button: {
      type: 'external_redirect',
      url: 'https://help.syncee.co',
      textKey: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_ACTION.CARDS.1.BUTTON',
      buttonClass: ['syncee-white-button'],
    },
  },
  {
    key: 'INTERCOM',
    icon: 'chat',
    iconColor: '#AFB82F',
    iconBackgroundColor: '#EBFCA2',
    titleKey: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_ACTION.CARDS.2.TITLE',
    descriptionKey: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_ACTION.CARDS.2.DESCRIPTION',
    button: {
      type: 'callback', // add callback in component
      url: 'https://help.syncee.co',
      textKey: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_ACTION.CARDS.2.BUTTON',
      buttonClass: ['syncee-white-button'],
    },
  },
  {
    icon: 'calendar_month',
    iconColor: '#009D97',
    iconBackgroundColor: '#D7EEEE',
    titleKey: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_ACTION.CARDS.3.TITLE',
    descriptionKey: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_ACTION.CARDS.3.DESCRIPTION',
    button: {
      type: 'callback',
      url: 'https://help.syncee.co',
      textKey: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_ACTION.CARDS.3.BUTTON',
      buttonClass: ['syncee-white-button'],
    },
  },
];

export const SELL_ON_SYNCEE_FEATURE_CARDS_WITH_SQUARE_ICON: FeatureCardsWithSquareIconItem[] = [
  {
    icon: 'access_time',
    title: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.1.TITLE',
    description: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.1.DESCRIPTION',
    iconColor: 'var(--app-syncee-primary-700)',
    iconBackgroundColor: 'var(--app-syncee-primary-50)',
  },
  {
    icon: 'file_upload',
    title: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.2.TITLE',
    description: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.2.DESCRIPTION',
    iconColor: 'var(--app-neon-yellow-800)',
    iconBackgroundColor: 'var(--app-neon-yellow-200)',
  },
  {
    icon: 'filter_alt',
    title: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.6.TITLE',
    description: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.6.DESCRIPTION',
    iconColor: 'var(--app-green-600)',
    iconBackgroundColor: 'var(--app-light-green-100)',
  },
  {
    icon: 'sell',
    title: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.3.TITLE',
    description: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.3.DESCRIPTION',
    iconColor: 'var(--app-amber-600)',
    iconBackgroundColor: 'var(--app-amber-100)',
  },
  {
    icon: 'settings',
    title: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.4.TITLE',
    description: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.4.DESCRIPTION',
    iconColor: 'var(--app-syncee-warn-400)',
    iconBackgroundColor: 'var(--app-syncee-warn-50)',
  },
  {
    icon: 'sync',
    title: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.5.TITLE',
    description: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.5.DESCRIPTION',
    iconColor: 'var(--app-syncee-primary-700)',
    iconBackgroundColor: 'var(--app-syncee-primary-50)',
  },
  {
    icon: 'inventory',
    title: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.7.TITLE',
    description: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.7.DESCRIPTION',
    iconColor: 'var(--app-green-600)',
    iconBackgroundColor: 'var(--app-light-green-100)',
  },
  {
    icon: 'trending_up',
    title: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.8.TITLE',
    description: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.8.DESCRIPTION',
    iconColor: 'var(--app-turquoise-300)',
    iconBackgroundColor: 'var(--app-turquoise-50)',
  },
  {
    icon: 'question_answer',
    title: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.9.TITLE',
    description: 'SELL_ON_SYNCEE.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.9.DESCRIPTION',
    iconColor: 'var(--app-amber-600)',
    iconBackgroundColor: 'var(--app-amber-100)',
  },
];

export const DROPSHIPPING_FEATURE_CARDS_WITH_SQUARE_ICON: FeatureCardsWithSquareIconItem[] = [
  {
    icon: 'list_alt',
    title: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.1.TITLE',
    description: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.1.DESCRIPTION',
    iconColor: 'var(--app-turquoise-300)',
    iconBackgroundColor: 'var(--app-turquoise-50)',
  },
  {
    icon: 'file_upload',
    title: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.4.TITLE',
    description: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.4.DESCRIPTION',
    iconColor: 'var(--app-amber-600)',
    iconBackgroundColor: 'var(--app-amber-100)',
  },
  {
    icon: 'sell',
    title: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.7.TITLE',
    description: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.7.DESCRIPTION',
    iconColor: 'var(--app-syncee-primary-700)',
    iconBackgroundColor: 'var(--app-syncee-primary-50)',
  },
  {
    icon: 'local_shipping',
    title: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.2.TITLE',
    description: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.2.DESCRIPTION',
    iconColor: 'var(--app-neon-yellow-800)',
    iconBackgroundColor: 'var(--app-neon-yellow-200)',
  },
  {
    icon: 'apps',
    title: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.5.TITLE',
    description: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.5.DESCRIPTION',
    iconColor: 'var(--app-green-600)',
    iconBackgroundColor: 'var(--app-green-100)',
  },
  {
    icon: 'sync',
    title: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.8.TITLE',
    description: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.8.DESCRIPTION',
    iconColor: 'var(--app-syncee-warn-400)',
    iconBackgroundColor: 'var(--app-syncee-warn-50)',
  },
  {
    icon: 'settings',
    title: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.3.TITLE',
    description: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.3.DESCRIPTION',
    iconColor: 'var(--app-syncee-primary-700)',
    iconBackgroundColor: 'var(--app-syncee-primary-50)',
  },
  {
    icon: 'auto_awesome',
    title: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.6.TITLE',
    description: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.6.DESCRIPTION',
    iconColor: 'var(--app-turquoise-300)',
    iconBackgroundColor: 'var(--app-turquoise-50)',
  },
  {
    icon: 'headset_mic',
    title: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.9.TITLE',
    description: 'DROPSHIPPING.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.9.DESCRIPTION',
    iconColor: 'var(--app-amber-600)',
    iconBackgroundColor: 'var(--app-amber-100)',
  },
];

export const HOMEPAGE_GUEST_FEATURE_CARDS_WITH_SQUARE_ICON: FeatureCardsWithSquareIconItem[] = [
  {
    icon: 'money_off',
    title: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.1.TITLE',
    description: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.1.DESCRIPTION',
    iconColor: 'var(--app-amber-300)',
    iconBackgroundColor: 'var(--app-amber-100)',
  },
  {
    icon: 'file_upload',
    title: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.2.TITLE',
    description: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.2.DESCRIPTION',
    iconColor: 'var(--app-syncee-primary-700)',
    iconBackgroundColor: 'var(--app-syncee-primary-50)',
  },
  {
    icon: 'sell',
    title: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.3.TITLE',
    description: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.3.DESCRIPTION',
    iconColor: 'var(--app-syncee-warn-400)',
    iconBackgroundColor: 'var(--app-syncee-warn-50)',
  },
  {
    icon: 'local_shipping',
    title: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.4.TITLE',
    description: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.4.DESCRIPTION',
    iconColor: 'var(--app-syncee-primary-700)',
    iconBackgroundColor: 'var(--app-syncee-primary-50)',
  },
  {
    icon: 'apps',
    title: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.5.TITLE',
    description: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.5.DESCRIPTION',
    iconColor: 'var(--app-turquoise-300)',
    iconBackgroundColor: 'var(--app-turquoise-50)',
  },
  {
    icon: 'shopping_cart',
    title: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.6.TITLE',
    description: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.6.DESCRIPTION',
    iconColor: 'var(--app-green-600)',
    iconBackgroundColor: 'var(--app-light-green-100)',
  },
  {
    icon: 'settings',
    title: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.7.TITLE',
    description: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.7.DESCRIPTION',
    iconColor: 'var(--app-neon-yellow-800)',
    iconBackgroundColor: 'var(--app-neon-yellow-200)',
  },
  {
    icon: 'auto_awesome',
    title: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.8.TITLE',
    description: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.8.DESCRIPTION',
    iconColor: 'var(--app-syncee-warn-400)',
    iconBackgroundColor: 'var(--app-syncee-warn-50)',
  },
  {
    icon: 'question_answer',
    title: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.9.TITLE',
    description: 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.9.DESCRIPTION',
    iconColor: 'var(--app-syncee-primary-700)',
    iconBackgroundColor: 'var(--app-syncee-primary-50)',
  },
];

export const INTEGRATION_SHOPIFY_FEATURE_CARDS_WITH_SQUARE_ICON: FeatureCardsWithSquareIconItem[] = [
  {
    icon: 'local_shipping',
    title: 'INTEGRATIONS.SHOPIFY.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.1.TITLE',
    description: 'INTEGRATIONS.SHOPIFY.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.1.DESCRIPTION',
    iconColor: 'var(--app-neon-yellow-800)',
    iconBackgroundColor: 'var(--app-neon-yellow-200)',
  },
  {
    icon: 'local_offer',
    title: 'INTEGRATIONS.SHOPIFY.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.2.TITLE',
    description: 'INTEGRATIONS.SHOPIFY.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.2.DESCRIPTION',
    iconColor: 'var(--app-turquoise-300)',
    iconBackgroundColor: 'var(--app-turquoise-50)',
  },
  {
    icon: 'storefront',
    title: 'INTEGRATIONS.SHOPIFY.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.3.TITLE',
    description: 'INTEGRATIONS.SHOPIFY.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.3.DESCRIPTION',
    iconColor: 'var(--app-syncee-primary-700)',
    iconBackgroundColor: 'var(--app-syncee-primary-50)',
  },
  {
    icon: 'cloud_upload',
    title: 'INTEGRATIONS.SHOPIFY.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.4.TITLE',
    description: 'INTEGRATIONS.SHOPIFY.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.4.DESCRIPTION',
    iconColor: 'var(--app-amber-600)',
    iconBackgroundColor: 'var(--app-amber-100)',
  },
  {
    icon: 'sync',
    title: 'INTEGRATIONS.SHOPIFY.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.5.TITLE',
    description: 'INTEGRATIONS.SHOPIFY.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.5.DESCRIPTION',
    iconColor: 'var(--app-syncee-warn-400)',
    iconBackgroundColor: 'var(--app-syncee-warn-50)',
  },
  {
    icon: 'public',
    title: 'INTEGRATIONS.SHOPIFY.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.6.TITLE',
    description: 'INTEGRATIONS.SHOPIFY.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.6.DESCRIPTION',
    iconColor: 'var(--app-green-600)',
    iconBackgroundColor: 'var(--app-light-green-100)',
  },
];

export const INTEGRATION_SHOPIFY_PLUS_FEATURE_CARDS_WITH_SQUARE_ICON: FeatureCardsWithSquareIconItem[] = [
  {
    icon: 'add_shopping_cart',
    title: 'INTEGRATIONS.SHOPIFY_PLUS.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.1.TITLE',
    description: 'INTEGRATIONS.SHOPIFY_PLUS.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.1.DESCRIPTION',
    iconColor: 'var(--app-neon-yellow-800)',
    iconBackgroundColor: 'var(--app-neon-yellow-200)',
  },
  {
    icon: 'storefront',
    title: 'INTEGRATIONS.SHOPIFY_PLUS.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.2.TITLE',
    description: 'INTEGRATIONS.SHOPIFY_PLUS.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.2.DESCRIPTION',
    iconColor: 'var(--app-syncee-primary-700)',
    iconBackgroundColor: 'var(--app-syncee-primary-50)',
  },
  {
    icon: 'local_offer',
    title: 'INTEGRATIONS.SHOPIFY_PLUS.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.3.TITLE',
    description: 'INTEGRATIONS.SHOPIFY_PLUS.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.3.DESCRIPTION',
    iconColor: 'var(--app-turquoise-300)',
    iconBackgroundColor: 'var(--app-turquoise-50)',
  },
  {
    icon: 'cloud_upload',
    title: 'INTEGRATIONS.SHOPIFY_PLUS.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.4.TITLE',
    description: 'INTEGRATIONS.SHOPIFY_PLUS.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.4.DESCRIPTION',
    iconColor: 'var(--app-amber-600)',
    iconBackgroundColor: 'var(--app-amber-100)',
  },
  {
    icon: 'sync',
    title: 'INTEGRATIONS.SHOPIFY_PLUS.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.5.TITLE',
    description: 'INTEGRATIONS.SHOPIFY_PLUS.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.5.DESCRIPTION',
    iconColor: 'var(--app-syncee-warn-400)',
    iconBackgroundColor: 'var(--app-syncee-warn-50)',
  },
  {
    icon: 'public',
    title: 'INTEGRATIONS.SHOPIFY_PLUS.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.6.TITLE',
    description: 'INTEGRATIONS.SHOPIFY_PLUS.FEATURE_CARDS_WITH_SQUARE_ICON.CARDS.6.DESCRIPTION',
    iconColor: 'var(--app-green-600)',
    iconBackgroundColor: 'var(--app-light-green-100)',
  },
];

export const BRANDS_REFERRALS_FEATURE_CARDS: CardContent[] = [
  {
    icon: 'link',
    iconColor: '#E57373',
    iconBackgroundColor: '#FFEBEE',
    titleKey: 'BRAND_REFERRALS.FEATURE_CARDS.CARDS.1.TITLE',
    descriptionKey: 'BRAND_REFERRALS.FEATURE_CARDS.CARDS.1.DESCRIPTION',
  },
  {
    icon: 'group',
    iconColor: '#286DF8',
    iconBackgroundColor: '#E2EFFB',
    titleKey: 'BRAND_REFERRALS.FEATURE_CARDS.CARDS.2.TITLE',
    descriptionKey: 'BRAND_REFERRALS.FEATURE_CARDS.CARDS.2.DESCRIPTION',
  },
  {
    icon: 'payments',
    iconColor: '#FFB300',
    iconBackgroundColor: '#FFECB3',
    titleKey: 'BRAND_REFERRALS.FEATURE_CARDS.CARDS.3.TITLE',
    descriptionKey: 'BRAND_REFERRALS.FEATURE_CARDS.CARDS.3.DESCRIPTION',
  },
];

export const RETAILER_REFERRALS_FEATURE_CARDS: CardContent[] = [
  {
    icon: 'link',
    iconColor: '#286DF8',
    iconBackgroundColor: '#E2EFFB',
    titleKey: 'RETAILER_REFERRALS.FEATURE_CARDS.CARDS.1.TITLE',
    descriptionKey: 'RETAILER_REFERRALS.FEATURE_CARDS.CARDS.1.DESCRIPTION',
  },
  {
    icon: 'group',
    iconColor: '#FFB300',
    iconBackgroundColor: '#FFECB3',
    titleKey: 'RETAILER_REFERRALS.FEATURE_CARDS.CARDS.2.TITLE',
    descriptionKey: 'RETAILER_REFERRALS.FEATURE_CARDS.CARDS.2.DESCRIPTION',
  },
  {
    icon: 'payments',
    iconColor: '#009D97',
    iconBackgroundColor: '#D7EEEE',
    titleKey: 'RETAILER_REFERRALS.FEATURE_CARDS.CARDS.3.TITLE',
    descriptionKey: 'RETAILER_REFERRALS.FEATURE_CARDS.CARDS.3.DESCRIPTION',
  },
];

export const ABOUT_US_FEATURE_CARDS: CardContent[] = [
  {
    icon: 'help_outline',
    iconColor: '#286DF8',
    iconBackgroundColor: '#E2EFFB',
    titleKey: 'ABOUT_US.FEATURE_CARDS.0.TITLE',
    descriptionKey: 'ABOUT_US.FEATURE_CARDS.0.DESCRIPTION',
  },
  {
    icon: 'mail',
    iconColor: '#E57373',
    iconBackgroundColor: '#FFEBEE',
    titleKey: 'ABOUT_US.FEATURE_CARDS.1.TITLE',
    descriptionKey: 'ABOUT_US.FEATURE_CARDS.1.DESCRIPTION',
  },
  {
    icon: 'edit_note',
    iconColor: '#009D97',
    iconBackgroundColor: '#D7EEEE',
    titleKey: 'ABOUT_US.FEATURE_CARDS.2.TITLE',
    descriptionKey: 'ABOUT_US.FEATURE_CARDS.2.DESCRIPTION',
  },
];

export const CONTACT_US_FEATURE_CARDS: CardContent[] = [
  {
    key: 'INTERCOM',
    icon: 'chat_bubble_outline',
    iconColor: 'var(--app-syncee-primary-700)',
    iconBackgroundColor: 'var(--app-syncee-primary-50)',
    titleKey: 'STATIC_PAGES.CONTACT_US.CARDS.FIRST.TITLE',
    descriptionKey: 'STATIC_PAGES.CONTACT_US.CARDS.FIRST.DESCRIPTION',
    button: {
      type: 'callback',
      textKey: 'STATIC_PAGES.CONTACT_US.CARDS.FIRST.ACTION',
      buttonClass: ['syncee-white-button'],
    },
  },
  {
    icon: 'mail',
    iconColor: 'var(--app-syncee-warn-300)',
    iconBackgroundColor: 'var(--app-syncee-warn-50)',
    titleKey: 'STATIC_PAGES.CONTACT_US.CARDS.SECOND.TITLE',
    descriptionKey: 'STATIC_PAGES.CONTACT_US.CARDS.SECOND.DESCRIPTION',
    button: {
      type: 'external_redirect',
      url: 'mailto:support@syncee.com',
      textKey: 'STATIC_PAGES.CONTACT_US.CARDS.SECOND.ACTION',
      buttonClass: ['syncee-white-button'],
    },
  },
  {
    icon: 'help_outline',
    iconColor: 'var(--app-turquoise-300)',
    iconBackgroundColor: 'var(--app-turquoise-50)',
    titleKey: 'STATIC_PAGES.CONTACT_US.CARDS.THIRD.TITLE',
    descriptionKey: 'STATIC_PAGES.CONTACT_US.CARDS.THIRD.DESCRIPTION',
    button: {
      type: 'external_redirect',
      url: 'https://help.syncee.co/',
      textKey: 'STATIC_PAGES.CONTACT_US.CARDS.THIRD.ACTION',
      buttonClass: ['syncee-white-button'],
    },
  },
];
