import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { getSelectedLanguageCode } from 'app/store/language/language.selector';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SectionTitleComponent } from '../../../static/components/section-title/section-title.component';

@Component({
  selector: 'app-integrations-card-grid',
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, RouterModule, TranslateModule, SectionTitleComponent],
  templateUrl: './integrations-card-grid.component.html',
  styleUrls: ['./integrations-card-grid.component.scss'],
})
export class IntegrationsCardGridComponent implements OnInit, OnDestroy {
  @Input() titleKey: string;
  @Input() descriptionKey: string;
  @Input() cardContent: IntegrationsCardContent[];
  @Input() wrapperClass: string;
  @Input() innerWrapperClass: string;

  currentLanguageCode = 'en';

  private unsubscribe: Subject<void>;

  constructor(private store: Store<AppState>) {
    this.unsubscribe = new Subject<void>();
  }

  ngOnInit(): void {
    this.getLanguageCode();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getLanguageCode(): void {
    this.store
      .select(getSelectedLanguageCode)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res) {
          this.currentLanguageCode = res;
        } else {
          this.currentLanguageCode = 'en';
        }
      });
  }
}

export interface IntegrationsCardContent {
  name: string;
  logo: string;
}
