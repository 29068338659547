<ng-container *ngIf="!ltMd; else mobileView">
  <div *ngIf="!!title" class="header">
    <div class="h5">{{ title }}</div>
    <ng-container *ngIf="!!tooltip">
      <mat-icon class="s-20 icon-filled" [matTooltip]="tooltip" matTooltipPosition="right">info</mat-icon>
    </ng-container>
  </div>
  <div
    class="drag-and-drop-container"
    [class.error]="hasError"
    [class.has-preview]="showAlreadyUploaded || (!onlyEmit && hasUploadedFile)"
    dragAndDropFileUpload
    [allowMultiple]="false"
    (click)="handleBrowserClick(file)"
    (filesDropped)="handleFileDropped($event)"
    [allowedExtensions]="allowedExtensions"
  >
    <ng-container *ngIf="!hasAlreadyUploadedFile || !showAlreadyUploaded; else alreadyUploadedRef">
      <ng-container *ngIf="!onlyEmit && hasUploadedFile; else noPreview">
        <ng-container [ngTemplateOutlet]="preview"></ng-container>
      </ng-container>
    </ng-container>
    <input
      type="file"
      #file
      (change)="handleBrowserFileChange($event)"
      class="hidden"
      [multiple]="false"
      [accept]="accept"
    />
  </div>
</ng-container>

<ng-template #noPreview>
  <div [style.height.px]="calculatedLineHeight" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center center">
    <div
      [style.line-height.px]="templateType === noTemplateType ? calculatedLineHeight : 20"
      [innerHTML]="'DRAG_AND_DROP_FILE.UPLOAD' | translate"
    ></div>
    <div
      *ngIf="templateType !== noTemplateType"
      [style.line-height.px]="20"
      [innerHTML]="'DRAG_AND_DROP_FILE.SELECT_TEMPLATE' | translate"
      (click)="handleOpenImageTemplateSelector(); $event.stopPropagation()"
    ></div>
  </div>
</ng-template>

<ng-template #preview>
  <div class="preview-images" [ngClass]="previewVariant">
    <div
      *ngFor="let preview of previewImages; let index = index"
      class="preview"
      [style.height.px]="calculatedLineHeight"
    >
      <ng-container *ngIf="preview">
        <div
          class="image-container"
          [class.icon]="!!preview.icon"
          [style.width.px]="!!calculatedLineHeight && previewVariant === 'grid' ? calculatedLineHeight - 16 : undefined"
        >
          <button
            mat-icon-button
            class="delete-button custom-elevation-z1 white-500-bg"
            (click)="deleteFile(index, $event)"
          >
            <mat-icon class="s-16">close</mat-icon>
          </button>
          <ng-container *ngIf="!!preview.icon; else image">
            <mat-icon class="s-60 syncee-grey-400-fg">{{ preview.icon }}</mat-icon>
          </ng-container>
          <ng-template #image> <img [src]="preview.url" [alt]="preview.name" /></ng-template>
        </div>
        <div class="name typography-overline">
          {{ previewVariant === 'list' ? preview.name : (preview.name | fileNameTruncate) }}
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #alreadyUploadedRef>
  <div class="preview-images" [ngClass]="previewVariant" *ngIf="showAlreadyUploaded; else noPreview">
    <div
      *ngFor="let preview of alreadyUploaded; let index = index"
      class="preview"
      [style.height.px]="calculatedLineHeight"
    >
      <ng-container *ngIf="preview">
        <div
          class="image-container"
          [class.icon]="!!preview.icon"
          [style.width.px]="!!calculatedLineHeight && previewVariant === 'grid' ? calculatedLineHeight - 16 : undefined"
        >
          <button
            mat-icon-button
            class="delete-button custom-elevation-z1 white-500-bg"
            (click)="deleteAlreadyClicked($event)"
          >
            <mat-icon class="s-16">close</mat-icon>
          </button>
          <ng-container *ngIf="!!preview.icon; else image">
            <mat-icon class="s-60 syncee-grey-400-fg">{{ preview.icon }}</mat-icon>
          </ng-container>
          <ng-template #image> <img [src]="preview.url" [alt]="preview.name" /></ng-template>
        </div>
        <div class="name typography-overline">
          {{
            previewVariant === 'list'
              ? preview.name + '.' + preview.extension
              : (preview.name | fileNameTruncate) + '.' + preview.extension
          }}
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #mobileView>
  <div class="mobile-view">
    <div class="mobile-header">
      <div *ngIf="!!title" class="mobile-title">
        <div class="h5">{{ title }}</div>
        <ng-container *ngIf="!!tooltip">
          <mat-icon class="s-20 icon-filled" [matTooltip]="tooltip" matTooltipPosition="right">info</mat-icon>
        </ng-container>
      </div>
      <div class="actions">
        <mat-icon *ngIf="hasError" class="s-20 red-400-fg">error_outline</mat-icon>
        <button mat-button class="syncee-light-blue-button" (click)="file.click()">{{ mobileButtonText }}</button>
      </div>
    </div>
    <div class="mobile-content">
      <ng-container *ngIf="!hasAlreadyUploadedFile || !showAlreadyUploaded; else alreadyUploadedRef">
        <ng-container *ngIf="!onlyEmit && hasUploadedFile">
          <ng-container [ngTemplateOutlet]="preview"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <input
    type="file"
    #file
    (change)="handleBrowserFileChange($event)"
    class="hidden"
    [multiple]="false"
    [accept]="accept"
  />
</ng-template>
