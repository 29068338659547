import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { Store } from '@ngrx/store';
import { LayoutService } from 'app/layout/layout.service';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations';
import { ToolbarConfig, ToolbarExtraIcon } from '../../../../@fuse/types';
import { AppState } from '../../../app.state';
import { AuthenticationService } from '../../../service/authentication/authentication.service';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { UserService } from '../../../service/user/user.service';
import { getUserRoleSelector } from '../../../store/user/user.selector';
import { ToolbarService } from './service/toolbar.service';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [fuseAnimations],
  providers: [ToolbarService],
})
export class ToolbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('toolbarContainer') toolbarContainer: ElementRef<HTMLElement>;
  @Input() needRedirect = true;
  @Input() needCategorySelector = true;
  @Input() hasAiChat: boolean;
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  hasSearchBar: boolean;
  container: string;
  extraIcons: ToolbarExtraIcon[];
  role: number;
  isOnMobile: Observable<boolean>;
  private _unsubscribeAll: Subject<void>;
  toolbarConfig: Observable<ToolbarConfig>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private layoutService: LayoutService,
    public _userService: UserService,
    public authService: AuthenticationService,
    private store: Store<AppState>,
    private screenManager: ScreenManagerService,
    private toolbarService: ToolbarService
  ) {
    this._unsubscribeAll = new Subject();
    this.isOnMobile = this.isMobilSize;
    this.toolbarConfig = this.getToolbarContent();
  }
  ngOnInit(): void {
    this.subscribeToActualRole();
    this.toolbarService.initCallToAction();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public ngAfterViewInit(): void {
    // initializing an observable which is directed on toolbar size change
    this.layoutService.initToolbar(this.toolbarContainer);
  }

  private get isMobilSize(): Observable<boolean> {
    return this.screenManager.observeIfBreakpointMatches(BreakPoint.md);
  }

  private subscribeToActualRole(): void {
    this.store
      .select(getUserRoleSelector)
      .pipe(distinctUntilChanged(), takeUntil(this._unsubscribeAll))
      .subscribe((role) => {
        this.role = role;
      });
  }

  private getToolbarContent(): Observable<ToolbarConfig> {
    return this._fuseConfigService.getConfig().pipe(
      omitNullOrUndefined(),
      distinctUntilChanged(),
      map((config) => config.layout.toolbar)
    );
  }
}
