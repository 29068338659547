import { CommonModule, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';
import { Utils } from 'app/utils/utils';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-google-social-button',
  templateUrl: './google-social-button.component.html',
  styleUrls: ['./google-social-button.component.scss'],
  standalone: true,
  imports: [CommonModule, SvgIconComponent, MatButtonModule, TranslateModule],
})
export class GoogleSocialButtonComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('googleBtn', { static: true }) googleSocialBtn: ElementRef;
  @Input() label = 'AUTHENTICATION.LOGIN.SIGN_IN_WITH_GOOGLE';
  @Output() submitHandle = new EventEmitter<GoogleSocialOutput>();
  @Input() disabled = false;

  constructor(private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document, private ngZone: NgZone) {
    // this.loginId += Math.random() * 1000;
  }

  ngOnInit(): void {}
  // @ts-ignore
  async googleResponse(credential): void {
    const jwtPayload = Utils.parseJwt(credential);
    this.submitHandle.emit({ email: jwtPayload.email, name: jwtPayload.name, token: credential });
  }

  fakeBtnClickHandle(): void {
    (<HTMLElement>document.getElementById('googleLogin').querySelector('div[role=button]')).click();
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      ux_mode: 'popup',
      callback: ({ credential }) => {
        this.ngZone.run(() => {
          this.googleResponse(credential);
        });
      },
      auto_select: false,
      cancel_on_tap_outside: true,
    });
    // @ts-ignore
    // tslint:disable-next-line: no-non-null-assertion
    google.accounts!.id.renderButton(document!.getElementById('googleLogin')!, {
      theme: 'outline',
      width: 200,
    });
    // @ts-ignore
    google.accounts.id.prompt();
  }

  ngOnDestroy(): void {}
}

export interface GoogleSocialOutput {
  email: string;
  name: string;
  token: string;
}
