import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { filter, map, take } from 'rxjs/operators';
import { CategoryVo } from '../../vo/category-vo';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Pipe({
  name: 'categoryNameById',
  standalone: true,
})
export class CategoryNameByIdPipe implements PipeTransform {
  constructor(private store: Store<AppState>, private translateService: TranslateService) {}

  transform(categoryId: number, ...args: unknown[]): Observable<string> {
    return this.store
      .select((appState) => appState.categories.flattenCategories)
      .pipe(
        filter((data) => !!data && data.length > 0),
        map((categories) => categories.find((category) => category.id === categoryId)),
        map(
          (matchingCategory) =>
            matchingCategory.categoryName.find(
              (categoryName) => categoryName.languageCode === this.translateService.currentLang
            )?.name ?? matchingCategory.categoryName.find((categoryName) => categoryName.languageCode === 'en').name
        )
      );
  }
}
