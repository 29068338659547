import { Action } from '@ngrx/store';
import { EcomVO } from 'app/service/ecom/ecom.service';

import { ROLE_TYPE, RolesEnum } from '../../vo/roles/roles';

export enum EcomActionTypes {
  USER_ECOMS_FETCH = '[Ecoms] User ecoms',
  USER_ECOMS_FETCH_SUCCESS = '[Ecoms] User Ecoms Fetch Success',

  FETCH_SUPPLIER_ECOMS_START = '[Ecoms] Supplier Ecoms Fetch Start',
  FETCH_SUPPLIER_ECOMS_SUCCESS = '[Ecoms] Supplier Ecoms Fetch Success',

  FETCH_RETAILER_ECOMS_START = '[Ecoms] Retailer Ecoms Fetch Start',
  FETCH_RETAILER_ECOMS_SUCCESS = '[Ecoms] Retailer Ecoms Fetch Success',

  USER_ECOM_CHAIN_TO_SUBSCRIPTION_CHANGED = '[Ecoms] User Ecom Chained To Subscription Changed',
  USER_ECOM_CHAIN_TO_SUBSCRIPTION_CHANGED_SUCCESS = '[Ecoms] User Ecom Chained To Subscription Changed Success',

  USER_ECOMS_CHAIN_TO_SUBSCRIPTION_FETCH = '[Ecoms] User Ecoms Chain To Subscription Fetch',
  USER_ECOMS_CHAIN_TO_SUBSCRIPTION_FETCH_SUCCESS = '[Ecoms] User Ecoms Chain To Subscription Fetch Success',

  CHANGE_SELECTED_RETAILER_ECOM_START = '[Ecoms] Change Selected Retailer Ecom Start',
  CHANGE_SELECTED_RETAILER_ECOM_SUCCESS = '[Ecoms] Change Selected Retailer Ecom Suceess',

  CHANGE_SELECTED_SUPPLIER_ECOM_START = '[Ecoms] Change Selected Supplier Ecom Start',
  CHANGE_SELECTED_SUPPLIER_ECOM_SUCCESS = '[Ecoms] Change Selected Supplier Ecom Success',

  SELECTED_ECOM_CURRENCY_UPDATE_START = '[Ecoms] Selected Ecom currency update start',
  SELECTED_ECOM_CURRENCY_UPDATE_SUCCESS = '[Ecoms] Selected Ecom currency update success',
  REMOVE_SELECTED_ECOM = '[Ecoms] Remove selected ecom',
}

export interface UserEcomsFetchPayload {
  role: ROLE_TYPE;
}

export interface UserEcomsGetPayload {
  roles: ROLE_TYPE[];
}

export interface UserEcomsSuccessPayload {
  selectedEcom: EcomVO;
  ecoms: EcomVO[];
}

export interface UserEcomChangePayload {
  ecomId: number;
}

export interface UserEcomChangeSuccessPayload {
  selectedEcom: EcomVO;
}

export interface SelectedEcomCurrencyUpdateStartPayload {
  ecom: EcomVO;
  currency: string;
  role: RolesEnum;
}

export class UserEcomChainToSubscriptionChanged implements Action {
  readonly type = EcomActionTypes.USER_ECOM_CHAIN_TO_SUBSCRIPTION_CHANGED;
  constructor(public payload: UserEcomChangePayload) {}
}

export class UserEcomChainToSubscriptionChangedSuccess implements Action {
  readonly type = EcomActionTypes.USER_ECOM_CHAIN_TO_SUBSCRIPTION_CHANGED_SUCCESS;
  constructor(public payload: UserEcomChangeSuccessPayload) {}
}

export class SelectedSupplierEcomChangeStart implements Action {
  readonly type = EcomActionTypes.CHANGE_SELECTED_SUPPLIER_ECOM_START;
  constructor(public payload: UserEcomChangePayload) {}
}

export class SelectedSupplierEcomChangeSuccess implements Action {
  readonly type = EcomActionTypes.CHANGE_SELECTED_SUPPLIER_ECOM_SUCCESS;
  constructor(public payload: UserEcomChangeSuccessPayload) {}
}

export class SelectedRetailerEcomChangeStart implements Action {
  readonly type = EcomActionTypes.CHANGE_SELECTED_RETAILER_ECOM_START;
  constructor(public payload: UserEcomChangePayload) {}
}

export class SelectedRetailerEcomChangeSuccess implements Action {
  readonly type = EcomActionTypes.CHANGE_SELECTED_RETAILER_ECOM_SUCCESS;
  constructor(public payload: UserEcomChangeSuccessPayload) {}
}

export class SupplierEcomsFetchStart implements Action {
  readonly type = EcomActionTypes.FETCH_SUPPLIER_ECOMS_START;
}

export class SupplierEcomsFetchSuccess implements Action {
  readonly type = EcomActionTypes.FETCH_SUPPLIER_ECOMS_SUCCESS;
  constructor(public payload: UserEcomsSuccessPayload) {}
}

export class RetailerEcomsFetchStart implements Action {
  readonly type = EcomActionTypes.FETCH_RETAILER_ECOMS_START;
  constructor(public payload: { hasRetailerRole: boolean }) {}
}

export class RetailerEcomsFetchSuccess implements Action {
  readonly type = EcomActionTypes.FETCH_RETAILER_ECOMS_SUCCESS;
  constructor(public payload: UserEcomsSuccessPayload) {}
}

export class UserEcomsFetch implements Action {
  readonly type = EcomActionTypes.USER_ECOMS_FETCH;
  constructor(public payload: UserEcomsFetchPayload) {}
}

export class UserEcomsFetchSuccess implements Action {
  readonly type = EcomActionTypes.USER_ECOMS_FETCH_SUCCESS;
  constructor(public payload: UserEcomsSuccessPayload) {}
}

export class UserEcomsChainToSubscriptionFetch implements Action {
  readonly type = EcomActionTypes.USER_ECOMS_CHAIN_TO_SUBSCRIPTION_FETCH;
  constructor(public payload: UserEcomsGetPayload) {}
}

export class UserEcomsChainToSubscriptionFetchSuccess implements Action {
  readonly type = EcomActionTypes.USER_ECOMS_CHAIN_TO_SUBSCRIPTION_FETCH_SUCCESS;
}

export class SelectedEcomCurrencyUpdateStart implements Action {
  readonly type = EcomActionTypes.SELECTED_ECOM_CURRENCY_UPDATE_START;
  constructor(public payload: SelectedEcomCurrencyUpdateStartPayload) {}
}

export class SelectedEcomCurrencyUpdateSuccess implements Action {
  readonly type = EcomActionTypes.SELECTED_ECOM_CURRENCY_UPDATE_SUCCESS;
  constructor(public payload: UserEcomChangeSuccessPayload) {}
}

export class RemoveSelectedEcom implements Action {
  readonly type = EcomActionTypes.REMOVE_SELECTED_ECOM;
  constructor() {}
}

export type USER_ECOMS_ACTIONS_ALL =
  | UserEcomsFetch
  | UserEcomsFetchSuccess
  | SupplierEcomsFetchStart
  | SupplierEcomsFetchSuccess
  | RetailerEcomsFetchStart
  | RetailerEcomsFetchSuccess
  | SelectedRetailerEcomChangeStart
  | SelectedSupplierEcomChangeStart
  | SelectedRetailerEcomChangeSuccess
  | SelectedSupplierEcomChangeSuccess
  | UserEcomsChainToSubscriptionFetch
  | UserEcomChainToSubscriptionChanged
  | UserEcomChainToSubscriptionChangedSuccess
  | UserEcomsChainToSubscriptionFetchSuccess
  | SelectedEcomCurrencyUpdateSuccess
  | RemoveSelectedEcom;
