import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SelectedSupplier } from '../../../filter-common/filter-items/supplier-filter/supplier-filter.component';
import { GTMFilterItem, GTMFilterService } from './gtm-filter.service';
import { CountryNode } from '../../../../utils/Countries';
import {
  FilterActionKey,
  GtmApproveTypeValues,
  GtmAutoOrderValues,
  GtmPremiumValues,
  GtmShippingTypeValues,
  GtmStockValues,
} from '../../../../service/google-tag-manager/actions/filter';
import { PreviousAndCurrentValue, withPreviousValue } from '../../../../utils/operator/with-previous-value';
import { map, takeUntil, tap } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { Language } from '../../../../utils/Languages';
import { CategoryVo } from '../../../../vo/category-vo';

@Injectable({ providedIn: 'root' })
export class FilterSelectorGtmService implements OnDestroy {
  private supplierSubject$ = new Subject<GTMFilterItem[]>();
  private shipsFromSubject$ = new Subject<GTMFilterItem[]>();
  private shipsToSubject$ = new Subject<GTMFilterItem[]>();
  private categorySubject$ = new Subject<GTMFilterItem>();
  private unsubscribeAll$ = new Subject<void>();

  constructor(private gtmFilterService: GTMFilterService) {
    this.watchSupplierChange();
    this.watchShipsToToChange();
    this.watchShipsFromChange();
    this.watchCategoryChange();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  public setFilter(key: FilterActionKey, value: PossibleFilters): void {
    switch (key) {
      case 'category':
        this.setCategory(value as CategoryVo);
        break;
      case 'supplier':
        this.setSupplier(value as SelectedSupplier[]);
        break;
      case 'shipsTo':
        this.setShipsTo(value as CountryNode[]);
        break;
      case 'shipsFrom':
        this.setShipsFrom(value as CountryNode[]);
        break;
      case 'lang':
        this.addLanguageToGtm(value as Language);
        break;
      case 'stock':
        this.addStockToGtm(value as string);
        break;
      case 'shippingType':
        this.addShippingTypeToGtm(value as string);
        break;
      case 'premium':
        this.addPremiumToGtm(value as string);
        break;
      case 'approveType':
        this.addApproveTypeToGtm(value as string);
        break;
      case 'autoOrder':
        this.addAutoOrderToGtm(value as string);
        break;
      case 'minPrice':
        this.addPriceToGTM(value as number, 'minPrice');
        break;
      case 'maxPrice':
        this.addPriceToGTM(value as number, 'maxPrice');
        break;
      default:
        return;
    }
  }

  private watchSupplierChange(): void {
    this.supplierSubject$.pipe(this.setArrayFilterValues('supplier'), takeUntil(this.unsubscribeAll$)).subscribe();
  }

  private watchShipsFromChange(): void {
    this.shipsFromSubject$.pipe(this.setArrayFilterValues('shipsFrom'), takeUntil(this.unsubscribeAll$)).subscribe();
  }

  private watchShipsToToChange(): void {
    this.shipsToSubject$.pipe(this.setArrayFilterValues('shipsTo'), takeUntil(this.unsubscribeAll$)).subscribe();
  }

  private watchCategoryChange(): void {
    this.categorySubject$.pipe(withPreviousValue()).subscribe(({ previous, current }) => {
      if (!previous || previous.id !== current.id) {
        this.gtmFilterService.sendFilter('category', {
          id: current.id,
          name: current.name,
        });
      }
    });
  }

  private addLanguageToGtm(language: Language): void {
    this.gtmFilterService.sendFilter('lang', { id: language.id, name: language.name });
  }

  private addStockToGtm(stock: string): void {
    this.gtmFilterService.sendFilter('stock', {
      id: GtmStockValues[stock].id,
      name: GtmStockValues[stock].name,
    });
  }

  private getMaxOfRange(value: number, divide: number): number {
    let found = 0;
    while (found < value) {
      found += divide;
    }
    return found;
  }

  private addPriceToGTM(value: number, type: 'minPrice' | 'maxPrice'): void {
    if (value === null || value === undefined) {
      return;
    }
    const divider = 10;
    const maxOfRange = this.getMaxOfRange(value, divider);
    this.gtmFilterService.sendFilter(type, {
      id: value.toString(),
      name: `${maxOfRange - divider + 1} - ${maxOfRange}`,
    });
  }

  private addShippingTypeToGtm(selectedType: string): void {
    this.gtmFilterService.sendFilter('shippingType', {
      id: selectedType,
      name: GtmShippingTypeValues[selectedType],
    });
  }

  private addPremiumToGtm(value: string): void {
    if (value !== 'any') {
      this.gtmFilterService.sendFilter('premium', {
        id: GtmPremiumValues[value].id,
        name: GtmPremiumValues[value].name,
      });
    }
  }

  private addApproveTypeToGtm(value: string): void {
    if (value !== 'any') {
      this.gtmFilterService.sendFilter('approveType', {
        id: GtmApproveTypeValues[value].id,
        name: GtmApproveTypeValues[value].name,
      });
    }
  }

  private addAutoOrderToGtm(value: string): void {
    this.gtmFilterService.sendFilter('autoOrder', {
      id: GtmAutoOrderValues[value].id,
      name: GtmAutoOrderValues[value].name,
    });
  }

  private setArrayFilterValues(
    key: FilterActionKey
  ): (source: Observable<GTMFilterItem[]>) => Observable<GTMFilterItem[]> {
    return (source) =>
      source.pipe(
        withPreviousValue(),
        this.getRelevantValue(),
        tap((values) => {
          values.forEach((value) => {
            this.gtmFilterService.sendFilter(key, value);
          });
        })
      );
  }
  private getRelevantValue(): (
    source: Observable<PreviousAndCurrentValue<GTMFilterItem[]>>
  ) => Observable<GTMFilterItem[]> {
    return (source) =>
      source.pipe(
        map((value) => {
          if (isEmpty(value.previous) || !value.previous) {
            return value.current;
          }
          return value.current.filter((currentEntry) => !value.previous.some((entry) => entry.id === currentEntry.id));
        })
      );
  }

  private setCategory(category: CategoryVo): void {
    this.categorySubject$.next({
      id: String(category.id),
      name: category.name,
    });
  }

  private setSupplier(suppliers: SelectedSupplier[]): void {
    const mappedSuppliers: GTMFilterItem[] = suppliers.map((entry) => ({
      id: String(entry.id),
      name: entry.name,
    }));
    this.supplierSubject$.next(mappedSuppliers);
  }

  private setShipsTo(countries: CountryNode[]): void {
    const mappedValue: GTMFilterItem[] = countries.map((country) => ({ id: country.code, name: country.name }));
    this.shipsToSubject$.next(mappedValue);
  }

  private setShipsFrom(countries: CountryNode[]): void {
    const mappedValue: GTMFilterItem[] = countries.map((country) => ({ id: country.code, name: country.name }));
    this.shipsFromSubject$.next(mappedValue);
  }
}

export type PossibleFilters =
  | SelectedSupplier
  | SelectedSupplier[]
  | CountryNode
  | CountryNode[]
  | Language
  | string
  | number
  | CategoryVo;
