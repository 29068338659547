import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AppState } from 'app/app.state';
import {
  GoogleSocialButtonComponent,
  GoogleSocialOutput,
} from 'app/main/authentication/google-social-button/google-social-button.component';
import { RequestError } from 'app/service/rest/request-error';
import { SocialGoogleLogIn } from 'app/store/authentication/authentication.actions';
import { isAuthenticatedSelector } from 'app/store/authentication/authentication.selector';
import { Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { RoleTypesEnum } from '../../../../../vo/roles/roles';

@Component({
  selector: 'app-login-google-button',
  templateUrl: './login-google-button.component.html',
  styleUrls: ['./login-google-button.component.scss'],
  standalone: true,
  imports: [GoogleSocialButtonComponent, TranslateModule],
})
export class LoginGoogleButtonComponent implements OnInit, OnDestroy {
  @Input() role: RoleTypesEnum;
  @Input() label = 'AUTHENTICATION.LOGIN.SIGN_IN_WITH_GOOGLE';
  @Input() disabled = false;

  @Output() success = new EventEmitter();
  private _unsubscribeAll: Subject<void>;

  constructor(private store: Store<AppState>) {
    this._unsubscribeAll = new Subject();
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {}

  socialSignInRequest(data: GoogleSocialOutput): void {
    this.store.dispatch(
      new SocialGoogleLogIn({
        jwt: data.token,
        role: this.role,
      })
    );
    this.subscribeSuccessLogin();
  }

  private subscribeSuccessLogin(): void {
    this.store
      .select(isAuthenticatedSelector)
      .pipe(
        takeWhile((authenticated) => authenticated === false, true),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(
        (isAuthenticated) => {
          if (isAuthenticated) {
            this.success.emit();
          }
        },
        (err: RequestError) => {},
        () => {}
      );
  }
}
