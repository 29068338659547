import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ChatService } from 'app/service/chat/chat.service';

import { Observable, Subject, switchMap, takeUntil } from 'rxjs';

import { RolesEnum } from 'app/vo/roles/roles';

import { BreakPoint, ScreenManagerService } from 'app/service/screen-manager/screen-manager.service';
import { ChatUser } from '../../../../service/chat/model/chat-models';
import { Action } from '../../../../service/authorization-ecom/authorization-ecom.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import { EcomVO } from '../../../../service/ecom/ecom.service';
import { hasRolesSelector } from '../../../../store/user/user.selector';
import { MarketplaceEcomService } from '../../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';

@Component({
  selector: 'app-contact-user',
  templateUrl: './contact-user.component.html',
  styleUrls: ['./contact.user.component.scss'],
})
export class ContactUserComponent implements OnDestroy, OnInit {
  @Input() variant: 'default' | 'button' = 'default';
  @Input() fullWidth = false;
  @Input() recipientUserRole: RolesEnum = RolesEnum.RETAILER;
  @Input() recipientUserID: string;
  @Input() recipientUserName: string;
  @Input() recipientUserContactEmail: string;

  public RolesEnum = RolesEnum;

  public CHAT_ACCESS = Action.CHAT_ACCESS;

  public ltMd$ = this.screenManagerService.observeIfBreakpointMatches(BreakPoint.md);

  public ecom$: Observable<EcomVO>;

  public isAdmin$: Observable<boolean>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private chatService: ChatService,
    private screenManagerService: ScreenManagerService,
    private store: Store<AppState>,
    private marketPlaceEcomService: MarketplaceEcomService
  ) {}

  ngOnInit(): void {
    this.getEcomObservable();
    this.isAdmin$ = this.store.select(hasRolesSelector, RolesEnum.ADMIN);
  }

  private getEcomObservable(): void {
    this.ecom$ = this.marketPlaceEcomService.getEcomWithSubscriptionFromStore();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public handleOpenContactUserDialog($event: Event): void {
    $event.stopPropagation();

    this.chatService
      .getCurrUser()
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((user: ChatUser): Observable<any> => {
          const recipientUser = {
            id: parseInt(this.recipientUserID, 10),
            name: this.recipientUserName,
            email: this.recipientUserContactEmail,
            avatar: null,
          } as ChatUser;

          return this.chatService.openChatDialog(recipientUser, user);
        })
      )
      .subscribe();
  }
}
