<mat-form-field appearance="fill" class="pr-4 w-100p select-without-label">
  <mat-chip-list #locationList>
    <mat-chip
      *ngFor="let country of selectedCountries"
      [selectable]="true"
      [removable]="true"
      (removed)="remove(country)"
      class="grey-chip"
    >
      {{ 'COUNTRIES.' + country?.code | uppercase | translate }}
      <mat-icon matChipRemove>close</mat-icon>
    </mat-chip>
    <input
      #itemInput
      [placeholder]="!!inputPlaceholder ? inputPlaceholder : null"
      [matAutocomplete]="auto"
      [matChipInputFor]="locationList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      [formControl]="formControl"
      (focusin)="handleFocusIn()"
      (focusout)="hasFocus = false"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let filteredCountry of filteredCountries | async" [value]="filteredCountry.code">
      {{ 'COUNTRIES.' + filteredCountry.code | uppercase | translate }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="!!error">{{ error | translate }}</mat-error>
</mat-form-field>
