import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GtmAction } from './gtm-action';
import { googleTagManagerId } from '../../../environments/environment';
import { User } from '../user/user';
import { AppTypeGtm } from './model/app-type-gtm';
import { RolesEnum } from '../../vo/roles/roles';
import { USER_ROLES } from '../../utils/Constants';
import { combineLatest, Observable } from 'rxjs';
import { SCOPES } from '../permission/scopes';
import { getScopesSelector } from '../../store/authentication/authentication.selector';
import { filter, map } from 'rxjs/operators';
import { includes } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { getCurrentUserSelector } from '../../store/user/user.selector';

@Injectable({ providedIn: 'root' })
export class GtmManagerService {
  constructor(private gtmService: GoogleTagManagerService, private store: Store<AppState>) {}

  private invalid(isAdmin: boolean): boolean {
    return isAdmin || googleTagManagerId === undefined || googleTagManagerId.length === 0;
  }

  private flushData(data: object): Promise<void> {
    Object.keys(data).forEach((key) => (data[key] = undefined));
    return new Promise(() => {
      this.gtmService.pushTag({ ...data });
    });
  }

  private getDefaultProps(user: User): DefaultProps {
    return {
      user_id: user?.id,
      app_type: !!user ? (user.role === RolesEnum.SUPPLIER ? 'supplier' : 'retailer') : undefined,
    };
  }

  addGtmToDom(): Observable<boolean> {
    return this.getIsAdminObs().pipe(
      map((isAdmin) => {
        if (this.invalid(isAdmin)) {
          return false;
        }
        this.gtmService.addGtmToDom();
        return true;
      })
    );
  }

  async pushTag(event: GtmAction): Promise<void> {
    combineLatest([this.getUser(), this.getIsAdminObs()]).subscribe(async ([user, isAdmin]) => {
      if (!this.invalid(isAdmin)) {
        await this.gtmService.pushTag({ event: event.event, ...this.getDefaultProps(user), ...event.payload });
        await this.flushData({ ...event.payload });
      }
    });
    return Promise.resolve();
  }

  private getPermissionsObs(): Observable<SCOPES[]> {
    return this.store.select(getScopesSelector(false));
  }

  private getIsAdminObs(): Observable<boolean> {
    return this.getPermissionsObs().pipe(map((permissions) => includes(permissions, SCOPES.ADMIN)));
  }

  private getUser(): Observable<User> {
    return this.store.select(getCurrentUserSelector).pipe(filter((user) => !!user));
  }
}

interface DefaultProps {
  user_id: number;
  app_type: AppTypeGtm;
}
