import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TemplateType } from 'app/service/image-templates/image-templates.service';
import { ConvertImagesToPng } from 'app/utils/convert-images-to-png';
import { concatMap, Observable, throwError } from 'rxjs';
import { SupplierImageType, SuppliersService } from '../../../service/suppliers/suppliers.service';
import { ConditionLoadingDirective } from '../../directives/condition-loading.directive';
import { ShowOnBreakpointDirective } from '../../directives/show-on-breakpoint.directive';
import { SupplierLogoFromUseridPipe } from '../../pipes/supplier-logo-from-userid.pipe';
import {
  AlreadyUploadedFile,
  DragAndDropFileComponent,
  DragAndDropFilePreviewVariant,
} from '../drag-and-drop-file/drag-and-drop-file.component';

@Component({
  selector: 'app-upload-supplier-image',
  standalone: true,
  imports: [CommonModule, DragAndDropFileComponent, ConditionLoadingDirective, ShowOnBreakpointDirective],
  templateUrl: './upload-supplier-image.component.html',
  styleUrls: ['./upload-supplier-image.component.scss'],
  providers: [SupplierLogoFromUseridPipe],
})
export class UploadSupplierImageComponent implements OnInit {
  @Input() resourcePath: string;
  @Input() type: SupplierImageType;
  @Input() title?: string;
  @Input() tooltip?: string;
  @Input() previewVariant: DragAndDropFilePreviewVariant = 'grid';
  @Input() helperImageSrc?: string;
  @Input() helperText?: string;
  @Input() variant: UploadSupplierImageVariant = 'default';
  @Input() maxWidth: number;
  @Input() maxHeight: number;
  @Input() maxFileSize: number;
  @Input() templateType: TemplateType = TemplateType.NONE;
  @Input() required = true;
  @Output() changed = new EventEmitter<void>();
  savedImageUrl: AlreadyUploadedFile;
  file: File;
  alreadyClosed: boolean;
  isLoading = true;
  allowedExtensions = ['png', 'jpg', 'jpeg', 'webp', 'bmp'];
  changedEmitted = false;

  constructor(private suppliersService: SuppliersService) {}

  ngOnInit(): void {
    this.checkIfImageExists(this.resourcePath, (exists) => {
      const splitArray = this.resourcePath.split('/');
      if (exists) {
        this.savedImageUrl = { src: this.resourcePath, filenameWithExtension: splitArray[splitArray.length - 1] };
      }
      this.isLoading = false;
    });
  }

  private checkIfImageExists(url: string, callback: (exist: boolean) => void): void {
    const img = new Image();
    img.src = url;
    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };
      img.onerror = () => {
        callback(false);
      };
    }
  }

  save(): Observable<void> {
    if (this.isValid) {
      return ConvertImagesToPng.convert([this.file]).pipe(
        concatMap((convertedFile) => this.suppliersService.saveSupplierImage(convertedFile[0], this.type))
      );
    } else {
      throw throwError('INVALID');
    }
  }

  handleChange(): void {
    this.changedEmitted = true;
    this.changed.emit();
  }

  get fileArray(): File[] {
    return !!this.file ? [this.file] : [];
  }

  get isValid(): boolean {
    return this.required
      ? (this.alreadyClosed && !this.savedImageUrl) || !!this.file || (!this.alreadyClosed && !!this.savedImageUrl)
      : true;
  }
}

export type UploadSupplierImageVariant = 'default' | 'for-full-width';
