import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from '../rest/rest.service';
import { CatalogSidebarService } from '../catalog-sidebar/catalog-sidebar.service';
import { EcomVO } from '../ecom/ecom.service';
import { MicroserviceNames, SpringRestService } from '../rest/microservices/spring-rest.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  constructor(
    public catalogSidebarService: CatalogSidebarService,
    private restService: RestService,
    private _springRestService: SpringRestService
  ) {}

  public getCurrenciesExhangesByCurrency(currency: string): Observable<CurrencyExchange[]> {
    return this._springRestService.get(MicroserviceNames.USER, `/Currency/Changes`, { currency }, true);
  }

  public getRetailerCurrencies(): Observable<Currencies> {
    return this.restService
      .get<RetailerCurrencies>(`CurrencyService/getRetailerCurrencies`)
      .pipe(map((response) => response.getFirstData().currencies));
  }

  public saveDefaultCurrency(ecomID, preferredCurrency): Observable<any> {
    // we'll use it for suppliers too, so sorry
    return this.restService
      .post(`CurrencyService/setPreferredCurrencyForRetailer`, {
        ecomId: ecomID,
        preferredCurrency: preferredCurrency === 'default' ? '' : preferredCurrency,
      })
      .pipe(map((response) => response.getFirstData()));
  }

  public getCurrencyFromTo(params): Observable<any> {
    return this.restService.get(`CurrencyService/getCurrencyData`, params).pipe(map((response) => response.getData()));
  }

  public static getCurrency(ecom: EcomVO = null): string {
    if (ecom) {
      if (!!ecom.options.preferredCurrency && !!ecom.options.preferredCurrency.length) {
        return ecom.options.preferredCurrency;
      } else if (!!ecom.options.currency && !!ecom.options.currency.length) {
        return ecom.options.currency.toUpperCase();
      } else {
        return 'USD';
      }
    } else {
      return localStorage.getItem('preferredCurrency') || 'USD';
    }
  }

  private getLocalCurrency(): string {
    if (localStorage.getItem('preferredCurrency')) {
      return localStorage.getItem('preferredCurrency');
    } else {
      return 'USD';
    }
  }

  private getSelectedEcomCurrency(selectedEcom: EcomVO): string {
    if (!!selectedEcom.options.preferredCurrency && !!selectedEcom.options.preferredCurrency.length) {
      return selectedEcom.options.preferredCurrency;
    } else if (!!selectedEcom.options.currency && !!selectedEcom.options.currency.length) {
      return selectedEcom.options.currency.toUpperCase();
    } else {
      return 'USD';
    }
  }
}

interface RetailerCurrencies {
  currencies: Currencies;
}

export interface Currencies {
  [vat: string]: number;
}

export interface CurrencyExchange {
  from: string;
  to: string;
  rate: number;
}
