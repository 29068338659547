<ng-container *ngIf="catalogs.length > 0; else noCatalog">
  <div fxLayoutAlign="space-between center" class="syncee-grey-200-border-bottom mb-8" *ngIf="catalogs.length > 1">
    <div>{{ 'TOOLBAR.MODAL.SELECT_LIST.TITLE' | translate }}</div>
    <mat-icon class="s-20 icon-filled" [matTooltip]="'TOOLBAR.MODAL.SELECT_LIST.TOOLTIP' | translate">info</mat-icon>
  </div>
  <div class="catalog-overlay-list-with-selector-container">
    <!--  <ng-container>-->
    <div fxLayoutAlign="center center" fxLayoutGap="8px" *ngIf="catalogs.length > 1">
      <div fxFlex="1 0 0">
        <mat-form-field class="select-without-label input-without-padding" appearance="fill">
          <mat-select
            [value]="(selectedCatalog$ | async)?.id"
            (valueChange)="handleCatalogSelectorValueChange($event)"
            disableOptionCentering
            panelClass="mat-select-display-change"
          >
            <mat-option *ngFor="let catalog of catalogs" [value]="catalog.id">
              {{ catalog.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="product-list syncee-grey-200-border-bottom" [class.pt-12]="catalogs.length > 1">
      <ng-container *ngIf="!productLoading; else spinner">
        <ng-container *ngIf="products.length > 0; else noProducts">
          <div class="product" *ngFor="let product of products" fxLayoutAlign="center center" fxLayoutGap="8px">
            <div fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="1 1 0" class="min-width-0">
              <img
                fxFlex="0 0 auto"
                [src]="product.IMAGES[0] | productImageThumbnailCdn"
                [productImageErrorHandler]="product.IMAGES[0]"
                [checkedOriginal]="true"
              />
              <div fxFlex="1 1 0" class="min-width-0">
                <div class="title">{{ product.TITLE }}</div>
                <div class="price" *ngIf="!!product">
                  {{
                    product
                      | getPrice
                      | currencyExchangeToSelected : product.SUPPLIER?.paymentCurrency ?? 'USD'
                      | async
                      | currencyTransformToSelected
                      | async
                  }}
                </div>
              </div>
            </div>
            <div class="badges">
              <ng-container *ngIf="recentlyAddedProductIds | async as productIds">
                <div class="badge new" *ngIf="productIds && productIds.includes(product.ID)">
                  {{ 'TOOLBAR.MODAL.NEW' | translate }}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <ng-template #noProducts>
          <div class="no-product">{{ 'TOOLBAR.MODAL.NO_PRODUCT' | translate }}</div>
        </ng-template>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-template #spinner>
  <div fxLayoutAlign="center center" class="mb-16">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
</ng-template>
<ng-template #noCatalog>
  <div class="mb-16">{{ 'TOOLBAR.MODAL.NO_CATALOG' | translate }}</div>
</ng-template>
