import { getPlatformByType } from '../platforms/platform-config';
import { EcomTypeEnum } from '../../vo/enums/ecom-type-enum';

export const INTEGRATION_CARDS_RETAILER = [
  {
    name: 'shopify',
    logo: getPlatformByType(EcomTypeEnum.SHOPIFY).images.black_logo,
  },
  {
    name: 'wix',
    logo: getPlatformByType(EcomTypeEnum.WIX).images.black_logo,
  },
  {
    name: 'squarespace',
    logo: getPlatformByType(EcomTypeEnum.SQUARESPACE).images.black_logo,
  },
  {
    name: 'woocommerce',
    logo: getPlatformByType(EcomTypeEnum.WOOCOMMERCE).images.black_logo,
  },
  {
    name: 'shopify-plus',
    logo: 'assets/images/integrations/shopify-plus.png',
  },
  {
    name: 'bigcommerce',
    logo: getPlatformByType(EcomTypeEnum.BIGCOMMERCE).images.black_logo,
  },
  {
    name: 'ecwid-by-lightspeed',
    logo: getPlatformByType(EcomTypeEnum.ECWID).images.black_logo,
  },
  {
    name: 'jumpseller',
    logo: getPlatformByType(EcomTypeEnum.JUMPSELLER).images.black_logo,
  },
  {
    name: 'shoprenter',
    logo: getPlatformByType(EcomTypeEnum.SHOPRENTER).images.black_logo,
  },
];
