import { Component, OnInit } from '@angular/core';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { BreadcrumbItem, FuseConfig } from '../../../../@fuse/types';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Params, RouterLink } from '@angular/router';
import { SafeHtml } from '@angular/platform-browser';
import { Utils } from 'app/utils/utils';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { join, map } from 'lodash';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDividerModule, TranslateModule, RouterLink, NgxJsonLdModule],
})
export class BreadcrumbComponent implements OnInit {
  fuseConfig: FuseConfig;

  path: string[];
  jsonLD: SafeHtml;

  private _unsubscribeAll: Subject<void>;

  constructor(private _fuseConfigService: FuseConfigService, private translateService: TranslateService) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.initFuseConfig();
  }

  private initFuseConfig(): void {
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.fuseConfig = config;
      this.subscribeToTranslateStream();
    });
  }

  private subscribeToTranslateStream(): void {
    this.translateService
      .stream('FORM_VALIDATORS.REQUIRED')
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((data) => !!data)
      )
      .subscribe(() => {
        this.addJsonLD();
      });
  }

  private buildBreadcrumbURL(pathElement: BreadcrumbItem): string {
    if (pathElement.url === '/') {
      return `https://${location.hostname}`;
    }
    return !Utils.isNullOrUndefined(pathElement.url)
      ? `https://${location.hostname}${pathElement.url}${this.flattenQueryParams(pathElement.queryParams)}`
      : '';
  }

  private flattenQueryParams(queryParams: Params): string {
    if (Utils.isNullOrUndefined(queryParams)) {
      return '';
    }
    return `?${join(
      map(queryParams, (value, key) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`),
      '&'
    )}`;
  }

  private addJsonLD(): void {
    this.jsonLD = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        this.fuseConfig.layout.breadcrumb.path.map((pathElement, index) => {
          return {
            '@type': 'ListItem',
            position: index + 1,
            name: this.translateService.instant(pathElement.translationKey) ?? pathElement.translationKey,
            item: this.buildBreadcrumbURL(pathElement),
          };
        }),
      ],
    };
  }
}
