import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AppState } from '../../../../app.state';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { SearchWidgetService } from '../../../../service/search-widget/search-widget.service';
import { StaticCtaService } from '../../../../service/static-pages/static-cta.service';
import { BrandsSliderByLocationComponent } from '../../../../shared/components/brands-slider-by-location/brands-slider-by-location.component';
import { CategoryCardsComponent } from '../../../../shared/components/category-cards/category-cards.component';
import { FaqColumnsComponent } from '../../../../shared/components/faq-columns/faq-columns.component';
import { FeatureCardsRowComponent } from '../../../../shared/components/feature-cards-row/feature-cards-row.component';
import { IntegrationsCardGridComponent } from '../../../../shared/components/integrations-card-grid/integrations-card-grid.component';
import { ReviewsSliderComponent } from '../../../../shared/components/reviews-slider/reviews-slider.component';
import { SelectButtonComponent } from '../../../../shared/components/select-button/select-button.component';
import { StepflowItem, StepflowRowComponent } from '../../../../shared/components/stepflow-row/stepflow-row.component';
import { SuppliersSliderByFilterComponent } from '../../../../shared/components/suppliers-slider-by-filter/suppliers-slider-by-filter.component';
import { SynceeCtaTwoColumnsComponent } from '../../../../shared/components/syncee-cta-two-columns/syncee-cta-two-columns.component';
import { SynceeCtaComponent } from '../../../../shared/components/syncee-cta/syncee-cta.component';
import { VideoDialogThumbnailComponent } from '../../../../shared/components/video-dialog-thumbnail/video-dialog-thumbnail.component';
import { YoutubeOpenVideoDialogComponent } from '../../../../shared/components/youtube-open-video-dialog/youtube-open-video-dialog.component';
import { TranslationCallbackDirective } from '../../../../shared/directives/translation-callback.directive';
import { GetAssetFromStoragePipe } from '../../../../shared/pipes/get-asset-from-storage';
import { FeatureCardsWithSquareIconComponent } from '../../../../static/components/feature-cards-with-square-icon/feature-cards-with-square-icon.component';
import { AddFilterWithRedirectAction } from '../../../../store/product-search/product-search.action';
import FaqSectionTranslationMapper from '../../../../utils/faq/faq-section-translation-mapper';
import {
  HOMEPAGE_GUEST_FEATURE_CARDS,
  HOMEPAGE_GUEST_FEATURE_CARDS_WITH_ACTION,
  HOMEPAGE_GUEST_FEATURE_CARDS_WITH_SQUARE_ICON,
} from '../../../../utils/feature-card/constants';
import { BRAND_NUMBER, USER_NUMBER } from '../../../../utils/marketing-constants';
import { omitNullOrUndefined } from '../../../../utils/operator/omit-null-or-undefined';
import { HomepageBannerSelectButton } from '../../../../utils/static/homepage-banner-select-button';
import { INTEGRATION_CARDS_RETAILER } from '../../../../utils/static/integration-cards';
import { SYNCEE_STATIC_REVIEWS_RETAILER } from '../../../../utils/static/syncee-static-reviews';
import { FaqSection } from '../../../../vo/faq/faq-section';
import { GeneralButton } from '../../../../vo/general-button/general-button';
import { Widget, WidgetType } from '../../../../vo/widget';
import { Header1Component } from '../../components/header-1/header-1.component';
import { LeftAndRightSectionWithGeneralButtonComponent } from '../../components/left-and-right-section-with-general-button/left-and-right-section-with-general-button.component';
import { LeftAndRightSectionWithListingDoubleBorderComponent } from '../../components/left-and-right-section-with-listing-double-border/left-and-right-section-with-listing-double-border.component';
import { WidgetCardsByWidgetTypeComponent } from '../../components/widget-cards-by-widget-type/widget-cards-by-widget-type.component';
import {
  ArticleCardsRowComponent,
  ArticleCardsRowItem,
} from '../../../../shared/components/article-cards-row/article-cards-row.component';
import { HOME_PAGE_GUEST_ARTICLE_CARDS } from '../../../../utils/static/article-cards';

@UntilDestroy()
@Component({
  selector: 'app-homepage-guest',
  standalone: true,
  imports: [
    CommonModule,
    Header1Component,
    TranslateModule,
    SelectButtonComponent,
    FlexModule,
    GetAssetFromStoragePipe,
    YoutubeOpenVideoDialogComponent,
    LeftAndRightSectionWithGeneralButtonComponent,
    StepflowRowComponent,
    LeftAndRightSectionWithListingDoubleBorderComponent,
    FeatureCardsRowComponent,
    WidgetCardsByWidgetTypeComponent,
    CategoryCardsComponent,
    VideoDialogThumbnailComponent,
    FeatureCardsWithSquareIconComponent,
    SynceeCtaComponent,
    IntegrationsCardGridComponent,
    ReviewsSliderComponent,
    SuppliersSliderByFilterComponent,
    BrandsSliderByLocationComponent,
    SynceeCtaTwoColumnsComponent,
    FaqColumnsComponent,
    TranslationCallbackDirective,
    NgOptimizedImage,
    ArticleCardsRowComponent,
  ],
  templateUrl: './homepage-guest.component.html',
  styleUrls: ['./homepage-guest.component.scss'],
})
export class HomepageGuestComponent implements OnInit {
  protected readonly BRAND_NUMBER = BRAND_NUMBER;
  protected readonly HOMEPAGE_GUEST_FEATURE_CARDS = HOMEPAGE_GUEST_FEATURE_CARDS;
  protected readonly HOMEPAGE_GUEST_FEATURE_CARDS_WITH_ACTION = HOMEPAGE_GUEST_FEATURE_CARDS_WITH_ACTION;
  protected readonly HOMEPAGE_GUEST_FEATURE_CARDS_WITH_SQUARE_ICON = HOMEPAGE_GUEST_FEATURE_CARDS_WITH_SQUARE_ICON;
  protected readonly INTEGRATION_CARDS = INTEGRATION_CARDS_RETAILER;
  protected readonly SYNCEE_STATIC_REVIEWS = SYNCEE_STATIC_REVIEWS_RETAILER;
  protected readonly bannerSelectButtonItems = HomepageBannerSelectButton;
  protected readonly userNumber = USER_NUMBER;
  protected readonly WIDGET_TYPE = WidgetType;
  protected readonly stepflowSteps: StepflowItem[] = [
    {
      icon: 'file_upload',
      titleKey: 'EXPLORE_PRODUCTS.GUEST.STEPFLOW.STEPS.1.TITLE',
      descriptionKey: 'EXPLORE_PRODUCTS.GUEST.STEPFLOW.STEPS.1.DESCRIPTION',
    },
    {
      icon: 'local_mall',
      titleKey: 'EXPLORE_PRODUCTS.GUEST.STEPFLOW.STEPS.2.TITLE',
      descriptionKey: 'EXPLORE_PRODUCTS.GUEST.STEPFLOW.STEPS.2.DESCRIPTION',
    },
    {
      icon: 'payment',
      titleKey: 'EXPLORE_PRODUCTS.GUEST.STEPFLOW.STEPS.3.TITLE',
      descriptionKey: 'EXPLORE_PRODUCTS.GUEST.STEPFLOW.STEPS.3.DESCRIPTION',
    },
    {
      icon: 'local_shipping',
      titleKey: 'EXPLORE_PRODUCTS.GUEST.STEPFLOW.STEPS.4.TITLE',
      descriptionKey: 'EXPLORE_PRODUCTS.GUEST.STEPFLOW.STEPS.4.DESCRIPTION',
    },
    {
      icon: 'savings',
      titleKey: 'EXPLORE_PRODUCTS.GUEST.STEPFLOW.STEPS.5.TITLE',
      descriptionKey: 'EXPLORE_PRODUCTS.GUEST.STEPFLOW.STEPS.5.DESCRIPTION',
    },
  ];
  protected readonly blueButton: GeneralButton = {
    type: 'callback',
    textKey: 'EXPLORE_PRODUCTS.GUEST.SELL_MORE_TOGETHER.BUTTON',
    buttonClass: 'syncee-blue-button',
    callback: this.startClickedRetailer.bind(this),
  };
  protected readonly arrowButton: GeneralButton = {
    type: 'callback',
    textKey: 'EXPLORE_PRODUCTS.GUEST.YOU_FOCUS_ON_SALES.BUTTON',
    buttonClass: 'syncee-remove-button-layout syncee-button-arrow',
    style: {
      disableRipple: true,
    },
    callback: this.startClickedRetailer.bind(this),
  };
  protected readonly whiteRetailer: GeneralButton = {
    type: 'callback',
    textKey: 'EXPLORE_PRODUCTS.GUEST.CTA_TWO_COLUMNS.COLUMNS.LEFT.BUTTON',
    buttonClass: 'syncee-cta-button',
    callback: this.startClickedRetailer.bind(this),
  };
  protected readonly whiteSupplier: GeneralButton = {
    type: 'callback',
    textKey: 'EXPLORE_PRODUCTS.GUEST.CTA_TWO_COLUMNS.COLUMNS.RIGHT.BUTTON',
    buttonClass: 'syncee-cta-button',
    callback: this.startClickedSupplier.bind(this),
  };
  protected articleCardsWithCallback: ArticleCardsRowItem[];
  ltMd: boolean;
  faq: FaqSection;

  constructor(
    private router: Router,
    private screenManagerService: ScreenManagerService,
    private searchWidgetService: SearchWidgetService,
    private store: Store<AppState>,
    private staticCtaService: StaticCtaService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.subscribeToScreenChange();
    this.addCallbackToArticleCards();
    this.getFaq();
  }

  handleBannerChanged(): void {
    this.router.navigate(['/suppliers']);
  }

  handleWidgetSelected(widget: Widget): void {
    this.store.dispatch(
      new AddFilterWithRedirectAction({
        needRedirect: true,
        filter: this.searchWidgetService.mapWidgetFilterDataToMarketplaceFilter(widget.filterData),
      })
    );
  }

  startClickedRetailer(): void {
    this.staticCtaService.startRetailer();
  }

  startClickedSupplier(): void {
    this.staticCtaService.startSupplier();
  }

  handleFAQClassClick(event: string): void {
    switch (event) {
      case 'retailer-registration':
        this.staticCtaService.startRetailer();
        break;
      case 'supplier-registration':
        this.staticCtaService.startSupplier();
        break;
      case 'supplier-referral':
        window.open('/supplier-referral', '_blank');
        break;
    }
  }

  private addCallbackToArticleCards(): void {
    this.articleCardsWithCallback = HOME_PAGE_GUEST_ARTICLE_CARDS.map((item) => ({
      ...item,
      button: {
        ...item.button,
        callback: () => {
          if (item.button?.url) {
            window.open(item.button.url, '_blank');
          }
        },
      },
    }));
  }

  private subscribeToScreenChange(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(untilDestroyed(this), this.screenManagerService.stateMatchesOperator())
      .subscribe((matches) => (this.ltMd = matches));
  }

  private getFaq(): void {
    this.translateService
      .stream('EXPLORE_PRODUCTS.GUEST.FAQ.SECTION')
      .pipe(omitNullOrUndefined(), take(1))
      .subscribe((translation) => {
        this.faq = FaqSectionTranslationMapper.map(translation);
      });
  }
}
