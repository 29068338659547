import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { Actions, ofType } from '@ngrx/effects';
import { ChatDialogComponent } from 'app/main/chat/chat/chat-dialog/chat-dialog.component';
import { AuthActionTypes } from 'app/store/authentication/authentication.actions';
import { Utils } from 'app/utils/utils';
import { BehaviorSubject, interval, Observable, of, Subject } from 'rxjs';
import { map, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SpringPage } from '../../vo/pagination/spring-page';
import { SpringPageable } from '../../vo/pagination/spring-pageable';
import { CachedUserService } from '../cached-user/cached-user.service';
import { SpringRestService } from '../rest/microservices/spring-rest.service';
import { RestService } from '../rest/rest.service';
import { BreakPoint, ScreenManagerService } from '../screen-manager/screen-manager.service';
import { SuppliersService } from '../suppliers/suppliers.service';
import { ChatUtilityService } from './chat-utility.service';
import { ChannelItem, ChatDialogInput, ChatMessage, ChatSupplierProfile, ChatUser } from './model/chat-models';
import { ChatRoom } from './model/chat-room';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public static CHARACTER_COUNT_LIMIT = 1000;
  public static FILE_SIZE_LIMIT = 5000000;
  public static UNSEEN_MESSAGE_POOL_INTERVAL = 60000;

  public static CHAT_SERVICE_NAME = 'chatService';

  public static EMOJI_REGEX =
    /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])+$/;
  public static GET_RETAILER_SUPPLIERS = 'RetailerToCatalogService/getMySuppliersProfile';
  public static GET_SUPPLIERS_BY_SEARCH = 'SupplierDetailsService/getSupplierListBySearch';
  public static GET_CHANNEL_ROOMS = '/v1/chat-room';
  public static POST_CHANNEL_ROOM = '/v1/chat-room';
  public static GET_MESSAGES = '/v1/chat-room';
  public static CONFIRM_MESSAGE = '/v1/chat-message/{messageId}/confirm';
  public static GET_FILE = '/v1/files';
  public static GET_CHANNEL_ROOMS_V2 = '/v2/chat-room';
  public static GET_CHANNEL_ROOMS_V2_USERS = '/v2/chat-room/users';
  public static GET_UNSEEN_MESSAGES_COUNT = '/v1/chat-message/count';
  public static GET_CHAT_PROFILE_DETAILS = 'SupplierDetailsService/getSupplierDetails';

  public static SEND_MESSAGE = '/v1/chat-message';
  public static WS_MESSAGE_HANDLER = '/ws/v1/{channelId}/chat-messages';
  private socket: WebSocket;

  private _messageReceiveSubject = new Subject<any>();
  private _openCloseProfileDetails = new BehaviorSubject<boolean>(null);

  constructor(
    private restService: RestService,
    private sRestService: SpringRestService,
    private screenService: ScreenManagerService,
    private fuseService: FuseNavigationService,
    private supplierService: SuppliersService,
    private dialogService: MatDialog,
    private router: Router,
    private cachedUserService: CachedUserService,
    private chatUtilityService: ChatUtilityService,
    private actions$: Actions
  ) {}

  getChannels(sUserId): Observable<ChannelItem[]> {
    return this.sRestService
      .get(ChatService.CHAT_SERVICE_NAME, ChatService.GET_CHANNEL_ROOMS)
      .pipe(map((resp) => this.chatUtilityService.mapChatRooms(resp, sUserId)));
  }

  getChannelsPage(
    sUserId: number,
    pageable: SpringPageable,
    searchTerm?: string,
    unseen?: boolean
  ): Observable<SpringPage<ChannelItem>> {
    return this.sRestService
      .get(ChatService.CHAT_SERVICE_NAME, ChatService.GET_CHANNEL_ROOMS_V2, {
        ...pageable,
        ...(!!searchTerm && { searchTerm }),
        ...(unseen !== undefined && unseen !== null && { unseen }),
      })
      .pipe(
        map((resp) => ({ ...resp, content: this.chatUtilityService.mapChatRooms(resp.content as ChatRoom[], sUserId) }))
      );
  }

  getChannelById(recipientUserId: number, currentUserId: number): Observable<ChannelItem> {
    return this.sRestService
      .get(ChatService.CHAT_SERVICE_NAME, ChatService.GET_CHANNEL_ROOMS_V2_USERS, { userId: recipientUserId })
      .pipe(map((channel) => this.chatUtilityService.mapChatRooms([channel], currentUserId)[0]));
  }

  messageReceiveEvent(): Observable<any> {
    return this._messageReceiveSubject.asObservable();
  }

  ocProfileDetailsEvent(): Observable<boolean> {
    return this._openCloseProfileDetails.asObservable();
  }

  openProfileDetails(): void {
    this._openCloseProfileDetails.next(true);
  }

  closeProfileDetails(): void {
    this._openCloseProfileDetails.next(false);
  }

  getUnseenMessagesCount(): Observable<number> {
    return this.sRestService.get(ChatService.CHAT_SERVICE_NAME, ChatService.GET_UNSEEN_MESSAGES_COUNT).pipe(
      map((resp) => {
        return resp ? resp.unseenMessageCount : 0;
      })
    );
  }

  getUsers(): Observable<ChatUser[]> {
    return this.restService.get(ChatService.GET_RETAILER_SUPPLIERS).pipe(
      map((resp) => {
        return resp.getData().map((s) => {
          return <ChatUser>{ id: s.userId, name: s.companyName, email: s.email, avatar: s.logo || null };
        });
      })
    );
  }

  getSupplierProfileDetails(userId: number): Observable<ChatSupplierProfile> {
    const qParams = { id: userId };
    return this.restService.get(ChatService.GET_CHAT_PROFILE_DETAILS, qParams).pipe(
      map((resp) => {
        const data = resp.getFirstData();
        return <ChatSupplierProfile>{
          logo: data.logoLink,
          companyName: data.companyName,
          contactEmail: data.contactEmail,
          website: data.website,
          phone: data.publicPhoneCode && data.publicPhoneNumber && data.publicPhoneCode + data.publicPhoneNumber,
          mainWarehouseLoc: data.mainWarehouseLocation,
          description: data.description,
          yearOfFoundation: data.yearOfFoundation,
          companyAddress: data.companyAddress,
        };
      })
    );
  }

  getSuppliersBySearch(searchTerm: string): Observable<ChatUser[]> {
    return this.restService.get(ChatService.GET_SUPPLIERS_BY_SEARCH, { searchTerm }).pipe(
      map((resp) => {
        return resp.getData().map((s) => {
          return <ChatUser>{ id: s.userId, name: s.companyName, email: s.userEmail, avatar: s.logo || null };
        });
      })
    );
  }

  getChannelMessages(channelId: string, page: number = 0, size: number = 50): Observable<ChatMessage[]> {
    const params = { page, size, sort: 'id,DESC' };
    const url = ChatService.GET_MESSAGES + '/' + channelId + '/messages';
    return this.sRestService.get(ChatService.CHAT_SERVICE_NAME, url, params).pipe(
      map((resp) => {
        return resp.map((m) => {
          //  return <ChatMessage>{id: m.id, userId: m.senderId, message: m.message,
          //                       media: m.media && <ChatMedia[]>[{type:m.media.type, value: m.media.source}]}
          return this.rawMessageMap(m);
        });
      })
    );
  }

  isMobile(): boolean {
    return this.screenService.checkBreakpoint(BreakPoint.md);
  }

  createChannel(users: ChatUser[]): Observable<boolean> {
    const userAccounts = users.map((user) => {
      return {
        userId: user.id,
        name: user.name,
        email: user.email,
        avatar: { type: null, source: user.avatar || null },
      };
    });
    const body = { userAccounts };

    return this.sRestService.post(ChatService.CHAT_SERVICE_NAME, ChatService.POST_CHANNEL_ROOM, body).pipe(
      map((resp) => {
        return true;
      })
    );
  }

  openChatDialog(recipientUser: ChatUser, currUser?: ChatUser): Observable<any> {
    if (
      Utils.isNullOrUndefined(recipientUser) ||
      Utils.isNullOrUndefined(currUser) ||
      !recipientUser.id ||
      !currUser.id ||
      typeof recipientUser.id !== 'number' ||
      typeof currUser.id !== 'number' ||
      !recipientUser.email ||
      !currUser.email
    ) {
      return of(null);
    }
    return this.dialogService
      .open(ChatDialogComponent, {
        width: this.isMobile() ? '100%' : '30vw',
        minWidth: '400px',
        maxWidth: '100%',
        height: this.isMobile() ? '100vh' : '70vh',
        panelClass: this.isMobile() ? 'custom-modal-full-width-lt-md' : 'custom-modal-container',
        data: <ChatDialogInput>{ currUser: currUser, recipientUser: recipientUser },
      })
      .afterClosed();
  }

  sendMessage(channelId: string, message: string, file?: File): Observable<boolean> {
    const body = new FormData();
    body.set('chatRoomId', channelId);
    body.set('message', message);
    if (file) {
      body.set('file', file);
    }
    return this.sRestService.post(ChatService.CHAT_SERVICE_NAME, ChatService.SEND_MESSAGE, body).pipe(
      map((resp) => {
        return true;
      })
    );
  }

  confirmMessage(messageId): Observable<boolean> {
    const url = ChatService.CONFIRM_MESSAGE.replace('{messageId}', messageId);
    return this.sRestService.put(ChatService.CHAT_SERVICE_NAME, url, null).pipe(
      map((resp) => {
        return true;
      })
    );
  }

  public rawMessageMap(rawMessage: any): ChatMessage {
    const mediaSource =
      rawMessage.media &&
      rawMessage.media.source &&
      SpringRestService.fetchBaseUrl(ChatService.CHAT_SERVICE_NAME, ChatService.GET_FILE) +
        '/' +
        rawMessage.chatRoomId +
        '/' +
        rawMessage.media.source +
        `?access_token=${this.cachedUserService.getRawAuthToken()}`;
    return <ChatMessage>{
      id: rawMessage.id,
      userId: rawMessage.senderId,
      message: rawMessage.message,
      date: rawMessage.date,
      media: mediaSource && [{ type: rawMessage.media.type, value: mediaSource }],
    };
  }

  public initMessageReceiver(channelId): void {
    const url =
      SpringRestService.fetchSocketUrl(
        ChatService.CHAT_SERVICE_NAME,
        ChatService.WS_MESSAGE_HANDLER.replace('{channelId}', channelId)
      ) + `?access_token=${this.cachedUserService.getRawAuthToken()}`;
    this.socket = new WebSocket(url);
    this.socket.onmessage = (event) => {
      this._messageReceiveSubject.next(this.rawMessageMap(JSON.parse(event.data)));
    };
  }

  public closeMessageReceiver(): void {
    if (this.socket) {
      this.socket.close();
    }
  }

  public getCurrUser(): Observable<ChatUser> {
    const user = this.cachedUserService.getCachedUser();
    if (this.cachedUserService.getActualRole() == 1) {
      return this.supplierService.getSupplierDetails().pipe(
        map((details) => {
          return <ChatUser>{ id: user.id, name: details.companyName, email: user.email, avatar: details.logoLink };
        })
      );
    } else {
      return of(<ChatUser>{ id: user.id, name: user.userName, email: user.email });
    }
  }

  public isOnlyEmoji(text): boolean {
    console.log(ChatService.EMOJI_REGEX.test(text));
    return ChatService.EMOJI_REGEX.test(text);
  }

  public initUnseenMessagePolling(): Observable<number> {
    return interval(ChatService.UNSEEN_MESSAGE_POOL_INTERVAL).pipe(
      switchMap(() => this.getUnseenMessagesCount()),
      tap((count) => {
        this.unseenMessageHandle(count);
      }),
      share(),
      takeUntil(this.actions$.pipe(ofType(AuthActionTypes.LOGOUT_SUCCESS)))
    );
  }

  public closeChatAppDialog(navigateToIndexPage = true): void {
    this.dialogService.closeAll();
    if (navigateToIndexPage) {
      this.router.navigateByUrl('/');
    }
  }

  public unseenMessageForce(): Observable<number> {
    return this.getUnseenMessagesCount().pipe(tap((count) => this.unseenMessageHandle(count)));
  }

  private unseenMessageHandle(count: number): void {
    if (!this.fuseService.getCurrentNavigation()) {
      return;
    }
    this.fuseService.updateNavigationItem('inmail', {
      badge:
        count > 0
          ? {
              title: count,
              bg: 'var(--app-syncee-primary-700)',
              fg: 'var(--app-white-500)',
            }
          : null,
    });
  }
}
