import { Injectable } from '@angular/core';
import { Action, AuthorizationEcomService } from '../authorization-ecom/authorization-ecom.service';
import { MarketplaceEcomService } from '../marketplace/marketplace-ecom/marketplace-ecom.service';
import { CatalogSidebarService } from '../catalog-sidebar/catalog-sidebar.service';
import { ApprovalDialogService } from '../../shared/components/approval-dialog/approval-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import {
  AddToImportListMenuComponent,
  AddToCatalogMenuComponentData,
} from '../../main/marketplace/explore-products/add-to-import-list-menu/add-to-import-list-menu.component';
import { SearchProductVO } from '../../vo/search-product-vo';
import { RcatalogService } from '../rcatalog/rcatalog.service';
import { AppState } from '../../app.state';
import { Store } from '@ngrx/store';
import { AddRecentlyAddedProductsAction } from '../../store/rcatalogs/rcatalogs.action';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Utils } from 'app/utils/utils';
import { SetupGuideService } from '../../main/setup-guide/service/setup-guide.service';
import { SnippetEnum } from '../../main/setup-guide/enums/snippet-enums';

@Injectable({
  providedIn: 'root',
})
export class AddProductToCatalogService {
  constructor(
    private authorizationService: AuthorizationEcomService,
    private marketplaceEcomService: MarketplaceEcomService,
    private catalogSidebarService: CatalogSidebarService,
    private approvalDialogService: ApprovalDialogService,
    private dialog: MatDialog,
    private rcatalogService: RcatalogService,
    private store: Store<AppState>,
    private setupGuideService: SetupGuideService
  ) {}

  add(product: SearchProductVO): void {
    this.checkIfUserHasPermissionToAddProductToCatalog(product)
      .pipe(take(1))
      .subscribe((hasPermission) => {
        if (hasPermission) {
          this.handleHasPermissionToAddToCatalog(product);
        } else {
          this.handleNoPermission();
        }
      });
  }

  private handleNoPermission(): void {
    this.authorizationService.openSubscribeDialog(
      'PLAN_UPSELL.SPECIAL_DESCRIPTION.ADD_TO_CATALOG',
      this.marketplaceEcomService.selectedEcom
    );
  }

  private handleHasPermissionToAddToCatalog(product: SearchProductVO): void {
    const catalogIsConnected = !Utils.isNullOrUndefined(
      this.catalogSidebarService.usedCatalogList.find(
        (element) => element.catalogId.toString() === product.TASK_ID.toString()
      )
    );
    if (catalogIsConnected) {
      this.addProductToCatalog(product);
    } else {
      this.handleCatalogIsNotConnected(product);
    }
  }

  private handleCatalogIsNotConnected(product: SearchProductVO): void {
    this.openApprovalDialog(product);
  }

  private openApprovalDialog(product: SearchProductVO): void {
    this.approvalDialogService
      .open(
        product.SETTINGS.approveNeeded,
        {
          approveDescription: product.SETTINGS.approveDescription,
          needResponse: product.SETTINGS.needResponse,
          supplierName: product.SUPPLIER.companyName,
          supplierId: product.USER_ID,
          website: product.SUPPLIER.website,
          email: product.SUPPLIER.contactEmail,
          isAutoOrder: product.SUPPLIER.isAutomated,
        },
        product.TASK_ID
      )
      .subscribe((result) => {
        if (result) {
          this.addProductToCatalog(product);
        }
      });
  }

  private addProductToCatalog(product: SearchProductVO): void {
    const selectedCatalogId = this.rcatalogService.getSelectedCatalog();
    if (!Utils.isNullOrUndefined(selectedCatalogId)) {
      this.addProductToSelectedCatalog(product);
    } else {
      this.openCatalogDialog(product);
    }
  }

  private addProductToSelectedCatalog(product: SearchProductVO): void {
    this.catalogSidebarService.addProductToCatalog(product.ID).subscribe((response) => {
      this.handleProductAddedToCatalog(product, response.getFirstData());
    });
  }

  private handleProductAddedToCatalog(product: SearchProductVO, response): void {
    // TODO: add type to param
    this.store.dispatch(new AddRecentlyAddedProductsAction([{ productId: product.ID, supplierId: product.USER_ID }]));

    this.setupGuideService.setCompletedStep(SnippetEnum.RETAILER_ADD_IMPORT_LIST);

    this.catalogSidebarService.newProductAdded.emit({
      productId: product.ID,
      catalogId: response.id,
      catalogName: response.name,
    });
  }

  private openCatalogDialog(product: SearchProductVO): void {
    const dialogRef = this.dialog.open<AddToImportListMenuComponent, AddToCatalogMenuComponentData>(
      AddToImportListMenuComponent,
      {
        width: '350px',
        disableClose: true,
        data: {
          id: product.ID,
          supplierCatalogID: product.TASK_ID,
          createEmptyCatalog: this.isFirstCatalog(),
        },
      }
    );

    dialogRef.afterClosed().pipe(take(1)).subscribe();
  }

  private hasPermissionToAddToCatalog(product: SearchProductVO): Observable<boolean> {
    return this.authorizationService.hasPermissionObs(Action.PRODUCT_ADD_TO_CATALOG, {
      approveNeeded: product.SETTINGS.approveNeeded,
      premium: product.SETTINGS.premium,
      isHidden: product.IS_HIDDEN,
    });
  }

  private checkIfUserHasPermissionToAddProductToCatalog(product: SearchProductVO): Observable<boolean> {
    return this.hasPermissionToAddToCatalog(product);
  }

  private isFirstCatalog(): boolean {
    const catalogs = this.rcatalogService.getMarketplaceCatalogs();
    return catalogs === null || catalogs === undefined || catalogs.length === 0;
  }
}
