<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayoutAlign="space-between center">
    <button mat-button class="syncee-white-button" (click)="syncClicked.emit()">Sync</button>
    <app-tasks-menu [menus]="menus" [task]="task" (menuItemClicked)="menuItemClicked.emit($event)"></app-tasks-menu>
  </div>
  <div>
    <div class="h4">{{ task.name }}</div>
    <div class="typography-body-2">{{ (ecom | async)?.domain }}</div>
  </div>
  <div>
    <div class="typography-body-3">{{ task.sourceType }}</div>
    <div>
      Products: <span>{{ task.resultCount ?? 0 }}</span>
    </div>
  </div>
  <div>
    <div *ngIf="!!task.date">Last update: {{ task.date | customDate }}</div>
    <div *ngIf="!!task.nextTimeRun">Next update: {{ task.nextTimeRun | customDate }}</div>
  </div>
  <app-task-badges [task]="task"></app-task-badges>
  <app-history-indicator [data]="task.progressbar" [variant]="'expendable'"></app-history-indicator>
</div>
