import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.state';
import { SuppliersService } from 'app/service/suppliers/suppliers.service';
import { getCurrentUserIdSelector } from 'app/store/user/user.selector';
import { map, Observable, shareReplay, switchMap } from 'rxjs';

@Injectable()
export class SupplierDashboardService {
  private _supplierPaymentCurrency$: Observable<string>;

  constructor(private _store: Store<AppState>, private _supplierService: SuppliersService) {}

  getSupplierPaymentCurrency(): Observable<string> {
    if (!this._supplierPaymentCurrency$) {
      this._supplierPaymentCurrency$ = this._store.select(getCurrentUserIdSelector).pipe(
        switchMap((userId: number) => {
          return this._supplierService.getSupplierData(userId);
        }),
        map((supplierData) => supplierData.paymentCurrency || 'USD'),
        shareReplay({
          refCount: true,
          bufferSize: 1,
        })
      );
    }
    return this._supplierPaymentCurrency$;
  }
}
