import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from '../rest/rest.service';
import { BillingInfoVO, CardInfoVO } from '../../vo/billing-vo';
import { InvoiceItem } from '../../main/billing-new/model/vo/invoices';
import { Utils } from 'app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  public storeChanged: EventEmitter<string> = new EventEmitter<string>();
  public getBillingAddressRest = 'UserService/getBillingAddress';

  constructor(private restService: RestService) {}

  public getBillingInformation(): Observable<BillingInfoVO> {
    return this.restService
      .get(this.getBillingAddressRest)
      .pipe(
        map((response) => (Utils.isNullOrUndefined(response.getData()) ? response.getData() : response.getFirstData()))
      );
  }

  public getBrainTreeClientToken(): Observable<any> {
    return this.restService
      .get('PaymentService/generateBrainTreeClientToken')
      .pipe(
        map((response) => (Utils.isNullOrUndefined(response.getData()) ? response.getData() : response.getFirstData()))
      );
  }

  public getInvoiceList(ecomId: string, type: string): Observable<InvoiceItem[]> {
    switch (type) {
      case 'nummuspay':
        return this.restService
          .get(`PaymentService/listInvoicesForNummuspayCustomer?ecomId=${ecomId}`)
          .pipe(map((response) => response.getData()));
      case 'braintree':
        return this.restService
          .get(`PaymentService/listInvoicesForBraintreeCustomer?ecomId=${ecomId}`)
          .pipe(map((response) => response.getData()));
    }
  }

  public getCardInformation(ecomId = null, type: string): Observable<CardInfoVO> {
    switch (type) {
      case 'nummuspay':
        return this.restService
          .get(
            `PaymentService/getStripeCardDataForCustomer${!Utils.isNullOrUndefined(ecomId) ? '?ecomId=' + ecomId : ''}`
          )
          .pipe(map((response) => response.getFirstData()));
      case 'braintree':
        return this.restService
          .get(
            `PaymentService/getBrainTreeCardDataForCustomer${
              !Utils.isNullOrUndefined(ecomId) ? '?ecomId=' + ecomId : ''
            }`
          )
          .pipe(map((response) => response.getFirstData()));
    }
  }

  public changeCardForCustomer(restData): Observable<any> {
    return this.restService
      .post(`PaymentService/changeCardForCustomer`, restData)
      .pipe(map((response) => response.getData()));
  }

  public changeBraintreeCardForCustomer(restData): Observable<any> {
    return this.restService
      .post(`PaymentService/changeBrainTreeCardForCustomer`, restData)
      .pipe(map((response) => response.getData()));
  }

  public getBillingAddress(): Observable<any> {
    return this.restService
      .get(`UserService/getBillingAddress`)
      .pipe(
        map((response) =>
          !!response.getData()
            ? typeof response.getFirstData() === 'string'
              ? JSON.parse(response.getFirstData())
              : response.getFirstData()
            : null
        )
      );
  }

  public saveBillingInformation(restData): Observable<any> {
    return this.restService
      .post(`UserService/updateBillingAddress`, restData)
      .pipe(map((response) => response.getData()));
  }

  public connectWithPaypal(restData): Observable<any> {
    return this.restService
      .post('SynceePaymentService/createPaypalAccount', restData)
      .pipe(map((response) => response.getData()));
  }

  public deletePaypalEmail(): Observable<any> {
    return this.restService
      .post('SynceePaymentService/disconnectPaypalAccount', null)
      .pipe(map((response) => response.getData()));
  }

  public existsPaypalEmail(): Observable<any> {
    return this.restService
      .get('SynceePaymentService/getPaypalConnect')
      .pipe(map((response) => response.getFirstData()));
  }

  public savePaypalEmailTemporary(email): Observable<any> {
    return this.restService
      .post('PaymentService/connectWithPaypalTemporary', { email: email })
      .pipe(map((response) => response.getData()));
  }

  public getDeletedPaypalEmails(): Observable<any> {
    return this.restService
      .get('SynceePaymentService/getDeletedPaypalConnects')
      .pipe(map((response) => response.getData()));
  }

  public setDeletedToNullByEmail(restData): Observable<any> {
    return this.restService
      .post('SynceePaymentService/reActivatePaypalAccount', restData)
      .pipe(map((response) => response.getData()));
  }
}
