import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { FadeInOutAnimation } from 'styles/animations/fade-in-out.animation';

@Component({
  selector: 'app-open-link-wrapper',
  templateUrl: './open-link-wrapper.component.html',
  styleUrls: ['./open-link-wrapper.component.scss'],
  standalone: true,
  imports: [MatIconModule, CommonModule],
  animations: [FadeInOutAnimation],
})
export class OpenLinkWrapperComponent {
  @Input()
  icon: string;

  @Input()
  url?: string;

  @Output()
  onClick = new EventEmitter<void>();

  @Input()
  beforeIcon: TemplateRef<any>;

  public show = false;

  constructor(private router: Router) {}

  public handleMouseEnter(): void {
    this.show = true;
  }

  public handleMouseLeave(): void {
    this.show = false;
  }

  public handleClick(event: MouseEvent): void {
    event.stopPropagation();

    if (!this.url) {
      this.onClick.emit();
      return;
    }

    this.router.navigateByUrl(this.url);
  }
}
