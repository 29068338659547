import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProdSampleInputVo } from '../vo/prod-sample-input-vo';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TaskWizardSampleService } from '../services/task-wizard-sample.service';
import { ProductField } from '../../../../service/taskwizard/taskwizard.service';
import { ProductSampleIndex } from '../vo/product-sample-index';

@Component({
  selector: 'app-prod-sample-list',
  templateUrl: './prod-sample-list.component.html',
  styleUrls: ['./prod-sample-list.component.scss'],
})
export class ProdSampleListComponent implements OnInit, OnDestroy {
  get prodSampleVo(): ProdSampleInputVo {
    return this._prodSampleVo;
  }
  @Input() set prodSampleVo(value: ProdSampleInputVo) {
    this._prodSampleVo = value;
    this.index.next({ fileId: value.fileId, index: 0, retry: value.retry, catalogId: value.taskId });
  }
  private _prodSampleVo: ProdSampleInputVo;
  private unsubscribeAll: Subject<void>;
  index: BehaviorSubject<ProductSampleIndex>;
  productFields: ProductField[];

  constructor(public taskWizardSampleService: TaskWizardSampleService) {
    this.unsubscribeAll = new Subject();
    this.index = new BehaviorSubject<ProductSampleIndex>(null);
  }

  ngOnInit(): void {
    this.subscribeToIndexChange();
    this.subscribeToSample();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  nextProduct(): void {
    this.index.next({ ...this.index.value, index: this.index.value.index + 1 });
  }

  prevProduct(): void {
    this.index.next({ ...this.index.value, index: this.index.value.index - 1 });
  }

  private subscribeToIndexChange(): void {
    this.index
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((index) =>
        this.taskWizardSampleService.updateProductSample(index.catalogId, index.fileId, index.index, index.retry)
      );
  }

  private subscribeToSample(): void {
    this.taskWizardSampleService.productSample
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((sample) => (this.productFields = sample));
  }
}
