<div guestOpenRegistration>
  <button
    authenticaton
    dialogType="register"
    [roleType]="AuthDialogType.RETAILER"
    mat-button
    hasPermissionButton
    [isAdmin]="isAdmin"
    [ecom]="selectedEcom"
    [actionType]="CHAT_ACCESS"
    [descriptionTranslationKey]="'PLAN_UPSELL.SPECIAL_DESCRIPTION.MESSENGER'"
    [class]="buttonType"
    [style]="buttonStyles"
    (click)="contactSupplierDialogOpen(); $event.stopPropagation()"
  >
    <mat-icon *ngIf="!withoutIcon">chat_bubble_outline</mat-icon>
    {{ 'STOREFRONT.CONTACT_SUPPLIER' | translate }}
  </button>
</div>
