<ng-container *ngIf="dataSet && beforeDataSet; else skeleton">
  <div class="w-100p" fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="28px">
    <app-numeric-card
      fxFlex="1 0 0"
      [value]="dataSet.productsSold"
      [percentage]="getPercentage('PRODUCTS_SOLD')"
      [title]="'DASHBOARD.SUPPLIER.NUMERIC_CARD_LIST.PRODUCTS_SOLD.TITLE' | translate"
      [tooltip]="'DASHBOARD.SUPPLIER.NUMERIC_CARD_LIST.PRODUCTS_SOLD.TOOLTIP' | translate"
    >
    </app-numeric-card>
    <app-numeric-card
      fxFlex="1 0 0"
      [value]=""
      [unitOfMeasurement]="
        dataSet.revenue
          | currencyExchangeToSelected : (supplierCurrency$ | async)
          | async
          | currencyTransformToSelected
          | async
      "
      [percentage]="getPercentage('REVENUE')"
      [title]="'DASHBOARD.SUPPLIER.NUMERIC_CARD_LIST.REVENUE.TITLE' | translate"
      [tooltip]="'DASHBOARD.SUPPLIER.NUMERIC_CARD_LIST.REVENUE.TOOLTIP' | translate"
    >
    </app-numeric-card>
    <app-numeric-card
      fxFlex="1 0 0"
      [value]="dataSet.orders"
      [percentage]="getPercentage('ORDERS')"
      [title]="'DASHBOARD.SUPPLIER.NUMERIC_CARD_LIST.ORDERS.TITLE' | translate"
      [tooltip]="'DASHBOARD.SUPPLIER.NUMERIC_CARD_LIST.ORDERS.TOOLTIP' | translate"
    >
    </app-numeric-card>
  </div>
</ng-container>

<ng-template #skeleton>
  <div class="w-100p" fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="28px">
    <app-skeleton-dashboard-box
      fxFlex="1 0 0"
      [width]="30"
      [title]="'DASHBOARD.SUPPLIER.NUMERIC_CARD_LIST.PRODUCTS_SOLD.TITLE' | translate"
    ></app-skeleton-dashboard-box>
    <app-skeleton-dashboard-box
      fxFlex="1 0 0"
      [width]="30"
      [title]="'DASHBOARD.SUPPLIER.NUMERIC_CARD_LIST.REVENUE.TITLE' | translate"
    ></app-skeleton-dashboard-box>
    <app-skeleton-dashboard-box
      fxFlex="1 0 0"
      [width]="30"
      [title]="'DASHBOARD.SUPPLIER.NUMERIC_CARD_LIST.ORDERS.TITLE' | translate"
    ></app-skeleton-dashboard-box>
  </div>
</ng-template>
