import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import {
  CreateTaskStartAction,
  CreateTaskSuccessAction,
  DeleteTaskStartAction,
  GetTasksStartAction,
  GetTasksSuccessAction,
  TaskActionType,
  UpdateTaskPayload,
  UpdateTaskStartAction,
} from './tasks.actions';
import { SupplierCatalogService } from '../../service/task/supplier-catalog.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { TaskService } from '../../service/task/task.service';

@Injectable()
export class TasksEffects {
  constructor(
    private actions$: Actions,
    private supplierCatalogService: SupplierCatalogService,
    private taskService: TaskService
  ) {}

  GetTasksStart: Observable<GetTasksSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActionType.GET_TASKS_START),
      switchMap(() => {
        return this.supplierCatalogService.getAllCatalogs();
      }),
      map((catalogs) => {
        return new GetTasksSuccessAction(catalogs);
      })
    )
  );

  CreateTask: Observable<CreateTaskSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActionType.CREATE_TASK_START),
      switchMap((action: CreateTaskStartAction) => {
        const { name, taskType, ecomId, sourceType } = action.payload;
        return !!ecomId
          ? this.supplierCatalogService.createNewPlatformCatalog(name, taskType, ecomId, sourceType)
          : this.supplierCatalogService.createNewCatalog(name, taskType, sourceType);
      }),
      map(() => new CreateTaskSuccessAction())
    )
  );

  UpdateTaskStart: Observable<GetTasksStartAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActionType.UPDATE_TASK_START),
      switchMap((action: UpdateTaskStartAction) => this.getTaskUpdateApiCall(action.payload)),
      map(() => new GetTasksStartAction())
    )
  );

  DeleteTaskStart: Observable<GetTasksStartAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActionType.DELETE_TASK_START),
      switchMap((action: DeleteTaskStartAction) => this.taskService.deleteTask(action.payload)),
      map(() => new GetTasksStartAction())
    )
  );

  private getTaskUpdateApiCall(data: UpdateTaskPayload): Observable<void> {
    if (data.updateType === 'catalog') {
      return this.supplierCatalogService.patchCatalog(data.taskId, data.data);
    } else {
      return this.taskService.patchTask(data.taskId, data.data);
    }
  }
}
