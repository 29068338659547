import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ProductCardComponent } from './product-card.component';
import { ImageSelectorComponent } from './image-selector/image-selector.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { FlexModule } from '@angular/flex-layout';
import { RouterLink } from '@angular/router';
import { ProductPricesComponent } from './product-details/product-prices/product-prices.component';
import { ProductDetailsSupplierShippingComponent } from './product-details/product-details-supplier-shipping/product-details-supplier-shipping.component';
import { ProductDetailsSupplierShippingOverlayComponent } from './product-details/product-details-supplier-shipping/product-details-supplier-shipping-overlay/product-details-supplier-shipping-overlay.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { ProductCardActionComponent } from './product-card-action/product-card-action.component';
import { ProductCardActionInCatalogComponent } from './product-card-action/product-card-action-in-catalog/product-card-action-in-catalog.component';
import { ProductCardActionInStoreComponent } from './product-card-action/product-card-action-in-store/product-card-action-in-store.component';
import { ProductCardActionSelectableComponent } from './product-card-action/product-card-action-selectable/product-card-action-selectable.component';
import { ProductCardActionDefaultComponent } from './product-card-action/product-card-action-default/product-card-action-default.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ImageFallbackDirective } from '../../shared/directives/image-fallback.directive';
import { MarketplaceBadgesComponent } from '../../shared/components/marketplace-badges/marketplace-badges.component';
import { MatButtonModule } from '@angular/material/button';
import { OnlyLoggedInDirective } from '../../shared/directives/only-logged-in.directive';
import { ProductPricesGuestComponent } from './product-details/product-prices-guest/product-prices-guest.component';
import { OnlyGuestDirective } from 'app/shared/directives/only-guest.directive';
import { AuthenticationDirective } from 'app/shared/directives/authentication.directive';
import { ProductImageErrorHandlerDirective } from '../../shared/directives/product-image-error-handler.directive';
import { CountryFlagPipe } from '../../shared/pipes/country_flag.pipe';
import { ProductImageListPipe } from '../../shared/pipes/product-image-list.pipe';
import { CurrencyTransformPipe } from 'app/shared/pipes/currency-transform-pipe.pipe';
import { CreateSupplierFullUrlPipe } from 'app/shared/pipes/create-supplier-url.pipe';
import { SupplierLogoFromUseridPipe } from 'app/shared/pipes/supplier-logo-from-userid.pipe';
import { GetCategoryByLanguage } from 'app/shared/pipes/get-category-by-language.pipe';
import { CurrencyExchangeToPipe } from 'app/shared/pipes/currency-exchange-to.pipe';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import {
  ProductImageFullCdnPipe,
  ProductImageThumbnailCdnPipe,
} from '../../shared/pipes/product-image-resizer-cdn.pipe';
import { FavouriteHeartComponent } from '../../shared/components/favourite-heart/favourite-heart.component';
import { CountryCodeToNamePipe } from 'app/shared/pipes/legacy/country-code-to-name.pipe';
import { CountryNameToCodePipe } from 'app/shared/pipes/legacy/country-name-to-code.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    ProductCardComponent,
    ImageSelectorComponent,
    ProductDetailsComponent,
    ProductPricesComponent,
    ProductDetailsSupplierShippingComponent,
    ProductDetailsSupplierShippingOverlayComponent,
    ProductCardActionComponent,
    ProductCardActionInCatalogComponent,
    ProductCardActionInStoreComponent,
    ProductCardActionSelectableComponent,
    ProductCardActionDefaultComponent,
    ProductPricesGuestComponent,
  ],
  imports: [
    CommonModule,
    FlexModule,
    RouterLink,
    OverlayModule,
    MatIconModule,
    SlickCarouselModule,
    ImageFallbackDirective,
    MarketplaceBadgesComponent,
    MatButtonModule,
    OnlyLoggedInDirective,
    OnlyGuestDirective,
    AuthenticationDirective,
    ProductImageErrorHandlerDirective,
    CountryFlagPipe,
    ProductImageListPipe,
    CurrencyTransformPipe,
    CreateSupplierFullUrlPipe,
    SupplierLogoFromUseridPipe,
    GetCategoryByLanguage,
    CurrencyExchangeToPipe,
    NgOptimizedImage,
    LazyLoadImageModule,
    ProductImageThumbnailCdnPipe,
    ProductImageFullCdnPipe,
    FavouriteHeartComponent,
    CountryCodeToNamePipe,
    CountryNameToCodePipe,
    TranslateModule,
    MatTooltipModule,
  ],
  providers: [CountryNameToCodePipe],
  exports: [ProductCardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProductCardModule {}
