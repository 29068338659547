export enum GtmEvent {
  virtual_page_view = 'virtual_page_view',
  login = 'login',
  sign_up = 'sign_up',
  tutorial_begin = 'tutorial_begin',
  tutorial_complete = 'tutorial_complete',
  search = 'search',
  gs_step_retailer = 'gs_step_retailer',
  gs_step_supplier = 'gs_step_supplier',
  cancellation_by_reasons = 'cancellation_by_reasons',
  filter = 'filter',
}
