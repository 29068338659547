import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { transformToCdnUrl } from 'app/utils/transform-to-cdn-url';
import { Utils } from 'app/utils/utils';
import { SearchProductVO } from 'app/vo/search-product-vo';
import { toNumber } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class MetaTagsService {
  constructor(private titleService: Title, private metaService: Meta) {}

  addTags(title: string, description: string, imageURL: string, href: string, type: TruncateType): void {
    const _title = this.truncateTitle(title, type);
    const _description = this.truncateDescription(description);

    this.titleService.setTitle(_title);

    this.metaService.addTags([
      { name: 'description', content: _description },
      { property: 'og:title', content: _title },
      { property: 'og:description', content: _description },
      { property: 'og:image', content: imageURL },
      { property: 'og:url', content: href },
    ]);
  }

  updateTags(title: string, description: string, imageURL: string, href: string, type: TruncateType): void {
    const _title = this.truncateTitle(title, type);
    const _description = this.truncateDescription(description);

    this.titleService.setTitle(_title);

    this.metaService.updateTag({ name: 'description', content: _description });
    this.metaService.updateTag({ property: 'og:title', content: _title });
    this.metaService.updateTag({ property: 'og:description', content: _description });
    this.metaService.updateTag({ property: 'og:image', content: imageURL });
    this.metaService.updateTag({ property: 'og:url', content: href });
  }

  resetToDefaults(): void {
    const _title = 'Syncee - Global Dropshipping & Wholesale';
    const _description =
      'B2B Platform for Dropshippers, Retailers and Suppliers. Find over a million products of reliable suppliers from around the world.';
    const _image = 'https://app.syncee.co/assets/images/syncee-global-dropshipping-banner.png';
    const _href = 'https://app.syncee.co/';

    this.titleService.setTitle(_title);

    this.metaService.updateTag({ name: 'description', content: _description });
    this.metaService.updateTag({ property: 'og:title', content: _title });
    this.metaService.updateTag({ property: 'og:description', content: _description });
    this.metaService.updateTag({ property: 'og:image', content: _image });
    this.metaService.updateTag({ property: 'og:url', content: _href });
  }

  truncateDescription(description: string): string {
    let truncatedDescription = !Utils.isNullOrUndefined(description)
      ? this.normalizeText(description).substring(0, 300)
      : 'B2B Platform for Dropshippers, Retailers and Suppliers. Find over a million products of reliable suppliers from around the world.';

    truncatedDescription = truncatedDescription.substring(0, truncatedDescription.lastIndexOf(' '));

    return `${truncatedDescription}`;
  }

  truncateTitle(title: string, type: TruncateType): string {
    if (type === TruncateType.PRODUCT) {
      let truncatedTitle = (title as string).replace(/[^\p{L}\p{N}\s]/gu, '');

      while (truncatedTitle.length > 42) {
        truncatedTitle = truncatedTitle.substring(0, truncatedTitle.lastIndexOf(' '));
      }

      return `Dropship ${truncatedTitle} - Syncee`;
    } else if (type === TruncateType.SUPPLIER) {
      let truncatedTitle = (title as string).replace(/[^\p{L}\p{N}\s]/gu, '');

      while (truncatedTitle.length > 38) {
        truncatedTitle = truncatedTitle.substring(0, truncatedTitle.lastIndexOf(' '));
      }

      return `${truncatedTitle} dropshipping - Syncee`;
    } else if (type === TruncateType.SYNCEE) {
      let truncatedTitle = title as string;

      while (truncatedTitle.length > 51) {
        truncatedTitle = truncatedTitle.substring(0, truncatedTitle.lastIndexOf(' '));
      }

      return `${truncatedTitle} - Syncee`;
    }
  }

  calculateProductRRP(product: SearchProductVO): number {
    const calculatedPrice =
      Utils.getPrice(product?.VARIANTS[0]?.PRICE, product?.VARIANTS[0]?.DISCOUNTED_PRICE) *
      ((toNumber(product.SETTINGS?.possibleMargin) + 100) / 100);
    return calculatedPrice;
  }

  normalizeText(value: string): string {
    return !Utils.isNullOrUndefined(value) ? value.replace(/<[^>]*>+/g, '').replace(/^\s*[\r\n]+/gm, '') : '';
  }
}

export enum TruncateType {
  SUPPLIER = 'supplier',
  PRODUCT = 'product',
  SYNCEE = 'syncee',
}

export const SYNCEE_FULL_LOGO_URL = transformToCdnUrl('logos/syncee-logo.svg');
export const SYNCEE_BAG_LOGO_URL = transformToCdnUrl('logos/syncee-bag-logo-with-bg.png');
