import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { PlanBox, PlanUpsellBoxComponent } from '../plan-upsell-box/plan-upsell-box.component';
import { Router } from '@angular/router';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { Utils } from '../../../utils/utils';
import { MarketplaceEcomService } from '../../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PLANS_TO_DATAFEED_SHOW, PLANS_TO_MARKETPLACE_SHOW } from '../../../utils/Constants';
import { CommonModule } from '@angular/common';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-subscription-upsell',
  templateUrl: './subscription-upsell.component.html',
  styleUrls: ['./subscription-upsell.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    ExtendedModule,
    TranslateModule,
    MatSlideToggleModule,
    MatChipsModule,
    FormsModule,
    MatButtonModule,
    PlanUpsellBoxComponent,
  ],
})
export class SubscriptionUpsellComponent implements OnInit, OnDestroy {
  @Output() laterEvent = new EventEmitter<void>();
  @Output() redirectedEvent = new EventEmitter<void>();
  @Input() variant: 'datafeed' | 'marketplace' = 'marketplace';
  @Input() descriptionTranslationKey = 'PLAN_UPSELL.DESCRIPTION';

  @Input() title: string;
  @Input() buttonClass = 'close';
  @Input() selectedEcom: EcomVO = null;
  @Input() hasActionBar = true;
  trialValid = false;
  private _unsubscribeAll: Subject<void>;

  public plansToMarketplaceShow: PlanBox[] = PLANS_TO_MARKETPLACE_SHOW;
  public plansToDataFeedShow: PlanBox[] = PLANS_TO_DATAFEED_SHOW;
  public isAnnual = true;

  constructor(private router: Router, private ngZone: NgZone, private marketplaceEcomService: MarketplaceEcomService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.subscribeToPlansLoaded();
    this.trialValid = this.isTrialValid();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  buttonClicked(data: { link: string; id: number }): void {
    const queryParams = {
      default: `${this.variant === 'datafeed' ? 'df' : 'rmp'}-${data.id}`,
    };
    if (this.isAnnual) {
      queryParams['cycle'] = 'annual';
    }
    this.ngZone.run(() => {
      this.router.navigate([data.link], { queryParams });
      this.redirectedEvent.emit();
    });
  }

  laterClicked(): void {
    this.laterEvent.emit();
  }

  handleAnnualBadgeClicked(): void {
    this.isAnnual = true;
  }

  isTrialValid(): boolean {
    return this.marketplaceEcomService.isFreeTrialAvailable(this.selectedEcom);
  }

  private subscribeToPlansLoaded(): void {
    this.marketplaceEcomService
      .getEcomWithSubscriptionFromStore()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((ecom) => {
        if (!Utils.isNullOrUndefined(ecom)) {
          this.selectedEcom = ecom;
        }
      });
  }

  private subscribeToEcomInit(): void {
    this.marketplaceEcomService.initialized.pipe(takeUntil(this._unsubscribeAll)).subscribe((elem) => {
      this.marketplaceEcomService.setPlansForSelectedEcom();
    });
  }
}
