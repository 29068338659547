import { Component, Input, OnInit } from '@angular/core';
import { CategoryMappingData } from '../../model/category-mapping-data';

@Component({
  selector: 'app-wizard-category-mapping-header',
  templateUrl: './wizard-category-mapping-header.component.html',
  styleUrls: ['./wizard-category-mapping-header.component.scss'],
})
export class WizardCategoryMappingHeaderComponent implements OnInit {
  @Input() displayedItems: CategoryMappingData[];
  constructor() {}

  ngOnInit(): void {}
}
