import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AlertMultiComponent } from '../alert-multi/alert-multi.component';
import { FlexModule } from '@angular/flex-layout';
import { ConnectStatus, PaymentAccount } from '../../../service/payment/payment.service';
import { environment } from '../../../../environments/environment';
import { PAYPAL_VALID_COUNTRY_CODES, STRIPE_VALID_COUNTRY_CODES } from '../../../utils/Constants';
import { MatCardModule } from '@angular/material/card';
import { LoginWithPaypalComponent } from '../login-with-paypal/login-with-paypal.component';
import { StripeConnectComponent } from '../stripe-connect/stripe-connect.component';
import { SupplierTaxSettingsComponent } from '../supplier-tax-settings/supplier-tax-settings.component';
import { NoSearchResultsComponent } from '../no-search-results/no-search-results.component';
import { PaypalOnboardComponent } from '../paypal/paypal-onboard/paypal-onboard.component';

@Component({
  selector: 'app-connect-payment-provider',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    AlertMultiComponent,
    FlexModule,
    MatCardModule,
    LoginWithPaypalComponent,
    StripeConnectComponent,
    SupplierTaxSettingsComponent,
    NoSearchResultsComponent,
    PaypalOnboardComponent,
  ],
  templateUrl: './connect-payment-provider.component.html',
  styleUrls: ['./connect-payment-provider.component.scss'],
})
export class ConnectPaymentProviderComponent implements OnInit {
  @Input() flexDirection = 'column';

  @Input()
  paymentAccount: PaymentAccount;
  @Output()
  private connectStatusChanged: EventEmitter<ConnectStatus> = new EventEmitter<ConnectStatus>();

  @Input()
  redirectPaypalUrl = environment.paypal.gsRedirectUrl;
  @Input()
  redirectStripeUrl = environment.stripe.gsRedirectUrl;
  stripeExistsInCountry: boolean;
  stripeValidCountries = STRIPE_VALID_COUNTRY_CODES;
  paypalValiCountries = PAYPAL_VALID_COUNTRY_CODES;
  paypalConnectStatus: boolean;
  stripeConnectStatus: boolean;

  constructor() {}

  ngOnInit(): void {
    this.stripeExistsInCountry = this.stripeValidCountries.includes(this.paymentAccount?.paymentAddress.country);
  }

  paypalConnectStatusEvent(status): void {
    this.paypalConnectStatus = status;
    this.emitConnectStatus();
  }

  stripeConnectStatusEvent(status): void {
    this.stripeConnectStatus = status;
    this.emitConnectStatus();
  }

  emitConnectStatus(): void {
    this.connectStatusChanged.emit({
      paypalConnectStatus: this.paypalConnectStatus,
      stripeConnectStatus: this.stripeConnectStatus,
    });
  }

  // get isPaypalExistsInCountry(): boolean {
  //     return this.paypalValiCountries.includes(this.country);
  // }
}
