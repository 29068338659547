import { Utils } from 'app/utils/utils';
import { EcomVO } from '../ecom/ecom.service';
import { PermissionPayload } from './authorization-ecom.service';
import { isEqual, some } from 'lodash';

export enum Action {
  CONTACTS_READ,
  CHAT_ACCESS,
  SYNC_CATALOG_MARKETPLACE,
  SYNC_ORDERS,
  SYNC_CATALOG_DATAFEED,
  EDIT_PIM,
  EDIT_VARIANT_RENAMING,
  PRODUCT_ADD_TO_CATALOG,
  USE_SUPPLIER_TASK,
  CREATE_CATALOG,
}

export const permissionValidatorObj = {
  [Action.CONTACTS_READ]: validateContactsRead,
  [Action.CHAT_ACCESS]: validateChatAccess,
  [Action.SYNC_CATALOG_MARKETPLACE]: validateSyncMarketplace,
  [Action.SYNC_ORDERS]: validateSyncMarketplace,
  [Action.SYNC_CATALOG_DATAFEED]: validateSyncDatafeed,
  [Action.EDIT_PIM]: validatePIM,
  [Action.EDIT_VARIANT_RENAMING]: validateVariantRename,
  [Action.PRODUCT_ADD_TO_CATALOG]: validateAddProductToCatalog,
  [Action.USE_SUPPLIER_TASK]: validateUseSupplierTask,
  [Action.CREATE_CATALOG]: validateCreateCatalog,
};

function validateAddProductToCatalog(ecom: EcomVO, payload?: Partial<PermissionPayload>): boolean {
  if (Utils.isNullOrUndefined(ecom) || Utils.isNullOrUndefined(payload)) {
    return false;
  }
  let hasAccess = true;
  if (payload.premium && !ecom.subscriptions.rmp.premiumSupplierAccess) {
    hasAccess = false;
  }
  if (payload.approveNeeded && !ecom.subscriptions.rmp.approveSupplierAccess) {
    hasAccess = false;
  }
  if (payload.isHidden && !ecom.subscriptions.rmp.vipSupplierAccess) {
    hasAccess = false;
  }
  return hasAccess;
}

function validateContactsRead(ecom: EcomVO, payload?: Partial<PermissionPayload>): boolean {
  return !!(ecom.subscriptions.rmp.contactAccess || ecom.subscriptions.df.contactAccess);
}

function validateChatAccess(ecom: EcomVO, payload?: Partial<PermissionPayload>): boolean {
  return !!(ecom.subscriptions.rmp.accessChat || ecom.subscriptions.df.accessChat);
}

function validateSyncMarketplace(ecom: EcomVO, payload?: Partial<PermissionPayload>): boolean {
  return ecom.subscriptions.rmp.canSync;
}

function validateSyncDatafeed(ecom: EcomVO, payload?: Partial<PermissionPayload>): boolean {
  return ecom.subscriptions.df.canSync;
}

function validatePIM(ecom: EcomVO, payload?: Partial<PermissionPayload>): boolean {
  return some([isEqual(ecom?.subscriptions?.rmp.pim, true), isEqual(ecom?.subscriptions?.df?.pim, true)]);
}

function validateVariantRename(ecom: EcomVO, payload?: Partial<PermissionPayload>): boolean {
  return !!(ecom.subscriptions.rmp.variantRenaming || ecom.subscriptions.df.variantRenaming);
}

function validateUseSupplierTask(ecom: EcomVO, payload: Partial<PermissionPayload>): boolean {
  return this.validateAddProductToCatalog(ecom, payload);
}

function validateCreateCatalog(ecom: EcomVO, payload: Partial<PermissionPayload>): boolean {
  if (Utils.isNullOrUndefined(ecom) || Utils.isNullOrUndefined(payload)) {
    return false;
  }
  if (ecom.subscriptions.rmp.catalogNum === 2) {
    if (payload.catalogType === 'marketplace') {
      if (payload.numberOfFindSupplierCatalogs === 1 && payload.numberOfMarketplaceCatalogs === 0) {
        return true;
      }
      if (payload.numberOfFindSupplierCatalogs === 0 && payload.numberOfMarketplaceCatalogs < 2) {
        return true;
      }
    } else {
      if (payload.numberOfFindSupplierCatalogs === 0) {
        return true;
      }
    }
    return false;
  }
  const catNumber = payload.numberOfMarketplaceCatalogs + payload.numberOfFindSupplierCatalogs;
  return catNumber < ecom.subscriptions.rmp.catalogNum;
}
