<div
  *ngIf="!!selectedEcom; else noEcomCard"
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="32px"
  class="w-100p"
>
  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="32px" class="w-100p dashboard-content">
    <app-numeric-card-list
      class="w-100p"
      [role]="RolesEnum.RETAILER"
      [dateFrom]="dateFrom"
      [dateTo]="dateTo"
      [hidePercentage]="!dateFrom && !dateTo"
    ></app-numeric-card-list>
    <app-orders-chart class="w-100p" [dateFrom]="dateFrom" [dateTo]="dateTo"></app-orders-chart>
    <mat-card class="w-100p p-40">
      <app-top-selling-products class="w-100p" [dateFrom]="dateFrom" [dateTo]="dateTo"></app-top-selling-products>
    </mat-card>
    <mat-card class="w-100p p-40">
      <app-top-suppliers class="w-100p" [dateFrom]="dateFrom" [dateTo]="dateTo"></app-top-suppliers>
    </mat-card>
    <div class="w-100p overview-group" fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="center stretch">
      <app-overview
        [role]="RolesEnum.RETAILER"
        [currency$]="currency$"
        [overViewRecords]="overViewRecords"
        fxFlex="1 0 400px"
        fxFlex.lt-lg="0 0 0"
      ></app-overview>
      <app-products-pie-chart
        [ngClass.lt-lg]="'w-100p'"
        fxFlex="2 0 400px"
        fxFlex.lt-lg="0 0 0"
        [dateFrom]="dateFrom"
        [dateTo]="dateTo"
      ></app-products-pie-chart>
    </div>
  </div>
</div>

<ng-template #noEcomCard>
  <app-no-ecom-card subTitleKey="DASHBOARD_NEW.NO_ECOM.DESCRIPTION"></app-no-ecom-card>
</ng-template>

<ng-template #topRightTemplate>
  <div fxLayout="row" *ngIf="!!selectedEcom">
    <app-date-selector-button fxFlex="1 0 0" [(dateFrom)]="dateFrom" [(dateTo)]="dateTo"></app-date-selector-button>
  </div>
</ng-template>
