import { Component, Input } from '@angular/core';
import { VariantVO } from '../../../vo/search-product-vo';
import { SupplierOrderInfoData } from '../../../shared/components/supplier-order-info/supplier-order-info.component';
import { OrderAndCatalogInformationDialogService } from '../../../service/order-and-catalog-information-dialog/order-and-catalog-information-dialog.service';
import { CategoryNamesByIdsPipe } from '../../../shared/pipes/category-names-by-ids.pipe';
import { MinimumOrderAmountDisplayService } from '../../../service/minimum-order-amount-display/minimum-order-amount-display.service';
import { CurrencyExchangeToSelectedPipe } from '../../../shared/pipes/currency-exchange-to-selected.pipe';
import { CurrencyExchangeToPipe } from '../../../shared/pipes/currency-exchange-to.pipe';
import { CurrencyTransformToSelectedPipe } from '../../../shared/pipes/currency-transform-to-selected.pipe';
import { CurrencyTransformPipe } from '../../../shared/pipes/currency-transform-pipe.pipe';
import { Action } from '../../../service/authorization-ecom/authorization-ecom.service';
import { CountryNameToCodePipe } from '../../../shared/pipes/legacy/country-name-to-code.pipe';

@Component({
  selector: 'app-product-page-details-accordion',
  templateUrl: './product-page-details-accordion.component.html',
  styleUrls: ['./product-page-details-accordion.component.scss'],
  providers: [
    OrderAndCatalogInformationDialogService,
    CategoryNamesByIdsPipe,
    MinimumOrderAmountDisplayService,
    CurrencyExchangeToSelectedPipe,
    CurrencyExchangeToPipe,
    CurrencyTransformToSelectedPipe,
    CurrencyTransformPipe,
    CountryNameToCodePipe,
  ],
})
export class ProductPageDetailsAccordionComponent {
  CONTACTS_READ = Action.CONTACTS_READ;

  @Input() supplierUserId: number;
  @Input() catalogId: number;
  @Input() description: string;
  @Input() productDetails: ProductAccordionData;
  @Input() orderInfoData: SupplierOrderInfoData;

  constructor(private orderAndCatalogInformationDialogService: OrderAndCatalogInformationDialogService) {}

  openOrderAndCatalogInformation(): void {
    this.orderAndCatalogInformationDialogService.open(this.catalogId, this.supplierUserId).subscribe();
  }
}

export interface ProductAccordionData {
  ecomCurrency: string;
  category: string;
  brand: string;
  tags: string;
  language: string;
  approval: boolean;
  optionNameFields: [string, any][];
  variants: VariantVO[];
}
