import { googleTagManagerId } from '../../../environments/environment';
import { GtmManagerService } from './gtm-manager.service';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { VirtualPageViewGtmAction } from './actions/virtual_page_view';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Utils } from 'app/utils/utils';

@Injectable()
export class InitGtmService {
  private navigationEnd$: Observable<NavigationEnd>;
  constructor(private gtmManagerService: GtmManagerService, private router: Router, private route: ActivatedRoute) {
    this.navigationEnd$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => event as NavigationEnd)
    );
  }

  private getRouteSnapshot(url: string): ActivatedRouteSnapshot | null {
    let child = this.route.firstChild;
    let concatenatedPath = '';
    while (child) {
      if (!isEmpty(child.snapshot.url)) {
        child.snapshot.url.forEach((segment) => {
          if (segment.path !== '') {
            concatenatedPath = concatenatedPath.concat('/', segment.path);
          }
        });
      }
      if (child.firstChild) {
        child = child.firstChild;
      } else if (
        '/' + child.snapshot.url.map((snapShotUrl) => snapShotUrl.path).join('/') === url ||
        (!!child.snapshot.data &&
          !!child.snapshot.data['cleanRoute'] &&
          '/' + child.snapshot.data['cleanRoute'] === url) ||
        concatenatedPath === url
      ) {
        return child.snapshot;
      } else {
        return null;
      }
    }
    return null;
  }

  private sendPageViewActionOnNavigation(event: NavigationEnd): void {
    const urlWithoutQueryParams = event.urlAfterRedirects.split('?')[0];
    const snapshot = this.getRouteSnapshot(urlWithoutQueryParams);
    const cleanRoute = snapshot?.data['cleanRoute'];
    const pageTitle = snapshot?.data['pageTitle'];
    this.gtmManagerService.pushTag(
      new VirtualPageViewGtmAction({
        cleaned_page_url:
          !!cleanRoute || cleanRoute === ''
            ? `${location.origin}/${cleanRoute}`
            : `${location.origin}${event.urlAfterRedirects}`,
        page_title: pageTitle,
      })
    );
  }

  private sendInitialPageViewEvent(): void {
    this.navigationEnd$.pipe(take(1)).subscribe((event) => {
      this.sendPageViewActionOnNavigation(event);
    });
  }

  public initGtm(): void {
    if (!Utils.isNullOrUndefined(googleTagManagerId) && googleTagManagerId.length !== 0) {
      this.sendInitialPageViewEvent();
      this.gtmManagerService
        .addGtmToDom()
        .pipe(take(1))
        .subscribe((isValid) => {
          if (isValid) {
            this.navigationEnd$.subscribe((event) => {
              this.sendPageViewActionOnNavigation(event);
            });
          }
        });
    }
  }
}
