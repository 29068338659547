import { Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { AsyncPipe, DOCUMENT, NgForOf, NgIf } from '@angular/common';
import { BillingService } from '../../../service/billing/billing.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogsService } from '../dialogs/confirmation-dialog/confirmation-dialog.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { SupplierTaxSettingsService } from '../../../service/supplier-tax-settings/supplier-tax-settings.service';
import { ActivatedRoute } from '@angular/router';
import { CustomIconComponent } from '../custom-icon/custom-icon.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { ConditionLoadingDirective } from '../../directives/condition-loading.directive';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { Observable } from 'rxjs';
import { hasRolesSelector } from '../../../store/user/user.selector';
import { RolesEnum } from '../../../vo/roles/roles';
import { Utils } from 'app/utils/utils';

@Component({
  selector: 'app-login-with-paypal',
  templateUrl: './login-with-paypal.component.html',
  styleUrls: ['./login-with-paypal.component.scss', '../payment-connect/shared-payment.scss'],
  imports: [
    CustomIconComponent,
    MatIconModule,
    TranslateModule,
    FlexModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    NgForOf,
    NgIf,
    MatListModule,
    ConditionLoadingDirective,
    AsyncPipe,
  ],
  standalone: true,
})
export class LoginWithPaypalComponent implements OnInit {
  @Input() redirectUrl = environment.paypal.returnurl;
  @Output() paypalConnected: EventEmitter<boolean> = new EventEmitter<boolean>();

  connected: boolean;
  userPaypalEmail: any;
  loading = true;
  deletedAccounts = [];
  selectedAccount;

  env = environment;
  isAdmin: Observable<boolean>;

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document,
    private billingService: BillingService,
    public dialog: MatDialog,
    private supplierTaxSettingsService: SupplierTaxSettingsService,
    public confirmationDialogsService: ConfirmationDialogsService,
    private activateRoute: ActivatedRoute,
    private _translateService: TranslateService,
    private store: Store<AppState>
  ) {
    this.isAdmin = this.store.select(hasRolesSelector, RolesEnum.ADMIN);
  }

  ngOnInit(): void {
    const code = this.activateRoute.snapshot.queryParams['code'];
    if (Utils.isNullOrUndefined(code)) {
      this.init();
      return;
    }
    this.connectToPaypal(code);
  }

  init(): void {
    this.existsPaypalEmail();
    this.getDeletedPaypalEmails();
  }

  connectToPaypal(code): void {
    const data = { code };
    this.billingService.connectWithPaypal(data).subscribe(
      () => {
        this.init();
      },
      () => {}
    );
  }

  initPaypal(): void {
    const s = this.renderer2.createElement('script');
    s.onload = this.loadNextScript.bind(this);
    s.type = 'text/javascript';
    s.src = 'https://www.paypalobjects.com/js/external/connect/api.js';
    s.text = ``;
    this.renderer2.appendChild(this._document.body, s);
  }

  loadNextScript(): void {
    const jsText = `
    paypal.use( ['login'], function (login) {
        login.render ({
            "appid":"${this.env.paypal.appid}",
            ${this.env.paypal.authend.length !== 0 ? `"authend":"${this.env.paypal.authend}",` : ``}
            "scopes":"email",
            "containerid":"cwppBtn",
            "responseType":"code id_Token",
            "locale": "${this._translateService.currentLang === 'en' ? 'en-US' : 'hu-HU'}",
            "buttonType":"CWP",
            "buttonShape":"pill",
            "buttonSize":"md",
            "fullPage":"true",
            "returnurl":"${this.redirectUrl}"
        });
    });
    `;
    const s2 = this.renderer2.createElement('script');
    s2.type = 'text/javascript';
    // s2.src = 'assets/js/paypal/paypal.js';
    s2.text = jsText;
    this.renderer2.appendChild(this._document.body, s2);
  }

  disconnectPaypal(): any {
    this.billingService.deletePaypalEmail().subscribe(
      () => {
        this.paypalConnected.emit(false);
        this.connected = false;
        this.initPaypal();
        this.getDeletedPaypalEmails();
        this.supplierTaxSettingsService.payPalEmailChange.next('');
      },
      (error) => {
        console.log(error);
      }
    );
  }

  existsPaypalEmail(): any {
    this.billingService.existsPaypalEmail().subscribe(
      (data) => {
        this.connected = !!data;
        if (!this.connected) {
          this.initPaypal();
        } else {
          this.setPaypalConnect(this.connected, data.email);
          this.supplierTaxSettingsService.payPalEmailChange.next(this.userPaypalEmail);
        }
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  getDeletedPaypalEmails(): any {
    this.billingService.getDeletedPaypalEmails().subscribe((response) => {
      this.deletedAccounts = response;
    });
  }

  setDeletedToNullByEmail(): any {
    this.billingService.setDeletedToNullByEmail({ connectId: this.selectedAccount.id }).subscribe(() => {
      this.setPaypalConnect(true, this.selectedAccount.email);
      this.getDeletedPaypalEmails();
    });
  }

  openDialog(): void {
    this.confirmationDialogsService
      .confirm(
        this._translateService.instant('BILLING.SUBSCRIPTION.PAYPAL_PAGES.WARNING'),
        this._translateService.instant('BILLING.SUBSCRIPTION.PAYPAL_PAGES.DISCONNECT_TEXT'),
        this._translateService.instant('BILLING.SUBSCRIPTION.PAYPAL_PAGES.DISCONNECT_BTN'),
        this._translateService.instant('BILLING.SUBSCRIPTION.PAYPAL_PAGES.CANCEL')
      )
      .subscribe((result) => {
        if (result) {
          this.disconnectPaypal();
        }
      });
  }

  setPaypalConnect(status, email = null): void {
    this.connected = status;
    this.userPaypalEmail = email;
    this.paypalConnected.emit(true);
  }
}
