import { GtmAction } from '../gtm-action';
import { GtmEvent } from '../gtm-event';
import { MarketplaceView } from '../../../main/marketplace/explore-products/marketplace-view-selector/marketplace-view-selector.component';
import { GTMFilterItem } from '../../../main/marketplace/marketplace-shared-components/service/gtm-filter.service';

export class FilterAction implements GtmAction {
  event = GtmEvent.filter;

  constructor(public payload: FilterActionPayload) {}
}

export interface FilterActionPayload {
  filter_key: FilterActionKey;
  filter_value: string;
  filter_name: string;
  filter_position: MarketplaceView;
}

export const GtmShippingTypeValues: Record<string, string> = {
  both: 'Wholesale & Dropshipping',
  dropship: 'Dropshipping',
  wholesaling: 'Wholesale',
};

export const GtmPremiumValues: Record<string, GTMFilterItem> = {
  true: { id: 'premium', name: 'Premium Suppliers' },
  false: { id: 'nonPremium', name: 'Non-premium Suppliers' },
};

export const GtmApproveTypeValues: Record<string, GTMFilterItem> = {
  true: { id: 'approveNeeded', name: 'Approval needed' },
  false: { id: 'free', name: 'Free to use' },
};

export const GtmStockValues: Record<string, GTMFilterItem> = {
  any: { id: 'all', name: 'All products' },
  true: { id: 'inStock', name: 'In stock products' },
};

export const GtmAutoOrderValues: Record<string, GTMFilterItem> = {
  any: { id: 'all', name: 'All products' },
  true: { id: 'autoOrder', name: 'Only auto order' },
};

const filterActionKeys = [
  'shipsFrom',
  'shipsTo',
  'category',
  'supplier',
  'lang',
  'stock',
  'shippingType',
  'premium',
  'approveType',
  'autoOrder',
  'minPrice',
  'maxPrice',
  'sku',
] as const;
type FilterActionKeyTuple = typeof filterActionKeys;
export type FilterActionKey = FilterActionKeyTuple[number];
export function isFilterActionKey(value: string): value is FilterActionKey {
  return filterActionKeys.includes(value as FilterActionKey);
}
