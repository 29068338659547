import { Component, Input, OnInit } from '@angular/core';
import { WizardCategoryHeaderService } from '../../../service/wizard-category-header.service';
import { WizardCategoryMappingListService } from '../../../service/wizard-category-mapping-list.service';
import { CategoryMappingData } from '../../../model/category-mapping-data';

@Component({
  selector: 'app-wizard-category-mapping-bulk-actions',
  templateUrl: './wizard-category-mapping-bulk-actions.component.html',
  styleUrls: ['./wizard-category-mapping-bulk-actions.component.scss'],
})
export class WizardCategoryMappingBulkActionsComponent implements OnInit {
  @Input() displayedItems: CategoryMappingData[];
  constructor(
    public headerService: WizardCategoryHeaderService,
    public listService: WizardCategoryMappingListService
  ) {}

  ngOnInit(): void {}

  handleSelectButtonClick(): void {
    if (this.listService.checkedItems.length < 1) {
      this.listService.checkAllItems(this.displayedItems);
    } else {
      this.listService.clearSelection();
    }
  }
}
