<div class="plan-upsell-content">
  <ng-container *ngIf="!!title; else defaultTitle">
    <h3 class="m-0">{{ title }}</h3>
  </ng-container>
  <div class="description centered-text">
    {{ descriptionTranslationKey | translate }}
    <span class="redirect" [innerHTML]="'PLAN_UPSELL.HELP_CENTER' | translate"></span>
  </div>
  <div class="annual-changer mt-12">
    <div
      fxLayoutAlign="center center"
      fxLayoutGap.lt-sm="8px"
      fxLayoutGap="16px"
      fxLayout.lt-sm="column"
      fxLayout="row"
    >
      <div fxLayoutAlign="center center">
        {{ 'PLAN_UPSELL.MONTHLY' | translate }}
        <mat-slide-toggle class="slider" [(ngModel)]="isAnnual"></mat-slide-toggle>
        {{ 'PLAN_UPSELL.ANNUAL' | translate }}
      </div>
      <mat-chip *ngIf="variant === 'marketplace'" class="syncee-blue-chip" (click)="handleAnnualBadgeClicked()">
        {{ 'PLAN_UPSELL.GET_UP' | translate }}
      </mat-chip>
    </div>
    <!--        <div>{{variant === "marketplace" ? ('PLAN_UPSELL.ANNUAL_CHANGER' | translate) : ('Datafeed plan' | translate)}}</div>-->
  </div>
  <div class="upsell-box-grid">
    <ng-container *ngFor="let plan of variant === 'marketplace' ? plansToMarketplaceShow : plansToDataFeedShow">
      <div *ngIf="!isAnnual || (plan.hasAnnualPlan && isAnnual)">
        <app-plan-upsell-box
          [isCurrent]="selectedEcom && selectedEcom.subscriptions && selectedEcom.subscriptions.rmp.planId === plan.id"
          [isTrialValidForEcom]="trialValid"
          [plan]="plan"
          [featured]="isAnnual ? plan.featured.annual : plan.featured.monthly"
          [hasCornerBadge]="isAnnual ? plan.featured.annual : plan.featured.monthly"
          [isAnnual]="isAnnual"
          (buttonClicked)="buttonClicked($event)"
          class="h-100-p"
        ></app-plan-upsell-box>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="hasActionBar">
    <div class="centered-text pt-16">
      <button [class]="buttonClass" mat-button (click)="laterClicked()">
        {{ 'PLAN_UPSELL.CLOSE' | translate }}
      </button>
    </div>
  </ng-container>
</div>

<ng-template #defaultTitle>
  <h3 class="title m-0 mb-12 centered-text">
    {{ 'PLAN_UPSELL.TITLE_DEFAULT' | translate }}
  </h3>
</ng-template>
