import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FilterGroup } from './model/filter-group';
import { GroupConditionChange } from './model/helper/group-condition-change';
import { FilterItemConditionChange } from './model/helper/filter-item-condition-change';
import { FilterStructure } from '../../vo/filter/filter-structure';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NeedAutocomplete } from '../retailer-import-list/tab-items/retailer-import-list-products/model/need-autocomplete';
import { AutocompleteSuggestion } from '../retailer-import-list/tab-items/retailer-import-list-products/model/autocomplete-suggestion';
import { FilterItemChange } from './model/helper/filter-item-change';
import { FilterBaseModifiersService } from './service/filter-base-modifiers.service';
import { FilterItemError } from './model/helper/filter-item-error';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() filter: FilterGroup;
  @Input() filterStructures: FilterStructure[];
  @Input() autocompleteSuggestion: BehaviorSubject<AutocompleteSuggestion>;
  @Input() hasNoMoreAutocomplete: Subject<boolean>;
  @Input() rightActionBar: TemplateRef<any>;
  @Input() filterItemErrors: FilterItemError[];
  @Input() fieldErrorMessages: string[] = [];
  @Input() locked = false;
  @Output() filterChange = new EventEmitter<FilterGroup>();
  @Output() needAutoComplete = new EventEmitter<NeedAutocomplete>();
  @Output() needMoreAutocomplete = new EventEmitter<NeedAutocomplete>();
  @Output() filterItemsErrorRevalidate = new EventEmitter<boolean>();

  constructor(
    private filterBaseModifiersService: FilterBaseModifiersService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  handleAddNewItem(groupId: string): void {
    this.filterChange.emit(this.filterBaseModifiersService.addNewItemToTree(this.filter, groupId));
  }

  handleAddNewGroup(groupId: string): void {
    this.filterChange.emit(this.filterBaseModifiersService.addNewGroupToTree(this.filter, groupId));
  }

  handleDeleteItem(itemId: string): void {
    this.filterChange.emit(this.filterBaseModifiersService.deleteItemFromTree(this.filter, itemId));
  }

  handleDeleteGroup(groupId: string): void {
    this.filterChange.emit(this.filterBaseModifiersService.deleteGroupFromTree(this.filter, groupId));
  }

  handleGroupConditionChange(event: GroupConditionChange): void {
    this.filterChange.emit(this.filterBaseModifiersService.changeGroupCondition(this.filter, event));
  }

  handleItemsConditionChange(event: FilterItemConditionChange): void {
    this.filterChange.emit(this.filterBaseModifiersService.filterItemsAllCondition(this.filter, event));
  }

  handleFilterItemChange(event: FilterItemChange): void {
    this.filterChange.emit(this.filterBaseModifiersService.editFilterItem(this.filter, event));
  }
}
