import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  RetailerDashboardAnalyticsService,
  TopSellingProductsDto,
} from '../../../service/analytics/retailer-dashboard-analytics.service';
import { MatTableDataSource } from '@angular/material/table';
import { SpringPageable } from '../../../vo/pagination/spring-pageable';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { getSelectedEcomByRole } from '../../../store/ecom/ecom.selector';
import { filter, take, takeUntil } from 'rxjs/operators';
import { CurrencyService } from '../../../service/currency-service/currency.service';
import { MARKETPLACE_NAVIGATION } from '../../../navigation/navigation-routes/common/marketplace.navigation';
import { NavigationService } from '../../../service/navigation/navigation.service';
import { RolesEnum } from 'app/vo/roles/roles';

@Component({
  selector: 'app-top-selling-products',
  templateUrl: './top-selling-products.component.html',
  styleUrls: ['./top-selling-products.component.scss'],
})
export class TopSellingProductsComponent implements OnInit, OnChanges {
  @Input()
  dateFrom: Date;
  @Input()
  dateTo: Date;
  dataSource: MatTableDataSource<TopSellingProductsDto>;
  columns = ['product-image', 'title', 'suppiler', 'products-sold', 'profit', 'revenue'];
  pageable: SpringPageable = { page: 0, size: 5, sort: 'profit,desc' };
  ecomCurrency: string;
  readonly marketplaceNavigation = MARKETPLACE_NAVIGATION;

  private unsubscribeAll: Subject<void>;

  constructor(
    private retailerDashboardAnalyticsService: RetailerDashboardAnalyticsService,
    private store: Store<AppState>,
    private navigationService: NavigationService
  ) {
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.getCurrencyFromEcom();
    this.setDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.dateFrom && !changes.dateFrom.firstChange) || (changes.dateTo && !changes.dateTo.firstChange)) {
      this.setDataSource();
    }
  }

  private getCurrencyFromEcom(): void {
    this.store
      .select(getSelectedEcomByRole(RolesEnum.RETAILER))
      .pipe(
        takeUntil(this.unsubscribeAll),
        filter((ecom) => !!ecom)
      )
      .subscribe((selectedEcom) => (this.ecomCurrency = CurrencyService.getCurrency(selectedEcom)));
  }

  setDataSource(): void {
    this.retailerDashboardAnalyticsService
      .getTopSellingProducts(this.dateFrom, this.dateTo, this.pageable)
      .pipe(take(1))
      .subscribe((topSellingProducts) => {
        this.dataSource = new MatTableDataSource<TopSellingProductsDto>(topSellingProducts);
      });
  }

  getProductPath(id: string): string {
    return this.navigationService.getProductPath(id, null);
  }
}
