<ng-container *ngIf="!!filter && !!filterStructures">
  <div fxLayout="column" fxLayoutGap="20px">
    <app-alert-multi
      *ngIf="fieldErrorMessages.length > 0"
      [messages]="fieldErrorMessages"
      [type]="'danger'"
      [readMoreLink]="'FILTER.READ_MORE_LINK' | translate"
    ></app-alert-multi>
    <app-filter-group
      [filterStructures]="filterStructures"
      [group]="filter"
      (newItemAdd)="handleAddNewItem($event)"
      (newGroupAdd)="handleAddNewGroup($event)"
      (deleteItemClicked)="handleDeleteItem($event)"
      (deleteGroupClicked)="handleDeleteGroup($event)"
      (groupConditionChange)="handleGroupConditionChange($event)"
      (filterItemConditionChange)="handleItemsConditionChange($event)"
      (needAutoComplete)="needAutoComplete.emit($event)"
      [autocompleteSuggestion]="autocompleteSuggestion"
      (needMoreAutocomplete)="needMoreAutocomplete.emit($event)"
      [hasNoMoreAutocomplete]="hasNoMoreAutocomplete"
      (filterItemChange)="handleFilterItemChange($event)"
      [rightActionBar]="rightActionBar"
      [filterItemErrors]="filterItemErrors"
      (filterItemsErrorRevalidate)="filterItemsErrorRevalidate.emit()"
      [locked]="locked"
    ></app-filter-group>
  </div>
</ng-container>
