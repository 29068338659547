import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AddStepsToUserData } from 'app/main/getting-started-new/model/add-steps-to-user-data';
import { GettingStartedStepDto } from 'app/main/getting-started-new/model/getting-started-step-dto';
import { SCOPES } from 'app/service/permission/scopes';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { switchMapWith } from 'app/utils/operator/switch-map-with';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import {
  RETAILER_GETTING_STARTED_CONFIG,
  SUPPLIER_GETTING_STARTED_CONFIG,
} from '../../main/getting-started-new/getting-started.config';
import { GettingStartedStepsService } from '../../main/getting-started-new/service/getting-started-steps.service';
import { AuthorizationEcomService } from '../../service/authorization-ecom/authorization-ecom.service';
import { RolesEnum } from '../../vo/roles/roles';
import { getScopesSelector } from '../authentication/authentication.selector';
import { SetActualRole } from '../user/user.actions';
import { getCurrentUserSelector, getUserRolesSelector, hasRoleSelector } from '../user/user.selector';
import {
  GettingStartedActionTypes,
  GettingStartedAllStepsStartAction,
  GettingStartedAllStepsSuccessAction,
  GettingStartedCheckUncompletedAction,
  GettingStartedCompletedStepsStartAction,
  GettingStartedCompletedStepsSuccessAction,
  GettingStartedCompleteStepsForTeamMembersStartAction,
  GettingStartedCompleteStepsForTeamMembersSuccessAction,
  GettingStartedFinishStartAction,
  GettingStartedRedirectAction,
} from './getting-started.actions';
import { gettingStartedCurrentStepSelector, gettingStartedUncompletedStepsSelector } from './getting-started.selector';

@Injectable()
export class GettingStartedEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private gettingStartedStepsService: GettingStartedStepsService,
    private router: Router,
    private authorizationEcomService: AuthorizationEcomService
  ) {}

  startChain: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(GettingStartedActionTypes.GETTING_STARTED_CHAIN_START),
      switchMap(() => [
        new GettingStartedCompletedStepsStartAction(),
        new GettingStartedAllStepsStartAction(),
        new GettingStartedCompleteStepsForTeamMembersStartAction(),
      ])
    )
  );

  completeStepsForTeamMembersStart: Observable<GettingStartedCompleteStepsForTeamMembersSuccessAction> = createEffect(
    () =>
      combineLatest([
        this.actions$.pipe(ofType(GettingStartedActionTypes.GETTING_STARTED_COMPLETED_STEPS_SUCCESS)),
        this.actions$.pipe(ofType(GettingStartedActionTypes.GETTING_STARTED_ALL_STEPS_SUCCESS)),
        this.actions$.pipe(ofType(GettingStartedActionTypes.GETTING_STARTED_COMPLETE_STEPS_FOR_TEAM_MEMBERS_START)),
      ]).pipe(
        take(1),
        switchMap(() => this.getIsTeamMember$()),
        switchMap((isTeamMember) => (!!isTeamMember ? this.setStepsForTeamMember() : of(null))),
        concatMap(() => [new GettingStartedCompletedStepsStartAction()]),
        concatMap(() => [new GettingStartedCompleteStepsForTeamMembersSuccessAction()])
      )
  );

  completeStepsForTeamMembersSuccess: Observable<GettingStartedCheckUncompletedAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(GettingStartedActionTypes.GETTING_STARTED_COMPLETE_STEPS_FOR_TEAM_MEMBERS_SUCCESS),
      switchMap(() => [new GettingStartedCheckUncompletedAction()])
    )
  );

  getGettingStartedCompletedStepsStart: Observable<GettingStartedCompletedStepsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(GettingStartedActionTypes.GETTING_STARTED_COMPLETED_STEPS_START),
      switchMap(() => this.store.select(getCurrentUserSelector).pipe(take(1))),
      switchMap((user) =>
        combineLatest([
          this.gettingStartedStepsService.getStepsForUser(RolesEnum.RETAILER, user.id, 2),
          this.gettingStartedStepsService.getStepsForUser(RolesEnum.SUPPLIER, user.id, 2),
        ]).pipe(catchError(() => of(null)))
      ),
      map(([retailerSteps, supplierSteps]) => this.filterStepsWithConfig([...retailerSteps, ...supplierSteps])),
      distinctUntilChanged(),
      switchMap((steps) => [new GettingStartedCompletedStepsSuccessAction(steps)])
    )
  );

  getGettingStartedAllStepsStart: Observable<GettingStartedAllStepsSuccessAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(GettingStartedActionTypes.GETTING_STARTED_ALL_STEPS_START),
      switchMap(() => this.store.select(getCurrentUserSelector).pipe(take(1))),
      switchMap(() =>
        combineLatest([
          this.gettingStartedStepsService.getAllSteps(RolesEnum.RETAILER, 2),
          this.gettingStartedStepsService.getAllSteps(RolesEnum.SUPPLIER, 2),
        ]).pipe(catchError(() => of(null)))
      ),
      map(([retailerSteps, supplierSteps]) => this.filterStepsWithConfig([...retailerSteps, ...supplierSteps])),
      distinctUntilChanged(),
      switchMap((steps) => [new GettingStartedAllStepsSuccessAction(steps)])
    )
  );

  checkUncompletedSteps: Observable<GettingStartedRedirectAction> = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(GettingStartedActionTypes.GETTING_STARTED_COMPLETED_STEPS_SUCCESS)),
      this.actions$.pipe(ofType(GettingStartedActionTypes.GETTING_STARTED_COMPLETE_STEPS_FOR_TEAM_MEMBERS_SUCCESS)),
      this.actions$.pipe(ofType(GettingStartedActionTypes.GETTING_STARTED_CHECK_UNCOMPLETED_ACTION)),
    ]).pipe(switchMap(() => [new GettingStartedRedirectAction()]))
  );

  gettingStartedFinishStart: Observable<GettingStartedStepDto> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GettingStartedActionTypes.GETTING_STARTED_FINISH_START),
        switchMap((action: GettingStartedFinishStartAction) =>
          this.store.select(gettingStartedCurrentStepSelector, action.payload).pipe(
            take(1),
            tap((currentStep) => {
              if (currentStep.step !== 'FINISHING_UP') {
                if (currentStep.appType === RolesEnum.RETAILER) {
                  this.router.navigate(['/']).then(() => {
                    this.authorizationEcomService.openSubscribeDialog();
                  });
                } else {
                  this.store.dispatch(new SetActualRole(RolesEnum.SUPPLIER));
                  this.router
                    .navigate(['/setup-guide'], {
                      queryParams: {
                        role: 1,
                      },
                    })
                    .then(() => {});
                }
              }
            })
          )
        )
      ),
    { dispatch: false }
  );

  redirectUser: Observable<[GettingStartedStepDto[], [boolean, string]]> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GettingStartedActionTypes.GETTING_STARTED_REDIRECT),
        switchMap(() => this.store.select(hasRoleSelector(RolesEnum.SUPPLIER)).pipe(omitNullOrUndefined(), take(1))),
        map((isSupplier) => (isSupplier ? RolesEnum.SUPPLIER : RolesEnum.RETAILER)),
        switchMap((role) =>
          this.store.select(gettingStartedUncompletedStepsSelector, role).pipe(omitNullOrUndefined(), take(1))
        ),
        switchMapWith((incompleteSteps) =>
          this.getIsTeamMember$().pipe(switchMapWith((isTeamMember) => this.getRedirectPath$(isTeamMember)))
        ),
        tap(([incompleteSteps, [isTeamMember, redirectPath]]) => {
          this.redirectIfNecessary(incompleteSteps, redirectPath, isTeamMember);
        })
      ),
    { dispatch: false }
  );

  private redirectIfNecessary(
    incompleteSteps: GettingStartedStepDto[],
    redirectPath: string,
    isTeamMember: boolean
  ): void {
    const path = document.location.pathname;
    const pathIncludesGS = path.includes('/getting-started');
    if (!!incompleteSteps && incompleteSteps.length > 0 && !pathIncludesGS) {
      this.router.navigate(['/getting-started']);
    } else if (!!incompleteSteps && incompleteSteps.length === 0 && pathIncludesGS) {
      this.router.navigate([redirectPath]);
    } else if (isTeamMember && path === '/') {
      this.router.navigate([redirectPath]);
    }
  }

  private filterStepsWithConfig(steps: GettingStartedStepDto[]): GettingStartedStepDto[] {
    const configStepKeys = RETAILER_GETTING_STARTED_CONFIG.concat(SUPPLIER_GETTING_STARTED_CONFIG).map(
      (step) => step.step
    );
    return steps.filter((step) => configStepKeys.includes(step.step));
  }

  private setStepsForTeamMember(): Observable<void> {
    return this.store.select(getUserRolesSelector).pipe(
      omitNullOrUndefined(),
      take(1),
      map((roles) => (roles.includes(RolesEnum.SUPPLIER) ? RolesEnum.SUPPLIER : RolesEnum.RETAILER)),
      switchMap((role) =>
        this.store.select(gettingStartedUncompletedStepsSelector, role).pipe(
          omitNullOrUndefined(),
          take(1),
          map((steps) =>
            steps.map((step) => {
              return { stepId: step.id, skip: false } as AddStepsToUserData;
            })
          )
        )
      ),
      switchMap((incompleteSteps) =>
        !!incompleteSteps?.length ? this.gettingStartedStepsService.addStepsToUser(incompleteSteps) : of(null)
      )
    );
  }

  private getIsTeamMember$(): Observable<boolean> {
    return this.store.select(getScopesSelector(false)).pipe(
      omitNullOrUndefined(),
      take(1),
      map((scopes) => scopes.includes(SCOPES.TEAM_MEMBER))
    );
  }

  private getRedirectPath$(isTeamMember: boolean): Observable<string> {
    return isTeamMember
      ? this.store.select(hasRoleSelector(RolesEnum.SUPPLIER)).pipe(
          omitNullOrUndefined(),
          take(1),
          map((isSupplier) => (isSupplier ? '/supplier/settings/team' : '/retailer/settings/team'))
        )
      : of('/');
  }
}
