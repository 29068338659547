import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { isEqual } from 'lodash';
import { BehaviorSubject, Observable, Subject, combineLatest, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { EXIST_CONDITIONS, FILTER_VALUES_OFFSETS } from '../../../../utils/Constants';
import { FilterCondition, FilterStructure } from '../../../../vo/filter/filter-structure';
import { AutocompleteSuggestion } from '../../../retailer-import-list/tab-items/retailer-import-list-products/model/autocomplete-suggestion';
import { NeedAutocomplete } from '../../../retailer-import-list/tab-items/retailer-import-list-products/model/need-autocomplete';
import { FilterItem } from '../../model/filter-item';
import { FilterItemError } from '../../model/helper/filter-item-error';

@Component({
  selector: 'app-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss'],
})
export class FilterItemComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('searchTermInput') searchTermInput: ElementRef;
  @Input() filterItemError: FilterItemError;
  @Input() filterItem: FilterItem;
  @Input() autocompleteSuggestion: BehaviorSubject<AutocompleteSuggestion>;
  @Input() hasNoMoreAutocomplete: Subject<boolean>;
  @Input() locked = false;
  @Output() deleteClicked = new EventEmitter<void>();
  @Output() needAutoComplete = new EventEmitter<NeedAutocomplete>();
  @Output() needMoreAutocomplete = new EventEmitter<NeedAutocomplete>();
  @Output() filterItemChange = new EventEmitter<FilterItem>();
  @Output() filterItemsErrorRevalidate = new EventEmitter<boolean>();

  addOnBlur = true;

  get filterStructures(): FilterStructure[] {
    return this._filterStructures;
  }
  @Input() set filterStructures(value: FilterStructure[]) {
    const filteredValue = value.filter((item) => item.valuesLimit !== 0);
    this._filterStructures = filteredValue;
    this.filterStructureChanges.next(filteredValue);
  }
  validLimit: number;
  conditionsForSelectedStructureObservable: Observable<FilterCondition[]>;
  conditionsForSelectedStructure: FilterCondition[];
  selectedStructure: BehaviorSubject<FilterStructure>;
  condition: BehaviorSubject<string>;
  chips: BehaviorSubject<string[]>;
  suggestion: string[] = [];
  hasLoadMore = true;
  searchTerm: string;
  disableInputOfValue = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly existConditions = EXIST_CONDITIONS;
  private _filterStructures: FilterStructure[];
  private readonly filterStructureChanges: BehaviorSubject<FilterStructure[]>;
  private unsubscribeFromAutocomplete: Subject<void>;
  private unsubscribeAll: Subject<void>;
  isAutoListSelected = false;

  constructor() {
    this.unsubscribeAll = new Subject();
    this.unsubscribeFromAutocomplete = new Subject<void>();
    this.filterStructureChanges = new BehaviorSubject<FilterStructure[]>([]);
    this.selectedStructure = new BehaviorSubject<FilterStructure>(null);
    this.condition = new BehaviorSubject<string>(null);
    this.chips = new BehaviorSubject<string[]>([]);
    this.conditionsForSelectedStructureObservable = combineLatest([
      this.filterStructureChanges,
      this.selectedStructure.pipe(filter((selected) => !!selected)),
    ]).pipe(
      map(
        ([structures, selectedStructure]) =>
          structures.find((structure) => structure.key === selectedStructure.key).conditions
      )
    );
  }

  ngOnInit(): void {
    this.conditionsForSelectedStructureObservable
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((conditions) => (this.conditionsForSelectedStructure = conditions));
    combineLatest([this.selectedStructure, this.condition, this.chips])
      .pipe(
        map(([structure, condition, chips]) => this.mapDataToFilterItem(structure, condition, chips)),
        filter((filterItem) => !isEqual(filterItem, this.filterItem)),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe((filterItem) => this.filterItemChange.emit(filterItem));
  }

  ngAfterViewInit(): void {
    if (!!this.searchTermInput) {
      fromEvent(this.searchTermInput.nativeElement, 'keyup')
        .pipe(filter(Boolean), debounceTime(500), distinctUntilChanged())
        .subscribe(() => {
          this.searchTerm = this.searchTermInput.nativeElement.value;
          this.emitNeedAutocomplete();
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.filterItem && !!changes.filterItem.currentValue && !!changes.filterItem.currentValue.field) {
      const selectedStructure = this.filterStructures.find((structure) => structure.key === this.filterItem.field);
      this.selectedStructure.next(selectedStructure);
      this.condition.next(
        selectedStructure.conditions.some((condition) => condition.value === this.filterItem.condition)
          ? this.filterItem.condition
          : null
      );
      this.chips.next(this.filterItem.values);
      if (!!this.filterItem.condition) {
        this.setValidLimit(selectedStructure.key, selectedStructure.valuesLimit);
        this.handleIfValuesReachedLimit(this.filterItem.values.length);
      }
      if (!!this.filterItemError) {
        this.filterItemsErrorRevalidate.emit();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    this.unsubscribeFromAutocomplete.next();
    this.unsubscribeFromAutocomplete.complete();
  }

  private handleIfValuesReachedLimit(valuesLength: number): void {
    this.disableInputOfValue = this.validLimit > 0 ? this.validLimit <= valuesLength : false;
  }

  private setValidLimit(field: string, baseLimit: number): void {
    this.validLimit = baseLimit + this.getLimitOffset(field, this.filterItem.condition);
  }

  private mapDataToFilterItem(structure: FilterStructure, condition: string, values: string[]): FilterItem {
    return {
      id: this.filterItem.id,
      condition,
      values,
      field: structure?.key,
      fieldStructures: [],
      locked: this.locked,
    };
  }

  private subscribeToAutocompleteSuggestion(): void {
    this.autocompleteSuggestion.pipe(takeUntil(this.unsubscribeAll)).subscribe((suggestion) => {
      this.suggestion = suggestion.forKey === this.selectedStructure.value.key ? suggestion.suggestions : [];
    });
  }

  private subscribeToHasNoMoreAutocomplete(): void {
    this.hasNoMoreAutocomplete.pipe(takeUntil(this.unsubscribeAll)).subscribe((res) => (this.hasLoadMore = !res));
  }

  private emitNeedAutocomplete(): void {
    this.needAutoComplete.emit({
      structure: this.selectedStructure.value,
      term: this.searchTerm,
      condition: this.condition.value,
    });
  }

  private getLimitOffset(field: string, condition: string): number {
    const fieldLimits = FILTER_VALUES_OFFSETS.find((limit) => limit.field === field);
    if (!fieldLimits) {
      return 0;
    } else {
      const offset = fieldLimits['conditions'].find((conditionFromLimit) => conditionFromLimit.key === condition);
      return !offset ? 0 : offset.offset;
    }
  }

  handleStructureSelected(key: string): void {
    this.suggestion = [];
    this.hasLoadMore = true;
    this.selectedStructure.next(this.filterStructures.find((structure) => structure.key === key));
  }

  addChip(event: MatChipInputEvent): void {
    setTimeout(() => {
      if (this.isAutoListSelected) {
        this.isAutoListSelected = false;
      } else {
        if (event.value && event.value.length > 0) {
          this.chips.next([...this.chips.value, event.value]);
        }
      }
    }, 500);
  }

  handleLoadMore(): void {
    this.isAutoListSelected = true;
    this.needMoreAutocomplete.emit({
      structure: this.selectedStructure.value,
      term: this.searchTerm,
      condition: this.condition.value,
    });
  }

  selectedAutocomplete(event: MatAutocompleteSelectedEvent): void {
    this.isAutoListSelected = true;
    this.chips.next([...this.chips.value, event.option.value]);
  }

  removedChip(chipToRemove: string): void {
    this.chips.next(this.chips.value.filter((chip) => chip !== chipToRemove));
  }

  handleClosed(): void {
    this.unsubscribeFromAutocomplete.next();
  }

  valueClicked(): void {
    this.subscribeToHasNoMoreAutocomplete();
    this.subscribeToAutocompleteSuggestion();
    this.emitNeedAutocomplete();
  }

  get hasFieldError(): boolean {
    return this.filterItemError?.errors.field.length > 0;
  }

  get hasConditionError(): boolean {
    return this.filterItemError?.errors.condition.length > 0;
  }

  get hasValueError(): boolean {
    return this.filterItemError?.errors.value.length > 0;
  }
}
