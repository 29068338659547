import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileMainMenuSidebarComponent } from './mobile-main-menu-sidebar/mobile-main-menu-sidebar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SynceeLogoComponent } from '../../../shared/components/syncee-logo/syncee-logo.component';
import { FlexModule } from '@angular/flex-layout';
import { FuseNavigationModule, FuseSidebarModule } from '../../../../@fuse/components';
import { TranslateModule } from '@ngx-translate/core';
import { CustomDialogWrapperComponent } from '../../../shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { RoleTranslationPipe } from '../../../shared/pipes/role-translation.pipe';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { MobileMainMenuCategoriesComponent } from './mobile-main-menu-categories/mobile-main-menu-categories.component';
import { MobileMainMenuInnerSidebarComponent } from './mobile-main-menu-inner-sidebar/mobile-main-menu-inner-sidebar.component';
import { OverflowDirective } from 'app/shared/directives/overflow.directive';

@NgModule({
  declarations: [
    MobileMainMenuSidebarComponent,
    MobileMainMenuCategoriesComponent,
    MobileMainMenuInnerSidebarComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    SynceeLogoComponent,
    FlexModule,
    FuseNavigationModule,
    TranslateModule,
    CustomDialogWrapperComponent,
    RoleTranslationPipe,
    FuseSidebarModule,
    FuseDirectivesModule,
    OverflowDirective,
  ],
  exports: [MobileMainMenuSidebarComponent],
})
export class MobileMainMenuModule {}
