import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CountryNode } from '../../../../utils/Countries';
@Component({
  selector: 'app-preference-country-multi',
  templateUrl: './preference-country-multi.component.html',
  styleUrls: ['./preference-country-multi.component.scss'],
})
export class PreferenceCountryMultiComponent implements OnInit {
  @Input() title?: string;
  @Input() subTitle?: string;
  @Input() selectedCountries: CountryNode[];
  @Output() selectedCountriesChange = new EventEmitter<CountryNode[]>();

  constructor() {}

  ngOnInit(): void {}
}
