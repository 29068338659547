import { Injectable } from '@angular/core';
import { CategoryMappingData, CategoryMappingPageable } from '../model/category-mapping-data';
import { BehaviorSubject, Observable } from 'rxjs';
import { WizardCategoryMappingFilter } from './wizard-category-header.service';
import { isEmpty } from 'lodash';
import { filter } from 'rxjs/operators';

@Injectable()
export class WizardCategoryMappingListService {
  customCategoriesTitle: string;
  customCategoriesTooltip: string;
  originalCategoriesTitle: string;
  originalCategoriesTooltip: string;
  private _allItems = new BehaviorSubject<CategoryMappingData[]>(undefined);

  constructor() {}

  public get allItems(): Observable<CategoryMappingData[]> {
    return this._allItems.asObservable().pipe(filter((value) => value !== undefined));
  }

  public keepCheckedValueOnItemsUpdate(updatedMappings: CategoryMappingData[]): CategoryMappingData[] {
    if (!this._allItems.value) {
      return updatedMappings;
    } else {
      return updatedMappings.map((item) => {
        const matchingItem = this._allItems.value.find((entry) => entry.id === item.id);
        return { ...item, checked: matchingItem ? matchingItem.checked : false };
      });
    }
  }

  public setItems(items: CategoryMappingData[]): void {
    this._allItems.next(items);
  }

  public checkAllItems(displayedItems: CategoryMappingData[]): void {
    const mappedItems = displayedItems.map((item) => ({ ...item, checked: true }));
    this.setItems(mappedItems);
  }

  public clearSelection(): void {
    if (!this._allItems.value) {
      return;
    }
    const mappedItems = this._allItems.value.map((item) => ({ ...item, checked: false }));
    this.setItems(mappedItems);
  }

  public get checkedItems(): CategoryMappingData[] {
    return this._allItems.getValue().filter((item) => item.checked);
  }

  public toggleSingleItemChecked(itemId: string, isChecked: boolean): void {
    const mappedItems = this._allItems
      .getValue()
      .map((item) => (item.id === itemId ? { ...item, checked: isChecked } : item));
    this.setItems(mappedItems);
  }

  public filterAndPaginate(
    categories: CategoryMappingData[],
    mapFromSearchTerm: string,
    mappedSearchTerm: string,
    categoryFilter: WizardCategoryMappingFilter,
    page: number,
    size: number
  ): CategoryMappingPageable {
    let allItemsCount = 0;
    let filteredCategories = this.filterByMapFromSearchTerm(categories, mapFromSearchTerm);
    filteredCategories = this.filterByMappedSearchTerm(filteredCategories, mappedSearchTerm);
    filteredCategories = this.filterByCategoryFilter(filteredCategories, categoryFilter);
    allItemsCount = filteredCategories.length;
    filteredCategories = this.getCategoriesForPage(filteredCategories, page, size);
    return { allItemsCount, data: filteredCategories };
  }

  private filterByMapFromSearchTerm(categories: CategoryMappingData[], searchTerm: string): CategoryMappingData[] {
    if (!searchTerm) {
      return categories;
    }
    return categories.filter((cat) => cat.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  private filterByMappedSearchTerm(categories: CategoryMappingData[], searchTerm: string): CategoryMappingData[] {
    if (!searchTerm) {
      return categories;
    }
    return categories.filter((cat) =>
      cat.mappedCategories.some((mappedCat) => mappedCat.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }

  private filterByCategoryFilter(
    categories: CategoryMappingData[],
    categoryFilter: WizardCategoryMappingFilter
  ): CategoryMappingData[] {
    if (categoryFilter === 'not_categorized') {
      return categories.filter((category) => isEmpty(category.mappedCategories));
    } else {
      return categories;
    }
  }

  private getCategoriesForPage(categories: CategoryMappingData[], page: number, size: number): CategoryMappingData[] {
    return categories.slice(page * size, (page + 1) * size);
  }
}
