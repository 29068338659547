import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { SliderItemComponent } from '../slider-item/slider-item.component';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { sum } from 'lodash';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { ObserversModule } from '@angular/cdk/observers';

@Component({
  selector: 'app-slider',
  standalone: true,
  imports: [CommonModule, FlexModule, ObserversModule],
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() pixelToSlide = 200;
  @Input() pixelToSlideResponsive?: PixelToSlideResponsive[];
  @Input() gap = 4;
  @Input() next: Subject<void>;
  @Input() previous: Subject<void>;
  @Output() nextAvailable = new EventEmitter<boolean>();
  @Output() previousAvailable = new EventEmitter<boolean>();
  @ViewChild('container') container: ElementRef<HTMLElement>;
  @ContentChildren(SliderItemComponent) sliderItemList: QueryList<SliderItemComponent>;
  transform = 'unset';
  private offset = 0;
  private unsubscribeAll: Subject<void>;
  private lengthOfChildren: number[];

  constructor(private el: ElementRef<HTMLElement>, private screenManagerService: ScreenManagerService) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.next.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => this.handleNext());
    this.previous.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => this.handlePrevious());
  }

  ngAfterViewInit(): void {
    combineLatest([...this.sliderItemList.toArray().map((item) => item.minWidthChanged)])
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((children) => {
        this.lengthOfChildren = children;
        this.onContentChange();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onContentChange(): void {
    this.previousAvailable.emit(this.isPrevAvailable());
    this.nextAvailable.emit(this.isNextAvailable());
  }

  handleNext(): void {
    this.calculateOffset('next');
    this.setTransform();
  }

  handlePrevious(): void {
    this.calculateOffset('prevoius');
    this.setTransform();
  }

  private setTransform(): void {
    this.transform = `translateX(-${this.offset}px)`;
  }

  private calculateOffset(direction: 'prevoius' | 'next'): void {
    const pixelToSlide = this.generatePixelToSlide();
    const offset = this.offset + (direction === 'next' ? pixelToSlide : -pixelToSlide);
    let childrenWidth = 0;
    const ownWidth = this.el.nativeElement.offsetWidth;
    const allLength = this.getWithOfChildren();
    for (const child of this.lengthOfChildren) {
      childrenWidth += child + this.gap;
      if (childrenWidth - ownWidth - offset > 0) {
        this.handleScroll(allLength, ownWidth, childrenWidth);
        break;
      } else {
        this.handleScrollToEnd(allLength, ownWidth);
      }
    }
  }

  private handleScrollToStart(): void {
    this.offset = 0;
    this.previousAvailable.emit(false);
    this.nextAvailable.emit(true);
  }

  private handleScrollToEnd(allLength: number, ownWidth: number): void {
    this.offset = allLength - ownWidth;
    this.nextAvailable.emit(false);
    this.previousAvailable.emit(true);
  }

  private handleScrollDefault(newOffset: number): void {
    this.offset = newOffset;
    this.previousAvailable.emit(true);
    this.nextAvailable.emit(true);
  }

  private handleScroll(allLength: number, ownWidth: number, childrenWidth: number): void {
    const newOffset = childrenWidth - ownWidth;
    if (newOffset < 0) {
      this.handleScrollToStart();
    } else if (newOffset > allLength - ownWidth) {
      this.handleScrollToEnd(allLength, ownWidth);
    } else {
      this.handleScrollDefault(newOffset);
    }
  }

  private generatePixelToSlide(): number {
    if (this.pixelToSlideResponsive) {
      return this.generatePixelToSlideResponsive();
    } else {
      return this.pixelToSlide;
    }
  }

  private generatePixelToSlideResponsive(): number {
    const foundItem = this.pixelToSlideResponsive.find((item) => item.breakpoint === this.largestValidBreakpoint());
    return foundItem ? foundItem.pixelToSlide : this.pixelToSlide;
  }

  private getWithOfChildren(): number {
    return sum(this.lengthOfChildren) + (this.lengthOfChildren.length - 1) * this.gap;
  }

  private isNextAvailable(): boolean {
    return this.container.nativeElement.scrollWidth > this.el.nativeElement.offsetWidth + -1 * this.offset;
  }

  private isPrevAvailable(): boolean {
    return this.offset !== 0;
  }

  private largestValidBreakpoint(): BreakPoint {
    for (const key of Object.keys(BreakPoint).reverse()) {
      if (!this.screenManagerService.checkBreakpoint(BreakPoint[key as keyof BreakPoint])) {
        return BreakPoint[key as keyof BreakPoint];
      }
    }
  }
}

export interface PixelToSlideResponsive {
  breakpoint: BreakPoint;
  pixelToSlide: number;
}
