import { Injectable } from '@angular/core';
import { MarketplaceFilter } from '../../../../vo/search-product-vo';
import { GTMFilterService } from './gtm-filter.service';
import { languages } from '../../../../utils/Languages';
import { SupplierGatewayService } from '../../../../service/suppliers/supplier-gateway.service';
import { isEmpty } from 'lodash';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import { flattenCategoriesSelector } from '../../../../store/category/category.selector';
import { CountriesManagerService } from '../../../../service/countries-manager/countries-manager.service';
import { FilterSelectorGtmService } from './filter-selector-gtm-service';
import { SelectedSupplier } from '../../../filter-common/filter-items/supplier-filter/supplier-filter.component';
import { CountryNode } from '../../../../utils/Countries';

@Injectable({ providedIn: 'root' })
export class InitGtmFiltersService {
  constructor(
    private gtmFilterService: GTMFilterService,
    private supplierGatewayService: SupplierGatewayService,
    private store: Store<AppState>,
    private countriesManager: CountriesManagerService,
    private filterSelectorGtmService: FilterSelectorGtmService
  ) {}

  initFilterGtm(storedFilter: MarketplaceFilter): void {
    if (!!storedFilter.shipsFrom) {
      this.initShipsFrom(storedFilter.shipsFrom as string[]);
    }
    if (!!storedFilter.shipsTo) {
      this.initShipsTo(storedFilter.shipsTo as string[]);
    }
    if (!!storedFilter.supplier) {
      this.initSupplier(storedFilter.supplier as string[]);
    }
    if (!!storedFilter.category && storedFilter.category !== 1) {
      this.initCategory(storedFilter.category);
    }

    if (!!storedFilter.lang) {
      this.initLanguage(storedFilter.lang);
    }
    if (!!storedFilter.stock) {
      this.filterSelectorGtmService.setFilter('stock', storedFilter.stock);
    }
    if (!!storedFilter.shippingType && storedFilter.shippingType !== 'any') {
      this.filterSelectorGtmService.setFilter('shippingType', storedFilter.shippingType as string);
    }
    if (!!storedFilter.premium && storedFilter.premium !== 'any') {
      this.filterSelectorGtmService.setFilter('premium', storedFilter.premium);
    }
    if (!!storedFilter.approveType && storedFilter.approveType !== 'any') {
      this.filterSelectorGtmService.setFilter('approveType', storedFilter.approveType);
    }
    if (!!storedFilter.autoOrder) {
      this.filterSelectorGtmService.setFilter('autoOrder', storedFilter.autoOrder);
    }
    if (!!storedFilter.maxPrice) {
      this.filterSelectorGtmService.setFilter('maxPrice', storedFilter.maxPrice);
    }
    if (!!storedFilter.minPrice) {
      this.filterSelectorGtmService.setFilter('minPrice', storedFilter.minPrice);
    }
  }
  private initSupplier(supplierIdList: string[]): void {
    if (!isEmpty(supplierIdList)) {
      const idNumberList = supplierIdList.map((id) => Number(id));
      this.supplierGatewayService
        .getSuppliers(idNumberList)
        .pipe(take(1))
        .subscribe((suppliers) => {
          const mappedSuppliers: SelectedSupplier[] = suppliers.map((supplier) => ({
            id: supplier.userId,
            name: supplier.companyName,
          }));
          this.filterSelectorGtmService.setFilter('supplier', mappedSuppliers);
        });
    }
  }
  private initLanguage(languageCode: string): void {
    const language = languages.find((lang) => lang.id === languageCode);
    this.gtmFilterService.sendFilter('lang', { id: language.id, name: language.name });
  }

  private initCategory(categoryId: number): void {
    this.store
      .select(flattenCategoriesSelector)
      .pipe(filter((value) => !isEmpty(value)))
      .subscribe((categories) => {
        const category = categories.find((cat) => cat.id === categoryId);
        this.filterSelectorGtmService.setFilter('category', {
          id: String(categoryId),
          name: category.name,
        });
      });
  }

  private initShipsTo(countryList: string[]): void {
    const mappedCountries: CountryNode[] = countryList.map((countryName) =>
      this.countriesManager.getCountryOrContinentByName(countryName)
    );
    this.filterSelectorGtmService.setFilter('shipsTo', mappedCountries);
  }

  private initShipsFrom(countryList: string[]): void {
    const mappedCountries: CountryNode[] = countryList.map((countryName) =>
      this.countriesManager.getCountryOrContinentByName(countryName)
    );
    this.filterSelectorGtmService.setFilter('shipsFrom', mappedCountries);
  }
}
