import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'app/utils/utils';
import { combineLatest, mergeMap, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { AppState } from '../../../../app.state';
import { NavigationService } from '../../../../service/navigation/navigation.service';
import { PreferenceStoreService } from '../../../../service/preference/preference.store.service';
import { CategoryService, TopSuppliersForCategory } from '../../../../service/product-search/category.service';
import { BreakPoint, ScreenManagerService } from '../../../../service/screen-manager/screen-manager.service';
import { SupplierGatewayService } from '../../../../service/suppliers/supplier-gateway.service';
import { AddTopSuppliersToCategory } from '../../../../store/category/category.action';
import { SetHelperAction } from '../../../../store/product-search/product-search.action';
import { omitNullOrUndefined } from '../../../../utils/operator/omit-null-or-undefined';
import { CategoryVo } from '../../../../vo/category-vo';
import { MarketplaceFilter, MarketplaceFilterSort } from '../../../../vo/search-product-vo';
import { SupplierDto } from '../../../../vo/supplier/supplier-dto';

@Component({
  selector: 'app-category-selector-popup-content',
  templateUrl: './category-selector-popup-content.component.html',
  styleUrls: ['./category-selector-popup-content.component.scss'],
})
export class CategorySelectorPopupContentComponent implements OnInit, OnDestroy {
  @Output() filterSelected = new EventEmitter<MarketplaceFilter>();
  private _mainCategory: CategoryVo;
  get mainCategory(): CategoryVo {
    return this._mainCategory;
  }
  @Input()
  set mainCategory(value: CategoryVo) {
    this._mainCategory = value;
    this.mainCategory$.next(value);
  }
  topSuppliersForCategory: SupplierDto[];
  unsubscribeAll: Subject<void>;
  topSuppliers$: Observable<TopSuppliersForCategory>;
  parentCategory: CategoryVo;
  isOnMobile: boolean;
  selectedSort: MarketplaceFilterSort;
  loading: boolean;

  private _selectedInnerCategory: CategoryVo;
  private mainCategory$ = new ReplaySubject<CategoryVo>(1);
  public selectedFilterCategoryId: number;
  private productSearchStore$: Observable<MarketplaceFilter>;
  private categoriesStore$: Observable<CategoryVo>;
  private currentLanguage: string;

  constructor(
    private store: Store<AppState>,
    private categoryService: CategoryService,
    private translateService: TranslateService,
    private screenManager: ScreenManagerService,
    private navigationService: NavigationService,
    private supplierGatewayService: SupplierGatewayService,
    private preferenceStoreService: PreferenceStoreService
  ) {
    this.unsubscribeAll = new Subject();
    this.topSuppliers$ = this.store.pipe(
      select((state) => state.categories),
      select((state) => state.topSuppliers)
    );
    this.productSearchStore$ = this.store.pipe(
      select((state) => state.productSearch),
      select((state) => state.filter)
    );
    this.categoriesStore$ = this.store.pipe(
      select((state) => state.categories),
      select((state) => state.categories)
    );
    this.currentLanguage = this.translateService.currentLang;
  }

  ngOnInit(): void {
    this.handleTopSuppliers();
    this.subscribeToProductSearchState();
    this.checkMobileSize();
    this.getTopSuppliers().subscribe((suppliers) => {
      this.topSuppliersForCategory = suppliers;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  handleSupplierClicked(supplier: SupplierDto): void {
    this.navigationService.navigateToStorefront(supplier.userId, supplier.handle);
  }

  handleBackToButtonClicked(): void {
    if (this.parentCategory.id === this._mainCategory.id) {
      this._selectedInnerCategory = null;
      this.parentCategory = null;
    } else {
      this._selectedInnerCategory = this.parentCategory;
      this.parentCategory = this.categoryService.searchInCategories(
        this.getCategories(),
        Number(this.parentCategory.parent)
      );
    }
  }

  handleCategoryClicked(category: CategoryVo): void {
    if (!Utils.isNullOrUndefined(category.children) && category.children.length > 0) {
      this.handleCategoryWithChildrenClicked(category);
    } else {
      this.handleCategoryWithoutChildrenClicked(category);
    }
  }

  handleViewAllClicked(): void {
    this.handleCategoryWithoutChildrenClicked(this.selectedInnerCategory);
  }

  private checkMobileSize(): void {
    this.screenManager
      .observeBreakpoint(BreakPoint.lg)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((breakPointState) => {
        this.isOnMobile = breakPointState.matches;
      });
  }

  private handleCategoryWithoutChildrenClicked(category: CategoryVo): void {
    if (this.selectedFilterCategoryId !== Number(category.id)) {
      this.store.dispatch(new SetHelperAction({ category: category }));
      this.filterSelected.emit({
        category: Number(category.id),
      });
    }
  }

  private handleCategoryWithChildrenClicked(category: CategoryVo): void {
    this.parentCategory = this._selectedInnerCategory || this._mainCategory;
    this._selectedInnerCategory = category;
  }

  private getTopSuppliers(): Observable<SupplierDto[]> {
    return combineLatest({
      allTop: this.topSuppliers$,
      mainCategory: this.mainCategory$,
    }).pipe(
      takeUntil(this.unsubscribeAll),
      tap(
        ({ allTop, mainCategory }) =>
          (this.loading = !allTop.hasOwnProperty(mainCategory?.id) || !allTop[mainCategory?.id])
      ),
      map(({ allTop, mainCategory }) => allTop[mainCategory?.id]),
      omitNullOrUndefined()
    );
  }

  private handleTopSuppliers(): void {
    combineLatest({
      location: this.preferenceStoreService.getPreferredLocation().pipe(omitNullOrUndefined()),
      allTop: this.topSuppliers$,
      mainCategory: this.mainCategory$.pipe(distinctUntilChanged((previous, current) => previous.id === current.id)),
    })
      .pipe(
        takeUntil(this.unsubscribeAll),
        filter(({ allTop, mainCategory }) => !allTop.hasOwnProperty(mainCategory?.id)),
        tap(({ mainCategory }) =>
          this.store.dispatch(new AddTopSuppliersToCategory({ categoryId: mainCategory.id, suppliers: undefined }))
        ),
        mergeMap(({ location, mainCategory }) =>
          this.supplierGatewayService
            .findByCountryAndCategory(location.code, mainCategory.id)
            .pipe(map((suppliersPage) => ({ supplier: suppliersPage.content, categoryId: mainCategory.id })))
        )
      )
      .subscribe((data) =>
        this.store.dispatch(new AddTopSuppliersToCategory({ categoryId: data.categoryId, suppliers: data.supplier }))
      );
  }

  private subscribeToProductSearchState(): void {
    this.productSearchStore$.pipe(takeUntil(this.unsubscribeAll)).subscribe((filters: MarketplaceFilter) => {
      this.selectedFilterCategoryId =
        !Utils.isNullOrUndefined(filters) && !Utils.isNullOrUndefined(filters.category) ? filters.category : null;
      this.setSelectedSort(filters);
    });
  }

  private setSelectedSort(filters: MarketplaceFilter): void {
    if (filters) {
      this.selectedSort = {
        sortOrder: filters.sortOrder,
        sortField: filters.sortField,
      };
    }
  }

  private getCategories(): CategoryVo {
    let categoryTree: CategoryVo;
    this.categoriesStore$.pipe(take(1)).subscribe((categories) => {
      categoryTree = categories;
    });
    return categoryTree;
  }

  get selectedInnerCategory(): CategoryVo {
    return this._selectedInnerCategory;
  }

  protected readonly Array = Array;
}
