import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PriceType } from '../enums/price-type.enum';

@Component({
  selector: 'app-supplier-task-pricing-type-settings',
  templateUrl: './supplier-task-pricing-type-settings.component.html',
  styleUrls: ['./supplier-task-pricing-type-settings.component.scss'],
})
export class SupplierTaskPricingTypeSettingsComponent implements OnInit {
  OPTION_PRICE = PriceType.PRICE;
  OPTION_RETAIL_PRICE = PriceType.RETAIL_PRICE;

  @Input() isDatafeed: boolean;
  @Input() selected: PriceType;
  @Input() locked: boolean;
  @Output() selectedChange = new EventEmitter<PriceType>();

  constructor() {}

  ngOnInit(): void {}

  onChange(value: PriceType): void {
    this.selectedChange.emit(value);
  }
}
