<div class="slider" (swiperight)="handlePrevious()" (swipeleft)="handleNext()">
  <div
    #container
    fxLayoutAlign="start center"
    [fxLayoutGap]="gap + 'px'"
    class="slider-list"
    [style.transform]="transform"
    (cdkObserveContent)="onContentChange()"
  >
    <ng-content></ng-content>
  </div>
</div>
