<ng-container *ngIf="!isMobile">
  <ng-container *ngIf="role$ | async as role">
    <ng-container *ngIf="hasPermission$ | async; else upgradeplancontainer">
      <div
        [style.height.px]="layoutService.contentHeight | async"
        fxLayout="column"
        fxFlex="1 0 0"
        [class.with-title]="!this.options.hideDirectMessageSide"
      >
        <ng-container *ngIf="currUser | async as currentUser">
          <app-chat
            fxLayout="column"
            fxFlex="1 0 0"
            [currUser]="currentUser"
            [options]="options"
            [role]="role"
          ></app-chat>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #upgradeplancontainer>
  <div class="chat-plan-container">
    <app-subscription-upsell
      (laterEvent)="upgradePlanLaterHandle()"
      [descriptionTranslationKey]="'PLAN_UPSELL.SPECIAL_DESCRIPTION.MESSENGER'"
    ></app-subscription-upsell>
  </div>
</ng-template>

<ng-template #upgradePlanMobile>
  <app-custom-dialog-wrapper [closeWithRedirect]="true">
    <ng-container [ngTemplateOutlet]="upgradeplancontainer"></ng-container>
  </app-custom-dialog-wrapper>
</ng-template>

<ng-template #mobileView>
  <ng-container *ngIf="role$ | async as role">
    <ng-container *ngIf="hasPermission$ | async; else upgradePlanMobile">
      <app-custom-dialog-wrapper [hideCloseBtn]="true" class="custom-modal-container">
        <ng-container *ngIf="currUser | async as currentUser">
          <app-chat
            fxLayout="column"
            fxFlex="1 0 0"
            [currUser]="currentUser"
            [options]="options"
            [role]="role"
          ></app-chat>
        </ng-container>
      </app-custom-dialog-wrapper>
    </ng-container>
  </ng-container>
</ng-template>
