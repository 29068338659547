<section>
  <app-header-1
    [title]="'EXPLORE_PRODUCTS.GUEST.BANNER.TITLE' | translate"
    [description]="'EXPLORE_PRODUCTS.GUEST.BANNER.DESCRIPTION' | translate"
    [upperText]="'EXPLORE_PRODUCTS.GUEST.BANNER.UPPER_MESSAGE' | translate"
    [innerClasses]="['content-row-padding-lt-xl', 'content-row-max-width']"
    [backgroundImage]="{
      src: 'images/static-pages/homepage-guest/retailer-home-banner-bg.webp' | getAssetFromStorage,
      alt: 'Find products to sell from trusted brands'
    }"
    [height]="750"
    [mobileHeight]="488"
  >
    <div fxLayoutAlign="center center">
      <app-select-button
        [items]="bannerSelectButtonItems"
        [selectedItem]="2"
        (selectedItemChange)="handleBannerChanged()"
      ></app-select-button>
    </div>
  </app-header-1>
</section>
<section>
  <app-left-and-right-section-with-general-button
    innerClass="content-row-padding-lt-xl content-row-max-width"
    position="left"
    [wrapperClass]="'custom-syncee-silver-bg ' + (ltMd ? 'pt-40' : 'py-100')"
    [title]="'EXPLORE_PRODUCTS.GUEST.SELL_MORE_TOGETHER.TITLE' | translate"
    [description]="'EXPLORE_PRODUCTS.GUEST.SELL_MORE_TOGETHER.DESCRIPTION' | translate"
    [descriptionSmall]="'EXPLORE_PRODUCTS.GUEST.SELL_MORE_TOGETHER.DESCRIPTION_SMALL' | translate"
    [chipText]="'EXPLORE_PRODUCTS.GUEST.SELL_MORE_TOGETHER.CHIP' | translate"
    [button]="blueButton"
  >
    <app-video-dialog-thumbnail
      [thumbnail]="{
        src: 'images/static-pages/homepage-guest/retailer-video-banner.webp' | getAssetFromStorage,
        alt: 'Sell more, together by revolutionized dropshipping',
        height: 423,
        width: 673
      }"
    >
      <app-youtube-open-video-dialog url="https://www.youtube.com/embed/Vm3fhp5Jjy4?cc_load_policy=1&autoplay=1">
        <img
          [src]="'images/static-pages/homepage-guest/retailer-play-button.svg' | getAssetFromStorage"
          alt="Play video"
        />
      </app-youtube-open-video-dialog>
    </app-video-dialog-thumbnail>
  </app-left-and-right-section-with-general-button>
</section>
<section>
  <app-left-and-right-section-with-general-button
    innerClass="content-row-padding-lt-xl content-row-max-width"
    [position]="ltMd ? 'left' : 'right'"
    [wrapperClass]="'custom-syncee-silver-bg ' + (ltMd ? 'py-40' : 'py-100')"
    [title]="'EXPLORE_PRODUCTS.GUEST.YOU_FOCUS_ON_SALES.TITLE' | translate"
    [description]="'EXPLORE_PRODUCTS.GUEST.YOU_FOCUS_ON_SALES.DESCRIPTION' | translate"
    [chipText]="'EXPLORE_PRODUCTS.GUEST.YOU_FOCUS_ON_SALES.CHIP' | translate"
    [button]="arrowButton"
  >
    <img
      alt="You focus on sales, they handle shipping"
      [ngSrc]="'images/static-pages/homepage-guest/retailer-home-row-01.webp' | getAssetFromStorage"
      [height]="688"
      [width]="508"
    />
  </app-left-and-right-section-with-general-button>
</section>
<section>
  <app-left-and-right-section-with-general-button
    innerClass="content-row-padding-lt-xl content-row-max-width"
    position="left"
    [wrapperClass]="'custom-syncee-silver-bg ' + (ltMd ? 'py-40' : 'py-100')"
    [title]="'EXPLORE_PRODUCTS.GUEST.TRY_FIRST_BUY_LATER.TITLE' | translate"
    [description]="'EXPLORE_PRODUCTS.GUEST.TRY_FIRST_BUY_LATER.DESCRIPTION' | translate"
    [chipText]="'EXPLORE_PRODUCTS.GUEST.TRY_FIRST_BUY_LATER.CHIP' | translate"
    [button]="arrowButton"
  >
    <img
      alt="Try first, buy later"
      [ngSrc]="'images/static-pages/homepage-guest/retailer-home-row-02.webp' | getAssetFromStorage"
      [height]="688"
      [width]="508"
    />
  </app-left-and-right-section-with-general-button>
</section>
<section>
  <app-stepflow-row
    titleKey="EXPLORE_PRODUCTS.GUEST.STEPFLOW.TITLE"
    descriptionKey="EXPLORE_PRODUCTS.GUEST.STEPFLOW.DESCRIPTION"
    innerWrapperClass="w-100-p"
    [wrapperClass]="'content-row-padding-lt-xl content-row-max-width stepflow ' + (ltMd ? 'py-40' : 'py-100')"
    [steps]="stepflowSteps"
  >
  </app-stepflow-row>
</section>
<section>
  <app-left-and-right-section-with-general-button
    innerClass="content-row-padding-lt-xl content-row-max-width"
    position="right"
    [wrapperClass]="'custom-syncee-silver-bg ' + (ltMd ? 'py-40' : 'py-100')"
    [title]="'EXPLORE_PRODUCTS.GUEST.MAXIMIZE_PROFIT_WITH_FULLY_AUTOMATED_TOOLS.TITLE' | translate"
    [description]="'EXPLORE_PRODUCTS.GUEST.MAXIMIZE_PROFIT_WITH_FULLY_AUTOMATED_TOOLS.DESCRIPTION' | translate"
    [chipText]="'EXPLORE_PRODUCTS.GUEST.MAXIMIZE_PROFIT_WITH_FULLY_AUTOMATED_TOOLS.CHIP' | translate"
    [button]="arrowButton"
  >
    <img
      alt="Try first, buy later"
      [ngSrc]="'images/static-pages/homepage-guest/retailer-home-row-03.webp' | getAssetFromStorage"
      [height]="688"
      [width]="508"
    />
  </app-left-and-right-section-with-general-button>
</section>
<!--
<section>
  <app-left-and-right-section-with-listing-double-border
    innerClass="content-row-padding-lt-xl content-row-max-width"
    [position]="ltMd ? 'left' : 'right'"
    [wrapperClass]="'custom-syncee-silver-bg ' + (ltMd ? 'py-40' : 'py-100')"
    [title]="'EXPLORE_PRODUCTS.GUEST.SOURCE_FROM_LOCAL_SUPPLIERS.TITLE' | translate"
    [description]="
      'EXPLORE_PRODUCTS.GUEST.SOURCE_FROM_LOCAL_SUPPLIERS.DESCRIPTION'
        | translate : { brands: BRAND_NUMBER.FULL_STRING }
    "
    [list]="[
      'EXPLORE_PRODUCTS.GUEST.SOURCE_FROM_LOCAL_SUPPLIERS.LIST.1' | translate,
      'EXPLORE_PRODUCTS.GUEST.SOURCE_FROM_LOCAL_SUPPLIERS.LIST.2' | translate,
      'EXPLORE_PRODUCTS.GUEST.SOURCE_FROM_LOCAL_SUPPLIERS.LIST.3' | translate,
      'EXPLORE_PRODUCTS.GUEST.SOURCE_FROM_LOCAL_SUPPLIERS.LIST.4' | translate,
      'EXPLORE_PRODUCTS.GUEST.SOURCE_FROM_LOCAL_SUPPLIERS.LIST.5' | translate
    ]"
  >
    <img
      alt="Source from local suppliers and sell more"
      [ngSrc]="'images/static-pages/homepage-guest/retailer-home-row-03.webp' | getAssetFromStorage"
      [height]="688"
      [width]="508"
    />
  </app-left-and-right-section-with-listing-double-border>
</section>
-->
<section class="content-row-padding-lt-xl custom-syncee-silver-bg" [ngClass]="ltMd ? 'py-40' : 'py-100'">
  <h2 class="m-0 pb-28 centered-text" [class.h3]="ltMd">
    {{ 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS.TITLE' | translate }}
  </h2>
  <section class="content-row-max-width">
    <app-feature-cards-row [cardContent]="HOMEPAGE_GUEST_FEATURE_CARDS"></app-feature-cards-row>
  </section>
</section>
<section>
  <app-widget-cards-by-widget-type
    innerClass="content-row-padding-lt-xl content-row-max-width"
    [wrapperClass]="ltMd ? 'py-40' : 'py-64'"
    [title]="'EXPLORE_PRODUCTS.EXPLORE_BY_CATEGORY' | translate"
    [widgetType]="WIDGET_TYPE.GUEST_CATEGORY_TILE"
    [columns]="ltMd ? 2 : 5"
    (cardClicked)="handleWidgetSelected($event)"
  ></app-widget-cards-by-widget-type>
</section>
<section>
  <app-feature-cards-with-square-icon
    innerClass="content-row-padding-lt-xl content-row-max-width"
    [wrapperClass]="ltMd ? 'py-40' : 'py-128'"
    [columns]="ltMd ? 1 : 3"
    [title]="'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.TITLE' | translate"
    [description]="'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_SQUARE_ICON.DESCRIPTION' | translate"
    [items]="HOMEPAGE_GUEST_FEATURE_CARDS_WITH_SQUARE_ICON"
  ></app-feature-cards-with-square-icon>
</section>
<section>
  <app-suppliers-slider-by-filter
    innerClass="content-row-padding-lt-xl content-row-max-width"
    [filter]="{}"
    [title]="'EXPLORE_PRODUCTS.GUEST.TRENDING.TITLE' | translate"
  ></app-suppliers-slider-by-filter>
</section>
<section>
  <app-left-and-right-section-with-general-button
    innerClass="content-row-padding-lt-xl content-row-max-width"
    position="left"
    [wrapperClass]="'custom-syncee-silver-bg ' + (ltMd ? 'py-40' : 'py-100')"
    [title]="'EXPLORE_PRODUCTS.GUEST.HAVENT_FOUND_YOUR_SUPPLIER.TITLE' | translate"
    [description]="'EXPLORE_PRODUCTS.GUEST.HAVENT_FOUND_YOUR_SUPPLIER.DESCRIPTION' | translate"
    [chipText]="'EXPLORE_PRODUCTS.GUEST.HAVENT_FOUND_YOUR_SUPPLIER.CHIP' | translate"
    [button]="arrowButton"
  >
    <img
      alt="Haven't found your supplier? Invite them!"
      [ngSrc]="'images/static-pages/homepage-guest/retailer-home-row-04.webp' | getAssetFromStorage"
      [height]="688"
      [width]="508"
    />
  </app-left-and-right-section-with-general-button>
</section>
<section>
  <app-syncee-cta
    [title]="'EXPLORE_PRODUCTS.GUEST.CTA.TITLE' | translate"
    [descriptionInnerHTML]="'EXPLORE_PRODUCTS.GUEST.CTA.DESCRIPTION' | translate"
    [buttonText]="'EXPLORE_PRODUCTS.GUEST.CTA.BUTTON' | translate"
    [callback]="startClickedRetailer.bind(this)"
  ></app-syncee-cta>
</section>
<section>
  <app-article-cards-row
    innerClass="content-row-padding-lt-xl content-row-max-width"
    [wrapperClass]="'custom-syncee-silver-bg ' + (ltMd ? 'py-40' : 'py-80')"
    [title]="'EXPLORE_PRODUCTS.GUEST.ARTICLE_CARDS.TITLE' | translate"
    [cards]="articleCardsWithCallback"
  ></app-article-cards-row>
</section>
<section>
  <app-integrations-card-grid
    innerWrapperClass="content-row-padding-lt-xl content-row-max-width"
    [wrapperClass]="ltMd ? 'py-40' : 'py-100'"
    [titleKey]="'EXPLORE_PRODUCTS.GUEST.INTEGRATIONS.TITLE'"
    [descriptionKey]="'EXPLORE_PRODUCTS.GUEST.INTEGRATIONS.DESCRIPTION'"
    [cardContent]="INTEGRATION_CARDS"
  >
  </app-integrations-card-grid>
</section>
<section [ngClass]="ltMd ? 'py-40' : 'py-64'">
  <app-reviews-slider
    [titleKey]="'EXPLORE_PRODUCTS.GUEST.REVIEWS.TITLE'"
    [reviewCardsContent]="SYNCEE_STATIC_REVIEWS"
    [wrapperClass]="'content-row-padding-lt-xl content-row-max-width'"
  ></app-reviews-slider>
</section>
<section class="content-row-padding-lt-xl content-row-max-width" [ngClass]="ltMd ? 'py-40' : 'py-80'">
  <h2 class="m-0 pb-8 centered-text" [class.h3]="ltMd">
    {{ 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_ACTION.TITLE' | translate }}
  </h2>
  <div class="m-0 pb-28 centered-text typography-h2-subtitle" [class.typography-h3-subtitle]="ltMd">
    {{ 'EXPLORE_PRODUCTS.GUEST.FEATURE_CARDS_WITH_ACTION.DESCRIPTION' | translate }}
  </div>
  <app-feature-cards-row [cardContent]="HOMEPAGE_GUEST_FEATURE_CARDS_WITH_ACTION"></app-feature-cards-row>
</section>
<section>
  <app-faq-columns
    *ngIf="!!faq"
    translationCallback
    innerWrapperClass="content-row-padding-lt-xl content-row-max-width"
    [callbackClasses]="['retailer-registration', 'supplier-registration', 'supplier-referral']"
    [questionsLeft]="faq.items.left"
    [questionsRight]="faq.items.right"
    [titleKey]="faq.titleKey"
    [translateValue]="false"
    (onClassClick)="handleFAQClassClick($event)"
  ></app-faq-columns>
</section>
<section>
  <app-brands-slider-by-location
    innerClass="content-row-padding-lt-xl content-row-max-width"
    [title]="'EXPLORE_PRODUCTS.GUEST.SUPPLIER_LOGOS.TITLE' | translate"
  ></app-brands-slider-by-location>
</section>
<section>
  <app-syncee-cta-two-columns
    innerWrapperClass="content-row-padding-lt-xl content-row-max-width"
    [title]="'EXPLORE_PRODUCTS.GUEST.CTA_TWO_COLUMNS.TITLE' | translate"
    [subtitleLeft]="'EXPLORE_PRODUCTS.GUEST.CTA_TWO_COLUMNS.COLUMNS.LEFT.SUBTITLE' | translate"
    [descriptionLeft]="
      'EXPLORE_PRODUCTS.GUEST.CTA_TWO_COLUMNS.COLUMNS.LEFT.DESCRIPTION'
        | translate : { brands: BRAND_NUMBER.FULL_STRING }
    "
    [buttonLeft]="whiteRetailer"
    [subtitleRight]="'EXPLORE_PRODUCTS.GUEST.CTA_TWO_COLUMNS.COLUMNS.RIGHT.SUBTITLE' | translate"
    [descriptionRight]="
      'EXPLORE_PRODUCTS.GUEST.CTA_TWO_COLUMNS.COLUMNS.RIGHT.DESCRIPTION'
        | translate : { retailers: userNumber.DROPSHIPPER_FULL_STRING }
    "
    [buttonRight]="whiteSupplier"
  ></app-syncee-cta-two-columns>
</section>
