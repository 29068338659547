<div class="image-selector-container">
  <div
    [class.hidden]="prevDisabled || images?.length < 2"
    (click)="slickModal.slickPrev()"
    fxLayoutAlign="center center"
    class="navigation-button left-arrow custom-elevation-z1"
  >
    <mat-icon>arrow_back_ios</mat-icon>
  </div>
  <div
    [class.hidden]="nextDisabled || images?.length < 2"
    (click)="slickModal.slickNext()"
    fxLayoutAlign="center center"
    class="navigation-button right-arrow custom-elevation-z1"
  >
    <mat-icon>arrow_forward_ios</mat-icon>
  </div>
  <ngx-slick-carousel #slickModal="slick-carousel" [config]="config" (beforeChange)="handleImageChanged($event)">
    <div ngxSlickItem *ngFor="let image of images || []; let i = index">
      <a [href]="productPath" target="_blank" style="cursor: move">
        <img
          defaultImage="assets/images/transparent-400x400.png"
          [lazyLoad]="i === 0 ? (image | productImageThumbnailCdn) : (image | productImageFullCdn)"
          [errorImage]="image"
          productImageErrorHandler
          [checkedOriginal]="true"
          [alt]="productName"
          width="200"
          height="200"
        />
      </a>
    </div>
  </ngx-slick-carousel>
</div>
