import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonModule } from 'app/shared/components/skeleton/skeleton.module';
import { CurrencyExchangeToSelectedPipe } from 'app/shared/pipes/currency-exchange-to-selected.pipe';
import { CurrencyExchangeToPipe } from 'app/shared/pipes/currency-exchange-to.pipe';
import { CurrencyTransformPipe } from 'app/shared/pipes/currency-transform-pipe.pipe';
import { CurrencyTransformToSelectedPipe } from 'app/shared/pipes/currency-transform-to-selected.pipe';
import { SanitizeHtmlPipe } from 'app/shared/pipes/sanitize-html.pipe';
import { SanitizeUrlPipe } from 'app/shared/pipes/sanitize-url.pipe';
import { NgChartsModule } from 'ng2-charts';
import { FuseSidebarModule } from '../../../@fuse/components';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { OnboardModule } from '../../layout/components/onboard/onboard.module';
import { AlertMultiComponent } from '../../shared/components/alert-multi/alert-multi.component';
import { BlurredOverlayComponent } from '../../shared/components/blurred-overlay/blurred-overlay.component';
import { DateSelectorButtonComponent } from '../../shared/components/date-selector-button/date-selector-button.component';
import { FromToDatePickerComponent } from '../../shared/components/from-to-date-picker/from-to-date-picker.component';
import { NoEcomCardComponent } from '../../shared/components/no-ecom-card/no-ecom-card.component';
import { SingleAlertWithIconComponent } from '../../shared/components/single-alert-with-icon/single-alert-with-icon.component';
import { GetResourceFromStoragePipe } from '../../shared/pipes/get-resource-from-storage';
import { IncDecLineComponent } from './inc-dec-line/inc-dec-line.component';
import { NumericCardListComponent } from './numeric-card-list/numeric-card-list.component';
import { NumericCardComponent } from './numeric-card/numeric-card.component';
import { OrdersChartComponent } from './orders-chart/orders-chart.component';
import { OverviewComponent } from './overview/overview.component';
import { ProductsPieChartComponent } from './products-pie-chart/products-pie-chart.component';
import { RetailerDashboardComponent } from './retailer-dashboard/retailer-dashboard.component';
import { SupplierDashboardService } from './services/supplier-dashboard.service';
import { SkeletonDashboardBoxComponent } from './skeleton-dashboard/skeleton-dashboard-box/skeleton-dashboard-box.component';
import { SkeletonDashboardSalesOverviewComponent } from './skeleton-dashboard/skeleton-dashboard-sales-overview/skeleton-dashboard-sales-overview.component';
import { SupplierDashboardComponent } from './supplier-dashboard/supplier-dashboard.component';
import { SupplierNumericCardListComponent } from './supplier-numeric-card-list/supplier-numeric-card-list.component';
import { SupplierOrdersChartComponent } from './supplier-orders-chart/supplier-orders-chart.component';
import { SupplierTopSellingProductsComponent } from './supplier-top-selling-products/supplier-top-selling-products.component';
import { TopRetailersComponent } from './top-retailers/top-retailers.component';
import { TopSellingProductsComponent } from './top-selling-products/top-selling-products.component';
import { TopSuppliersComponent } from './top-suppliers/top-suppliers.component';

@NgModule({
  declarations: [
    RetailerDashboardComponent,
    SupplierDashboardComponent,
    NumericCardComponent,
    IncDecLineComponent,
    OrdersChartComponent,
    ProductsPieChartComponent,
    OverviewComponent,
    TopSuppliersComponent,
    TopSellingProductsComponent,
    NumericCardListComponent,
    SupplierTopSellingProductsComponent,
    TopRetailersComponent,
    SupplierNumericCardListComponent,
    SupplierOrdersChartComponent,
    SkeletonDashboardBoxComponent,
    SkeletonDashboardSalesOverviewComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    OnboardModule,

    // Material
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatCardModule,
    MatTooltipModule,
    MatListModule,
    MatMenuModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatTabsModule,

    // Fuse modules
    FuseSharedModule,
    FuseSidebarModule,

    NgChartsModule,
    FromToDatePickerComponent,
    MatRadioModule,
    MatTableModule,
    GetResourceFromStoragePipe,
    BlurredOverlayComponent,
    NoEcomCardComponent,
    AlertMultiComponent,
    SingleAlertWithIconComponent,
    CurrencyTransformPipe,
    DateSelectorButtonComponent,
    SanitizeHtmlPipe,
    TranslateModule,
    SanitizeUrlPipe,
    SkeletonModule,
    CurrencyTransformToSelectedPipe,
    CurrencyExchangeToSelectedPipe,
  ],
  providers: [SupplierDashboardService, CurrencyTransformPipe, CurrencyExchangeToPipe],
})
export class DashboardModule {}
