import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MARKETPLACE_NAVIGATION } from '../../navigation/navigation-routes/common/marketplace.navigation';

@Injectable({
  providedIn: 'root',
})
export class DiscoveryGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.router.parseUrl(`${MARKETPLACE_NAVIGATION.DEFAULT_PRODUCTS_PATH}?search=${route.params.search_term}`);
  }
}
