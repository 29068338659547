import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestError } from 'app/service/rest/request-error';
import { ConfirmPasswordValidator, StrongPasswordValidator } from '../../validators/password.validator';

@Component({
  selector: 'app-registration-password-form',
  templateUrl: './registration-password-form.component.html',
  styleUrls: ['./registration-password-form.component.scss'],
})
export class RegistrationPasswordFormComponent implements OnInit, OnDestroy {
  @Output() submitClick = new EventEmitter<void>();

  private _form: FormGroup;

  @Output() formChange = new EventEmitter<FormGroup>();

  @Input() set form(value: FormGroup) {
    this._form = value;
  }

  get form(): FormGroup {
    return this._form;
  }

  public submitEnabled = true;

  constructor(private _fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, StrongPasswordValidator]],
      passwordConfirm: ['', [Validators.required, ConfirmPasswordValidator]],
      checkedTerms: [false, Validators.requiredTrue],
    });
    this.formChangeListener();
  }

  submitHandle(): void {
    if (!this.form.valid) {
      return;
    }
    this.submitClick.emit();
  }

  private enableSubmit(): void {
    this.submitEnabled = true;
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get passwordConfirm(): AbstractControl {
    return this.form.get('passwordConfirm');
  }

  get checkedTerms(): AbstractControl {
    return this.form.get('checkedTerms');
  }

  formChangeListener(): void {
    this.form.valueChanges.subscribe(() => {
      this.formChange.emit(this.form);
    });
  }

  handleLoginError(error?: RequestError): void {
    this.enableSubmit();
  }

  ngOnDestroy(): void {
    this.submitClick.unsubscribe();
  }
}
