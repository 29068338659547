<div fxLayout="column" fxLayoutGap="12px" class="filters-container">
  <div class="searchbar">
    <mat-form-field
      class="form-field"
      appearance="fill"
      [floatLabel]="'never'"
      [(ngModel)]="searchTerm"
      (ngModelChange)="searchTermChange.next($event)"
      ngDefaultControl
    >
      <input autocomplete="off" matInput type="text" [placeholder]="'FAVORITES.SEARCH' | translate" />
      <span matPrefix><mat-icon class="s-20 secondary-text">search</mat-icon></span>
    </mat-form-field>
  </div>
  <app-filter-selector-row
    [hiddenFilters]="hiddenFilters"
    [hideFilterDialog]="true"
    [isSupplierSearch]="isSupplierSearch"
  ></app-filter-selector-row>
</div>
