<div fxLayout="column" fxLayoutGap="8px">
  <div fxLayout="row" fxLayoutGap="4px">
    <h2>${{ price }}</h2>
    <div class="typography-h2-subtitle" fxFlexAlign="end">/ {{ 'PLAN_UPSELL_BOXES.MONTH' | translate }}</div>
  </div>
  <div class="typography-body-2">
    {{
      price == 0 && trialDays == 0
        ? ('PLAN_UPSELL_BOXES.COMPLETELY_FREE' | translate)
        : isAnnual
        ? ('PLAN_UPSELL_BOXES.PAID_ANNUALLY' | translate)
        : !!trialDays
        ? ('PLAN_UPSELL_BOXES.AFTER_TRIAL' | translate : { trialDays })
        : ('PLAN_UPSELL_BOXES.PAID_MONTHLY' | translate)
    }}
  </div>
</div>
