import { Component, Input, OnInit } from '@angular/core';
import { productCardStatusConfigs } from '../../product-card.config';
import { SearchProductVO } from '../../../../vo/search-product-vo';
import { AddProductToCatalogService } from '../../../../service/catalog/add-product-to-catalog.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../app.state';
import { Catalog } from '../../../../vo/catalog';
import { subscriptionsSelector } from '../../../../store/subscription/subscription.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-product-card-action-default',
  templateUrl: './product-card-action-default.component.html',
  styleUrls: ['./product-card-action-default.component.scss'],
})
export class ProductCardActionDefaultComponent implements OnInit {
  @Input() product: SearchProductVO;
  config = productCardStatusConfigs;
  hasAvailableEcom = false;
  selectedCatalog: Catalog;
  hasMoreCatalog = true;

  constructor(private addProductToCatalogService: AddProductToCatalogService, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscribeToPlansLoaded();
    this.subscribeToSelectedCatalog();
    this.subscribeToRctalogs();
  }

  handleButtonClicked(): void {
    this.addProductToCatalogService.add(this.product);
  }

  private subscribeToPlansLoaded(): void {
    this.store
      .select(subscriptionsSelector)
      .pipe(untilDestroyed(this))
      .subscribe((subscription) => {
        if (!!subscription) {
          this.handlePlansLoaded();
        }
      });
  }

  private handlePlansLoaded(): void {
    this.hasAvailableEcom = true;
  }

  private subscribeToSelectedCatalog(): void {
    this.store
      .pipe(
        untilDestroyed(this),
        select((state) => state.rcatalogs),
        select((state) => state.selectedCatalog)
      )
      .subscribe((catalog) => {
        this.selectedCatalog = catalog;
      });
  }

  private subscribeToRctalogs(): void {
    this.store
      .pipe(
        untilDestroyed(this),
        select((state) => state.rcatalogs),
        select((state) => state.catalogs)
      )
      .subscribe((catalogs) => {
        this.hasMoreCatalog = catalogs && catalogs.length > 1;
      });
  }
}
