import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AppState } from '../../../../../app.state';
import { CountriesManagerService } from '../../../../../service/countries-manager/countries-manager.service';
import { PreferenceHelperService } from '../../../../../service/preference/preference-helper.service';
import {
  PreferenceStoreService,
  PreferenceTypeRecord,
} from '../../../../../service/preference/preference.store.service';
import { CategoryService } from '../../../../../service/product-search/category.service';
import { flattenCategoriesSelector } from '../../../../../store/category/category.selector';
import { CountryNode } from '../../../../../utils/Countries';
import { GETTING_STARTED_CONTENT_MAX_WIDTH } from '../../../../../utils/getting-started/getting-started-style-settings';
import { Preference } from '../../../../../vo/Preferences/preference';
import { CategorySelectorButtonsComponent } from '../../../../category-selector-buttons/category-selector-buttons.component';
import { NotificationService } from '../../../../notification/notification.service';
import { PreferencesModuleService } from '../../../../preferences/service/preferences-module.service';
import { StepDataModel } from '../../../components/sub-steps-stepper/model/step-data.model';
import { SubStepsStepperComponent } from '../../../components/sub-steps-stepper/sub-steps-stepper.component';
import { GettingStartedStepBaseService } from '../../../service/getting-started-step-base.service';
import { GettingStartedStepBaseComponent } from '../../getting-started-step-base/getting-started-step-base.component';

@Component({
  selector: 'app-personalization',
  templateUrl: './personalization.component.html',
  styleUrls: ['./personalization.component.scss'],
  providers: [PreferencesModuleService],
})
export class PersonalizationComponent extends GettingStartedStepBaseComponent implements OnInit, OnDestroy {
  @ViewChild('categorySelectorButtons') categorySelector: CategorySelectorButtonsComponent;
  @ViewChild('stepperComponent') stepper: SubStepsStepperComponent;
  isLoading = false;
  hasError = false;
  isDirty = false;
  selectedCategories: number[] = [];
  selectedToCountries: CountryNode[] = [];
  selectedFromCountries: CountryNode[] = [];
  currentStepIndex = 0;
  stepTitles = PERSONALIZATION_STEP_TITLES;
  contentMaxWidth = GETTING_STARTED_CONTENT_MAX_WIDTH;

  private unsubscribeAll: Subject<void>;

  steps: StepDataModel[] = [
    {
      optional: false,
      state: 'current',
      label: 'GETTING_STARTED.STEP_PAGE.PERSONALIZATION.STEPPER_LABELS.PRODUCTS',
      isDone: false,
      errorLabel: 'RETAILER_SEARCH_SETTINGS.NOTIFICATIONS.CATEGORY_WARNING',
    },
    {
      optional: false,
      state: 'default',
      label: 'GETTING_STARTED.STEP_PAGE.PERSONALIZATION.STEPPER_LABELS.CUSTOMERS',
      isDone: false,
      errorLabel: 'RETAILER_SEARCH_SETTINGS.NOTIFICATIONS.SHIPPING_WARNING',
    },
    {
      optional: false,
      state: 'default',
      label: 'GETTING_STARTED.STEP_PAGE.PERSONALIZATION.STEPPER_LABELS.SUPPLIERS',
      isDone: false,
      errorLabel: 'RETAILER_SEARCH_SETTINGS.NOTIFICATIONS.WAREHOUSE_WARNING',
    },
  ];
  constructor(
    gettingStartedStepBaseService: GettingStartedStepBaseService,
    private preferenceService: PreferencesModuleService,
    private countriesManagerService: CountriesManagerService,
    private notificationService: NotificationService,
    private preferencesStoreService: PreferenceStoreService,
    private preferenceHelperService: PreferenceHelperService,
    private categoryService: CategoryService,
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {
    super(gettingStartedStepBaseService);
    this.unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    if (this.isCompleted) {
      this.steps.map((step) => (step.isDone = true));
    }
    this.subscribeToPreferences();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  get lasStepIndex(): number {
    return this.steps.length - 1;
  }

  nextClicked(): void {
    // add business logic

    if (this.stepper.steps[this.currentStepIndex].isDone) {
      if (this.currentStepIndex === this.lasStepIndex) {
        this.savePreference().subscribe((isComplete) => {
          if (!isComplete) {
            return;
          }
          this.goToNextStep();
        });
      } else {
        this.stepper.navigateSteps.next('next');
      }
    } else {
      this.notificationService.error(
        this.translateService.instant(this.stepper.steps[this.currentStepIndex].errorLabel)
      );
    }
  }

  prevClicked(): void {
    if (this.currentStepIndex === 0) {
      this.goToPreviousStep();
    } else {
      this.stepper.navigateSteps.next('prev');
    }
  }

  handleSelectedCategoryChange(newValue: number[]): void {
    this.selectedCategories = newValue;
    this.makeDirtyOnChange();
    this.stepper.completeStep.next({ stepIndex: this.currentStepIndex, complete: !!this.selectedCategories.length });
  }

  handleShipsToChange(newValue: CountryNode[]): void {
    this.selectedToCountries = newValue;
    this.makeDirtyOnChange();
    this.stepper.completeStep.next({ stepIndex: this.currentStepIndex, complete: !!this.selectedToCountries.length });
  }

  handleShipsFromChange(newValue: CountryNode[]): void {
    this.selectedFromCountries = newValue;
    this.makeDirtyOnChange();
    this.stepper.completeStep.next({ stepIndex: this.currentStepIndex, complete: !!this.selectedFromCountries.length });
  }

  handleSubStepChange(index: number): void {
    this.currentStepIndex = index;
  }

  private makeDirtyOnChange(): void {
    this.isDirty = true;
  }

  private subscribeToPreferences(): void {
    this.isLoading = true;
    this.preferencesStoreService
      .getPreferencesWithTypes()
      .pipe(
        takeUntil(this.unsubscribeAll),
        tap(([preferences, types]) => {
          this.handlePreferenceResponse(preferences, types);
        })
      )
      .subscribe(
        () => {
          this.isLoading = false;
          this.hasError = false;
        },
        () => {
          this.isLoading = false;
          this.hasError = true;
        }
      );
  }

  private handlePreferenceResponse(response: Preference[], types: PreferenceTypeRecord): void {
    this.store
      .select(flattenCategoriesSelector)
      .pipe(take(1))
      .subscribe((categories) => {
        this.selectedCategories = this.preferenceHelperService
          .getCategoryPreferences(response, types)
          .map((pref) => +pref.preferenceValue)
          .filter((value) => this.categoryService.isFirstLevelCategory(value, categories));
        if (!isEmpty(this.selectedCategories)) {
          this.steps[0].isDone = true;
        }
      });

    this.selectedToCountries = this.preferenceHelperService
      .getShipsToLocationPreferences(response, types)
      .map((pref) => this.countriesManagerService.getCountryNodeByCountryName(pref.preferenceValue));
    if (!isEmpty(this.selectedToCountries)) {
      this.steps[1].isDone = true;
    }
    this.selectedFromCountries = this.preferenceHelperService
      .getWarehouseLocationPreferences(response, types)
      .map((pref) => this.countriesManagerService.getCountryNodeByCountryName(pref.preferenceValue));
    if (!isEmpty(this.selectedFromCountries)) {
      this.steps[2].isDone = true;
    }
  }

  public savePreference(): Observable<boolean> {
    if (this.isValid()) {
      if (!this.isDirty) {
        return of(true);
      }
      return this.preferenceService
        .createPreference(
          this.selectedCategories,
          this.selectedFromCountries.map((country) => country.name),
          this.selectedToCountries.map((country) => country.name)
        )
        .pipe(switchMap((preferences) => this.preferencesStoreService.savePreferences(preferences)));
    } else {
      /* if (this.selectedCategories.length === 0) {
        this.notificationService.error(
          this.translateService.instant('RETAILER_SEARCH_SETTINGS.NOTIFICATIONS.CATEGORY_WARNING')
        );
      }
      if (this.selectedFromCountries.length === 0) {
        this.notificationService.error(
          this.translateService.instant('RETAILER_SEARCH_SETTINGS.NOTIFICATIONS.WAREHOUSE_WARNING')
        );
      }
      if (this.selectedToCountries.length === 0) {
        this.notificationService.error(
          this.translateService.instant('RETAILER_SEARCH_SETTINGS.NOTIFICATIONS.SHIPPING_WARNING')
        );
      } */
      return of(false);
    }
  }

  public isValid(): boolean {
    return (
      this.selectedCategories.length > 0 && this.selectedToCountries.length > 0 && this.selectedFromCountries.length > 0
    );
  }
}

const PERSONALIZATION_STEP_TITLES: PersonalizationStepTitle[] = [
  {
    title: 'GETTING_STARTED.STEP_PAGE.PERSONALIZATION.CATEGORIES.TITLE',
    description: 'GETTING_STARTED.STEP_PAGE.PERSONALIZATION.CATEGORIES.SUBTITLE',
  },
  {
    title: 'GETTING_STARTED.STEP_PAGE.PERSONALIZATION.SHIPS_TO.TITLE',
    description: 'GETTING_STARTED.STEP_PAGE.PERSONALIZATION.SHIPS_TO.SUBTITLE',
  },
  {
    title: 'GETTING_STARTED.STEP_PAGE.PERSONALIZATION.WAREHOUSE_LOCATION.TITLE',
    description: 'GETTING_STARTED.STEP_PAGE.PERSONALIZATION.WAREHOUSE_LOCATION.SUBTITLE',
  },
];

export interface PersonalizationStepTitle {
  title: string;
  description: string;
}
