<div class="w-100p h-100-p" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="40px">
  <app-sub-steps-stepper
    #stepperComponent
    [steps]="steps"
    (stepChange)="handleSubStepChange($event)"
  ></app-sub-steps-stepper>
  <div
    fxFlex="1 0 0"
    class="w-100p personalization-container"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="40px"
  >
    <ng-container *conditionLoading="!isLoading">
      <ng-container *ngIf="!hasError; else errorTemplate">
        <app-step-title-description
          [title]="stepTitles[currentStepIndex].title | translate"
          [description]="stepTitles[currentStepIndex].description | translate"
        ></app-step-title-description>
        <ng-container [ngSwitch]="currentStepIndex">
          <div *ngSwitchCase="0" class="w-100p" [style.max-width]="contentMaxWidth">
            <app-preference-category
              *ngIf="currentStepIndex === 0"
              class="category-selector"
              [(selectedCategories)]="selectedCategories"
              (selectedCategoriesChange)="handleSelectedCategoryChange($event)"
            ></app-preference-category>
          </div>
          <div *ngSwitchCase="1" class="w-100p" [style.max-width]="contentMaxWidth">
            <app-preference-country-multi
              [(selectedCountries)]="selectedToCountries"
              (selectedCountriesChange)="handleShipsToChange($event)"
              class="w-100p"
            ></app-preference-country-multi>
          </div>
          <div *ngSwitchCase="2" class="w-100p" [style.max-width]="contentMaxWidth">
            <app-preference-country-multi
              [(selectedCountries)]="selectedFromCountries"
              (selectedCountriesChange)="handleShipsFromChange($event)"
              class="w-100p"
            ></app-preference-country-multi>
          </div>
        </ng-container>
      </ng-container>
      <app-step-navigation-buttons
        (prevClick)="prevClicked()"
        (nextClick)="nextClicked()"
      ></app-step-navigation-buttons>
    </ng-container>
  </div>
</div>

<ng-template #errorTemplate>
  <app-error-message></app-error-message>
</ng-template>
