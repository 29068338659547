<app-authentication-layout [reverse]="true">
  <app-authentication-dialog-banner
    [textList]="bannerTextList"
    [coverImgPath]="bannerCoverImage"
    [textPosition]="'center'"
    banner-side
  ></app-authentication-dialog-banner>
  <app-registration-supplier-content
    content-side
    [titleKey]="titleKey"
    [descriptionKey]="descriptionKey"
  ></app-registration-supplier-content>
</app-authentication-layout>
