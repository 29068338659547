import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { TranslateService } from '@ngx-translate/core';
import { CategoryVo } from '../../vo/category-vo';
import { map, take } from 'rxjs/operators';

@Pipe({
  name: 'categoryNamesByIds',
  standalone: true,
})
export class CategoryNamesByIdsPipe implements PipeTransform {
  constructor(private store: Store<AppState>, private translateService: TranslateService) {}

  transform(categoryIds: number[] | string[], ...args: unknown[]): string {
    let matchingCategories: CategoryVo[];
    const categoryIdsParsed = categoryIds.map((el) => parseInt(el, 0));
    this.store
      .select((appState) => appState.categories.flattenCategories)
      .pipe(take(1))
      .subscribe(
        (categories) => (matchingCategories = categories.filter((category) => categoryIdsParsed.includes(category.id)))
      );

    return matchingCategories
      .map(
        (mainCategory) =>
          mainCategory.categoryName.find(
            (categoryName) => categoryName.languageCode === this.translateService.currentLang
          )?.name ?? mainCategory.categoryName.find((categoryName) => categoryName.languageCode === 'en').name
      )
      .join(', ');
  }
}
