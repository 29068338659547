<div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="24px">
  <app-wizard-category-mapping-bulk-actions
    [displayedItems]="displayedItems"
  ></app-wizard-category-mapping-bulk-actions>
  <div
    fxLayout="row"
    fxLayout.lt-lg="column"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-lg="start start"
    fxLayoutGap="12px"
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="typography-body-large-2">{{ headerService.titleConfig.customCategoriesTitle }}</div>
      <div class="p-8 centered" ngClass.lt-md="py-0">
        <mat-icon class="s-20 icon-filled" [matTooltip]="headerService.titleConfig.customCategoriesTooltip"
          >info</mat-icon
        >
      </div>
    </div>
    <div ngClass.lt-lg="w-100p">
      <app-searchbar-custom (searchTermChange)="headerService.setMapToSearchTerm($event)"></app-searchbar-custom>
    </div>
  </div>
</div>
