<div class="my-suppliers-mobile-item" [ngClass]="{ disabled: !approved }">
  <div class="my-suppliers-mobile-item-header" (click)="handleExpansion()">
    <div style="width: 100%" fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="8px">
      <div class="text-truncate clickable-underlined">{{ (catalog | async).name }}</div>
      <ng-container *ngIf="!supplier.autoOrder">
        <app-badge-custom
          style="font-weight: 600"
          fxFlex="1 0 auto"
          badgeSize="sm"
          [text]="'RETAILER_MANAGE_ORDERS.BADGE.NON_AUTO_ORDER' | translate"
          [textColor]="'NON_AUTO_ORDER' | statusTextColor"
          [bgColor]="'NON_AUTO_ORDER' | statusColor"
          [matTooltip]="'MY_SUPPLIERS.TOOLTIPS.NON_AUTO_ORDER' | translate"
          matTooltipPosition="above"
        ></app-badge-custom>
      </ng-container>
    </div>
    <ng-container [ngSwitch]="approved">
      <mat-icon *ngSwitchCase="true" class="arrow-icon" [ngClass]="{ 'arrow-up': expanded }"
        >keyboard_arrow_down</mat-icon
      >
      <div *ngSwitchCase="false">{{ 'MY_SUPPLIERS.INFO.CATALOG_NOT_AVAILABLE_MOBILE' | translate }}</div>
    </ng-container>
  </div>

  <div [@detailExpand]="expanded ? 'expanded' : 'collapsed'">
    <div class="my-suppliers-mobile-item-content">
      <div class="my-suppliers-mobile-data-list">
        <div class="my-suppliers-mobile-data-list-item">
          <h5>{{ 'MY_SUPPLIERS.TABLE_TITLES.SUPPLIER' | translate }}</h5>
          <div>{{ supplier.companyName }}</div>
        </div>

        <div class="my-suppliers-mobile-data-list-item">
          <h5>{{ 'MY_SUPPLIERS.TABLE_TITLES.EMAIL' | translate }}</h5>

          <ng-container *ngIf="hasAccessToReadContacts; else upsellBadge">
            <div>{{ supplier.contactEmail }}</div>
          </ng-container>
        </div>

        <ng-container *ngIf="!!supplier.publicPhoneNumber">
          <div class="my-suppliers-mobile-data-list-item">
            <h5>{{ 'MY_SUPPLIERS.TABLE_TITLES.PHONE' | translate }}</h5>

            <ng-container *ngIf="hasAccessToReadContacts; else upsellBadge">
              <div>
                {{ supplier.publicPhoneCode ? supplier.publicPhoneCode : '' + ' ' + supplier.publicPhoneNumber }}
              </div>
            </ng-container>
          </div>
        </ng-container>

        <div class="my-suppliers-mobile-data-list-item">
          <h5>{{ 'MY_SUPPLIERS.TABLE_TITLES.PRODUCTS' | translate }}</h5>
          <div
            *ngIf="managedProductNumber | async as value"
            [innerHTML]="
              (value.count === 1
                ? 'MY_SUPPLIERS.TABLE_CONTENT.MANAGED_PRODUCTS'
                : 'MY_SUPPLIERS.TABLE_CONTENT.MANAGED_PRODUCTS_MULTIPLE'
              ) | translate : { productCount: value.count }
            "
          ></div>
        </div>

        <div class="my-suppliers-mobile-data-list-item">
          <h5>{{ 'MY_SUPPLIERS.TABLE_TITLES.ORDERS' | translate }}</h5>
          <app-orders-info
            *ngIf="wholesalePriceAndOrderNumber | async as value"
            [total]="value.wholesalePriceSum"
            [numberOfOrders]="value.lineItemCount"
            [currency]="'USD'"
          ></app-orders-info>
        </div>

        <div class="my-suppliers-mobile-data-list-item">
          <h5>{{ 'MY_SUPPLIERS.TABLE_TITLES.CATALOG_DETAILS' | translate }}</h5>
          <mat-icon (click)="showCatalogDetails()">keyboard_arrow_right</mat-icon>
        </div>
      </div>

      <div fxLayoutAlign="end">
        <app-contact-supplier-button
          [selectedEcom]="pageService.selectedEcom | async"
          [isAdmin]="pageService.isAdmin | async"
          [supplierUserId]="+supplier.userId"
          [buttonType]="'syncee-black-flat-button'"
          [withoutIcon]="true"
        ></app-contact-supplier-button>
      </div>
    </div>
  </div>
</div>

<ng-template #upsellBadge>
  <app-need-subscription-badge></app-need-subscription-badge>
</ng-template>
