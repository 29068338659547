import { Component, Input, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-plan-upsell-box-pricing',
  templateUrl: './plan-upsell-box-pricing.component.html',
  styleUrls: ['./plan-upsell-box-pricing.component.scss'],
  standalone: true,
  imports: [FlexModule, TranslateModule],
})
export class PlanUpsellBoxPricingComponent implements OnInit {
  @Input() price: number;
  @Input() isAnnual: boolean;
  @Input() trialDays: number;

  constructor() {}

  ngOnInit(): void {}
}
