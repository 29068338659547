import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Utils } from 'app/utils/utils';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { AppState } from '../../../app.state';
import { CountriesManagerService } from '../../../service/countries-manager/countries-manager.service';
import { SupplierDetails, SupplierDetailsService } from '../../../service/suppliers/supplier-details.service';
import { UserSocialService } from '../../../service/user/user-social.service';
import { PhoneNumberModel } from '../../../shared/components/country-phone-input/phone-number-model';
import { customPhoneInputValidator } from '../../../shared/components/phone-input-form-field/custom-phone-input.validator';
import { getCurrentUserIdSelector } from '../../../store/user/user.selector';
import { Social } from '../../../vo/social/social';
import { SocialPlatform, SocialPlatformType } from '../../../vo/social/social-platform';
import { SocialPlatformToUser } from '../../../vo/social/social-platform-to-user';
import { SnippetEnum } from '../../setup-guide/enums/snippet-enums';
import { SetupGuideService } from '../../setup-guide/service/setup-guide.service';

@Injectable()
export class SupplierPublicInformationService {
  form: FormGroup;
  inputNames = SupplierPublicInformationInputNames;
  socialPlatforms: SocialPlatform[];
  initialized = false;
  private supplierDetailsExist: boolean;

  readonly currentYear = new Date().getFullYear();

  public readonly MIN_YEAR_OF_ESTABLISHMENT = 1800;

  constructor(
    private formBuilder: FormBuilder,
    private userSocialService: UserSocialService,
    private supplierDetailsService: SupplierDetailsService,
    private countriesManagerService: CountriesManagerService,
    private store: Store<AppState>,
    private setupGuideService: SetupGuideService
  ) {
    this.buildForm();
  }

  buildForm(): void {
    this.combineForm()
      .pipe(
        tap((form) => (this.form = this.formBuilder.group(form))),
        switchMap(() => this.initFormData())
      )
      .subscribe(({ supplierDetails, socials }) => {
        this.patchForm(supplierDetails, socials);
        this.initialized = true;
      });
  }

  save(): Observable<boolean> {
    if (this.form.valid) {
      return this.saveData();
    } else {
      this.form.markAllAsTouched();
      return of(false);
    }
  }

  private saveData(): Observable<boolean> {
    const socials = this.getSocialParamsFromForm();
    return this.getUserId().pipe(
      switchMap((userId) =>
        forkJoin([
          this.supplierDetailsService.save(
            {
              ...this.getSupplierDetailParamsFromForm(),
              userId,
            },
            this.supplierDetailsExist
          ),
          ...(socials.length > 0 ? [this.userSocialService.saveSocialPlatforms(userId, socials)] : []),
        ]).pipe(
          tap(() => this.setupGuideService.setCompletedStep(SnippetEnum.SUPPLIER_SETUP_STOREFRONT)),
          map(() => true)
        )
      )
    );
  }

  private combineForm(): Observable<{}> {
    return combineLatest([this.getBasicFormControls(), this.getSocialFormControls()]).pipe(
      map(([basic, social]) => ({
        ...basic,
        [this.inputNames.SOCIAL_LINKS]: this.formBuilder.group(social),
      }))
    );
  }

  private getBasicFormControls(): Observable<{}> {
    return of({
      [this.inputNames.COMPANY_NAME]: [null, [Validators.required]],
      [this.inputNames.COMPANY_EMAIL]: [null, [Validators.required, Validators.email]],
      [this.inputNames.WEBSITE]: [null, [Validators.required]],
      [this.inputNames.DESCRIPTION]: [
        null,
        [Validators.required, Validators.minLength(500), Validators.maxLength(1500)],
      ],
      [this.inputNames.MAIN_WAREHOUSE_LOCATION]: [null, [Validators.required]],
      [this.inputNames.PHONE]: [
        { countryCode: null, phoneNumber: null },
        [customPhoneInputValidator(), Validators.required],
      ],
      [this.inputNames.DATE]: [
        null,
        [Validators.required, Validators.min(this.MIN_YEAR_OF_ESTABLISHMENT), Validators.max(this.currentYear)],
      ],
    });
  }

  private initFormData(): Observable<{ supplierDetails: SupplierDetails; socials: Social[] }> {
    return this.getUserId().pipe(
      switchMap((userId) =>
        forkJoin([this.getSupplierDetails(userId), this.getSocialForUser(userId)]).pipe(
          take(1),
          tap(
            ([supplierDetails]) =>
              (this.supplierDetailsExist =
                !!supplierDetails && Object.values(supplierDetails).some((value) => !Utils.isNullOrUndefined(value)))
          ),
          map(([supplierDetails, socials]) => ({ supplierDetails, socials }))
        )
      )
    );
  }

  private patchForm(supplierDetails: SupplierDetails, socials: Social[]): void {
    if (!supplierDetails) {
      return;
    }
    this.form.patchValue({
      [this.inputNames.COMPANY_NAME]: supplierDetails.companyName,
      [this.inputNames.COMPANY_EMAIL]: supplierDetails.contactEmail,
      [this.inputNames.WEBSITE]: supplierDetails.website,
      [this.inputNames.DESCRIPTION]: supplierDetails.description,
      [this.inputNames.MAIN_WAREHOUSE_LOCATION]: this.countriesManagerService.getCountryCodeByCountryName(
        supplierDetails.mainWarehouseLocation
      ),
      [this.inputNames.PHONE]: { phoneNumber: supplierDetails.phoneNumber, countryCode: supplierDetails.phoneCode },
      [this.inputNames.DATE]: supplierDetails.yearOfFoundation,
      [this.inputNames.SOCIAL_LINKS]: this.mapSocialsToFormGroupData(socials),
    });
  }

  private mapSocialsToFormGroupData(socials: Social[]): any {
    const socialGroupData = {};
    socials.forEach((social) => (socialGroupData[social.platform] = social.url));
    return socialGroupData;
  }

  private getSupplierDetails(userId: number): Observable<SupplierDetails> {
    return this.supplierDetailsService.get(userId);
  }

  private getUserId(): Observable<number> {
    return this.store.select(getCurrentUserIdSelector).pipe(take(1));
  }

  private getSupplierDetailParamsFromForm(): Partial<SupplierDetails> {
    return {
      companyName: this.companyName.value,
      contactEmail: this.companyEmail.value,
      website: this.website.value,
      description: this.description.value,
      mainWarehouseLocation: this.countriesManagerService.getCountryNameByCountryCode(this.mainWarehouseLocation.value),
      phoneNumber: this.phone.value.phoneNumber,
      phoneCode: this.phone.value.countryCode,
      yearOfFoundation: Number(this.date.value),
    };
  }

  private getSocialParamsFromForm(): SocialPlatformToUser[] {
    const keys = Object.keys(this.formValue[this.inputNames.SOCIAL_LINKS]) as SocialPlatformType[];
    return keys
      .map((key) => ({
        platform: key,
        url: this.formValue[this.inputNames.SOCIAL_LINKS][key],
      }))
      .filter((platform) => !!platform.url && platform.url.length > 0);
  }

  private getSocialFormControls(): Observable<{}> {
    return this.getSocialPlatforms().pipe(map((social) => this.mapSocialPlatformToFormControl(social)));
  }

  private mapSocialPlatformToFormControl(platforms: SocialPlatform[]): any {
    const socialPlatforms = {};
    platforms.forEach((platform) => (socialPlatforms[platform.name] = [null]));
    return socialPlatforms;
  }

  private getSocialForUser(userId: number): Observable<Social[]> {
    return this.userSocialService.getSocial(userId);
  }

  private getSocialPlatforms(): Observable<SocialPlatform[]> {
    return this.userSocialService.getSocialPlatforms().pipe(tap((social) => (this.socialPlatforms = social)));
  }

  get companyName(): AbstractControl<string> {
    return this.form.get<string>(this.inputNames.COMPANY_NAME);
  }

  get companyEmail(): AbstractControl<string> {
    return this.form.get<string>(this.inputNames.COMPANY_EMAIL);
  }

  get website(): AbstractControl<string> {
    return this.form.get<string>(this.inputNames.WEBSITE);
  }

  get description(): AbstractControl<string> {
    return this.form.get<string>(this.inputNames.DESCRIPTION);
  }

  get mainWarehouseLocation(): AbstractControl<string> {
    return this.form.get<string>(this.inputNames.MAIN_WAREHOUSE_LOCATION);
  }

  get phone(): AbstractControl<PhoneNumberModel> {
    return this.form.get<string>(this.inputNames.PHONE);
  }

  get date(): AbstractControl<string> {
    return this.form.get<string>(this.inputNames.DATE);
  }

  get formValue(): any {
    return this.form.value;
  }
}

export enum SupplierPublicInformationInputNames {
  COMPANY_NAME = 'companyName',
  COMPANY_EMAIL = 'companyEmail',
  WEBSITE = 'website',
  DESCRIPTION = 'description',
  MAIN_WAREHOUSE_LOCATION = 'mainWarehouseLocation',
  PHONE = 'phone',
  DATE = 'date',
  SOCIAL_LINKS = 'socialLinks',
}
