import { Component, OnInit } from '@angular/core';
import { FilterSelectorOption, FilterSelectorOptionBadge } from '../../../filter-selector/filter-selector.component';
import { select, Store } from '@ngrx/store';
import { SetHelperAction, SetTemporaryFilter } from '../../../../../../store/product-search/product-search.action';
import { Observable } from 'rxjs';
import { ProductSearchState } from '../../../../../../store/product-search/product-search.reducer';
import { AppState } from '../../../../../../app.state';
import { SupplierDataForFilter, SuppliersService } from '../../../../../../service/suppliers/suppliers.service';
import { debounce } from 'lodash';
import { CountryNameToCodePipe } from '../../../../../../shared/pipes/legacy/country-name-to-code.pipe';
import { take } from 'rxjs/operators';
import { RestResponseWithPagination } from '../../../../../../service/rest/rest-response';
import { CountryFlagPipe } from '../../../../../../shared/pipes/country_flag.pipe';

@Component({
  selector: 'app-supplier-filter',
  templateUrl: './supplier-filter.component.html',
  styleUrls: ['./supplier-filter.component.scss'],
})
export class SupplierFilterComponent implements OnInit {
  private static size = 20;
  options: FilterSelectorOption<string, string>[] = [];
  productSearchStore$: Observable<ProductSearchState>;
  searchTerm: string;
  totalNumber = SupplierFilterComponent.size * 2;
  debouncedLoadNewOptions: () => void;
  private from = 0;

  constructor(
    private store: Store<AppState>,
    private supplierService: SuppliersService,
    private countryNameToCodePipe: CountryNameToCodePipe,
    private countryFlagPipe: CountryFlagPipe
  ) {
    this.productSearchStore$ = this.store.pipe(select((state) => state.productSearch));
    this.debouncedLoadNewOptions = debounce(this.loadNewOptions, 500);
  }

  ngOnInit(): void {
    this.loadMore();
  }

  handleSelectionChange(value: string): void {
    const supplier: FilterSelectorOption<string, string> = this.options.find((option) => option.key === value);
    this.store.dispatch(new SetHelperAction({ supplier: { id: Number(supplier.key), name: supplier.value } }));
    this.store.dispatch(new SetTemporaryFilter({ value: { supplier: value.toString() } }));
  }

  loadMore(): void {
    this.fetchSuppliers(this.searchTerm)
      .pipe(take(1))
      .subscribe((res) => {
        this.options.push(...this.mapSuppliersToOptions(res.getData()));
        this.totalNumber = res.totalCount;
      });
    this.increaseFrom();
  }

  handleSearchTermChange(value: string): void {
    this.searchTerm = value;
    this.debouncedLoadNewOptions();
  }

  private loadNewOptions(): void {
    this.options = [];
    this.from = 0;
    this.loadMore();
  }

  private fetchSuppliers(searchTerm?: string): Observable<RestResponseWithPagination<SupplierDataForFilter>> {
    return this.supplierService.getSupplierDataForFilter(this.from, SupplierFilterComponent.size, searchTerm);
  }

  private mapSuppliersToOptions(suppliers: SupplierDataForFilter[]): FilterSelectorOption<string, string>[] {
    return suppliers.map((supplier) => {
      const badges: FilterSelectorOptionBadge[] = [];
      if (supplier.vip) {
        badges.push({ text: 'VIP', textColor: 'white', backgroundColor: '#286ef9' });
      }
      if (supplier.isNew) {
        badges.push({ text: 'NEW', textColor: 'white', backgroundColor: '#8BC34A' });
      }
      if (supplier.premium) {
        badges.push({ text: 'PREMIUM', textColor: 'white', backgroundColor: '#FBBF46' });
      }
      return {
        value: supplier.companyName,
        key: supplier.userId.toString(),
        badges,
        image: {
          src: this.countryFlagPipe.transform(this.countryNameToCodePipe.transform(supplier.mainWarehouseLocation)),
          height: '10px',
          width: '20px',
        },
      };
    });
  }

  private increaseFrom(): void {
    this.from += SupplierFilterComponent.size;
  }
}
