import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { selectedRetailerEcomSelector } from '../../../store/ecom/ecom.selector';

import { CatalogSidebarService } from '../../../service/catalog-sidebar/catalog-sidebar.service';
import { ExploreProductsService } from '../../../service/marketplace/explore-products/explore-products.service';

import type { AppState } from '../../../app.state';
import type { CatalogChangeEventHandleVO } from '../../../service/catalog-sidebar/catalog-sidebar.service';
import type { EcomVO } from '../../../service/ecom/ecom.service';
import type { ProductCatalogVo } from '../../../vo/search-product-vo';

@Component({
  selector: 'app-product-page-add-to-import-list',
  templateUrl: './product-page-add-to-import-list.component.html',
  styleUrls: ['./product-page-add-to-import-list.component.scss'],
})
export class ProductPageAddToImportListComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  productId: string;

  @Output()
  addToClick = new EventEmitter<void>();

  public isInImportList: boolean;
  public selectedEcom$: Observable<EcomVO>;

  private _listOfCatalogs: ProductCatalogVo[];
  private _ecomId: number;

  private _unsubscribe = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private _exploreProductService: ExploreProductsService,
    private catalogSidebarService: CatalogSidebarService
  ) {
    this.setUpEcomObservable();
  }

  public ngOnInit(): void {
    this.observeWhenNewProductAdded().subscribe();
  }
  public ngOnChanges(): void {}

  public ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  public handleAddToClick(): void {
    if (this.isInImportList) {
      return;
    }

    this.addToClick.emit();
  }

  private observeWhenNewProductAdded(): Observable<CatalogChangeEventHandleVO> {
    return this.catalogSidebarService.newProductAdded.pipe(takeUntil(this._unsubscribe)).pipe(
      tap((res) => {
        if (res.productId === this.productId) {
          this.getUsedProducts();
        }
      })
    );
  }

  get htmlCatalogList(): string[] {
    return (
      this._listOfCatalogs.map(
        (catalog: ProductCatalogVo): string => `
          <a
            href="/retailer/import-list/${catalog.CATALOG_ID}"
            target="_blank"
          >
            ${catalog.CATALOG_NAME}
          </a>
      `
      ) ?? []
    );
  }

  private getUsedProducts(): void {
    this._exploreProductService
      .getIsProductUsed(this._ecomId, [this.productId])
      .pipe(
        map((res) => res?.filter((el) => el?.productIds?.includes(this.productId)) ?? []),
        tap((listOfCatalogs) => {
          this._listOfCatalogs = listOfCatalogs.map((el) => {
            return { CATALOG_ID: el?.catalogId, CATALOG_NAME: el?.catalogName };
          });
        })
      )
      .subscribe((listOfCatalogs) => {
        this.isInImportList = listOfCatalogs?.length > 0;
      });
  }

  private setUpEcomObservable(): void {
    this.selectedEcom$ = this.store.select(selectedRetailerEcomSelector).pipe(
      filter((value) => !!value),
      tap((value) => {
        this.isInImportList = undefined;
        this._ecomId = value.id;
      }),
      tap(() => this.getUsedProducts())
    );
  }
}
