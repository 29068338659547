import { Component, Input, OnInit } from '@angular/core';
import { WizardCategoryHeaderService } from '../../../service/wizard-category-header.service';
import { CategoryMappingData } from '../../../model/category-mapping-data';

@Component({
  selector: 'app-wizard-mapping-header-left-controls',
  templateUrl: './wizard-mapping-header-left-controls.component.html',
  styleUrls: ['./wizard-mapping-header-left-controls.component.scss'],
})
export class WizardMappingHeaderLeftControlsComponent implements OnInit {
  @Input() displayedItems: CategoryMappingData[];
  constructor(public headerService: WizardCategoryHeaderService) {}

  ngOnInit(): void {}
}
