import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { combineLatest, fromEvent, Observable, of, Subscription } from 'rxjs';
import {
  Action,
  AuthorizationEcomService,
  PermissionPayload,
} from '../../service/authorization-ecom/authorization-ecom.service';
import { EcomVO } from '../../service/ecom/ecom.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { hasRoleSelector } from '../../store/user/user.selector';
import { RolesEnum } from '../../vo/roles/roles';
import { Utils } from '../../utils/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[hasPermissionButton]',
  standalone: true,
})
export class HasPermissionButtonDirective implements AfterViewInit {
  @Input() needsPermission = true;
  @Input() actionType: Action;
  @Input() descriptionTranslationKey: string;
  @Input() payload: Partial<PermissionPayload>;
  @Input() ecom: EcomVO;
  @Input() isAdmin?: boolean;

  private subscription: Subscription;

  constructor(
    private element: ElementRef<HTMLButtonElement>,
    private authorizationService: AuthorizationEcomService,
    private store: Store<AppState>
  ) {}

  public ngAfterViewInit(): void {
    if (this.actionType && this.needsPermission) {
      this.createSubscription();
    }
  }

  private hasPermission$(): Observable<boolean> {
    if (Utils.isNullOrUndefined(this.ecom)) {
      return this.authorizationService.hasPermissionObs(this.actionType, this.payload);
    }

    if (Utils.isNullOrUndefined(this.isAdmin)) {
      return this.store.select(hasRoleSelector(RolesEnum.ADMIN)).pipe(
        map((isAdmin: boolean): boolean => {
          return this.authorizationService.hasPermission(this.actionType, this.ecom, isAdmin, this.payload);
        })
      );
    }

    return of(this.authorizationService.hasPermission(this.actionType, this.ecom, this.isAdmin, this.payload));
  }

  private createSubscription(): void {
    if (!Utils.isNullOrUndefined(this.subscription)) {
      return;
    }

    this.subscription = combineLatest([
      fromEvent(this.element.nativeElement, 'click', { capture: true }),
      this.authorizationService.isAuthenticatedUserOrNot(),
      this.hasPermission$(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([event, isAuth, hasPermission]) => {
        if (!hasPermission && !!isAuth) {
          event.stopPropagation();
          this.authorizationService.openSubscribeDialog(this.descriptionTranslationKey);
        }
      });
  }
}
