import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RegistrationDialogComponent } from './registration-dialog/registration-dialog.component';
import { RegistrationRetailerComponent } from './registration-retailer/registration-retailer.component';
import { AuthenticationLayoutComponent } from '../authentication-layout/authentication-layout.component';
import { CustomDialogWrapperComponent } from 'app/shared/components/dialogs/custom-dialog-wrapper/custom-dialog-wrapper.component';
import { RegistrationSupplierComponent } from './registration-supplier/registration-supplier.component';
import { RegistrationRetailerContentComponent } from './registration-retailer/registration-retailer-content/registration-retailer-content.component';
import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';
import { RegistrationPasswordFormComponent } from './registration-password-form/registration-password-form.component';
import { RegistrationSupplierContentComponent } from './registration-supplier/registration-supplier-content/registration-supplier-content.component';
import { AuthenticationDialogBannerComponent } from '../authentication-dialog-banner/authentication-dialog-banner.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    MatStepperModule,
    RouterModule,
    FuseSharedModule,
    AuthenticationLayoutComponent,
    CustomDialogWrapperComponent,
    SvgIconComponent,
    AuthenticationDialogBannerComponent,
    TranslateModule,
  ],
  declarations: [
    RegistrationDialogComponent,
    RegistrationRetailerComponent,
    RegistrationSupplierComponent,
    RegistrationRetailerContentComponent,
    RegistrationPasswordFormComponent,
    RegistrationSupplierContentComponent,
  ],
})
export class RegistrationModule {}
