import { Injectable } from '@angular/core';
import { User } from './user';
import { RestService } from '../rest/rest.service';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TaskTypes, USER_ROLES } from '../../utils/Constants';
import { InitAppService } from '../shared/init-app.service';
import { Intercom } from 'ng-intercom';
import { BootstrapService } from '../bootstrap/bootstrap.service';
import { NotificationService } from '../../main/notification/notification.service';
import { AlterUserVo } from './alter-user.vo';
import { UserGatewayService } from './user-gateway.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { getCurrentUserIdSelector, getCurrentUserSelector } from '../../store/user/user.selector';
import { UserActionTypes } from '../../store/user/user.actions';
import { Actions, ofType } from '@ngrx/effects';
import { LogoutAction } from 'app/store/authentication/authentication.actions';
import { Utils } from 'app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user: User = null;
  private userSubject: Subject<User> = new Subject<User>();

  constructor(
    private restService: RestService,
    private initAppService: InitAppService,
    public intercom: Intercom,
    private bootstrapService: BootstrapService,
    private notificationService: NotificationService,
    private userGatewayService: UserGatewayService,
    private store: Store<AppState>,
    private actions$: Actions
  ) {}

  userSubObservable(): Observable<User> {
    return this.userSubject.asObservable();
  }

  getUserDetailsWithBroadcast(): Observable<User> {
    return this.getUserDetails(true, true);
  }

  getCurrentUserFromStore(): Observable<User> {
    return this.store.select(getCurrentUserSelector);
  }

  getCurrentUserIdFromStore(): Observable<number> {
    return this.store.select(getCurrentUserIdSelector);
  }

  subscribeToUserUpdateSuccess(): Observable<void> {
    return this.actions$.pipe(ofType(UserActionTypes.UPDATE_USER_SUCCESS));
  }

  getUserDetailsForce(): Observable<User> {
    return this.getUserDetails(true);
  }

  getUserDetailsForceWithoutNavigate(): Observable<User> {
    return this.getUserDetails(true, false, false);
  }

  getUserDetails(force = false, forceBroadcast = false, withNavigate = true): Observable<User> {
    const user = this.getCachedUser();
    if (!Utils.isNullOrUndefined(user) && !force) {
      this.bootstrapService.initAfterUserLoggedIn();
      return new Observable<User>((observer) => {
        if (forceBroadcast) {
          this.userSubject.next(this.user);
        }
        observer.next(user);
        observer.complete();
      });
    }

    return this.userGatewayService.getUser().pipe(
      filter((res) => {
        this.user = res;
        if (this.user.deleted === undefined || this.user.deleted === null) {
          return true;
        } else {
          this.notificationService.error(
            'Your Syncee account has been deleted. If you need help in restoring your account or have any questions, please contact us via support@syncee.co.'
          );
          this.store.dispatch(new LogoutAction());
          return false;
        }
      }),
      map((res) => {
        this.user = res;
        this.bootstrapService.initAfterUserLoggedIn();
        localStorage.setItem('currentUser', JSON.stringify(res));
        if (this.user.role === USER_ROLES.GLOBAL_ADMIN) {
          localStorage.setItem('actualRole', '2');
        } else {
          localStorage.setItem('actualRole', this.user.role.toString());
        }
        this.initAppService.init(this.user, withNavigate);
        this.userSubject.next(this.user);
        return this.user;
      })
    );
  }

  getActualRole(): any {
    return localStorage.getItem('actualRole');
  }

  getCachedUser(): User {
    const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser;
  }

  isAdmin(): boolean {
    return this.getCachedUser().role === 3;
  }

  getLoggedUser(): User {
    const currentUser: User = JSON.parse(localStorage.getItem('userDetails'));
    console.log(localStorage.getItem('userDetails'));
    return currentUser;
  }

  public getAlterUser(): Observable<AlterUserVo[]> {
    return this.restService.get(`UserService/getAlterUser`).pipe(map((response) => response.getData()));
  }

  public saveKeyPairs(saveKeyPairsVO: any): Observable<any> {
    return this.restService
      .post(`EcomService/updateShopKeys`, saveKeyPairsVO)
      .pipe(map((response) => response.getFirstData()));
  }

  public getHistoryOfUserTasks(type: TaskTypes): Observable<any> {
    return this.restService.post(`TaskService/getHistoryOfUserTasks`, type).pipe(map((response) => response.getData()));
  }

  public getOrderBillingAndShippingInformation(ecomId: number = null): Observable<ShippingAndBillingInfo[]> {
    return this.restService
      .get(`CentralOrderManagementService/getShippingAndBillingInfo${ecomId ? '?ecomId=' + ecomId : ''}`)
      .pipe(map((response) => response.getData()));
  }

  public saveOrderBillingAndShippingInformation(data): Observable<any> {
    return this.restService
      .post(`CentralOrderManagementService/saveShippingAndBillingInfo`, data)
      .pipe(map((response) => response.getData()));
  }

  public saveOrderSettings(data): Observable<any> {
    return this.restService
      .post(`CentralOrderManagementService/saveOrderSettings`, data)
      .pipe(map((response) => response.getData()));
  }

  public deleteUser(): Observable<any> {
    return this.restService.post(`UserService/deleteUser`, null).pipe(
      map((response) => {
        response.getData();
      })
    );
  }

  public changeRole(post): Observable<any> {
    return this.restService.post(`UserService/changeRole`, post).pipe(
      map((response) => {
        response.getData();
      })
    );
  }

  public affiliatePartner(): Observable<any> {
    return this.restService.post('AffiliateService/addPromoter', {});
  }

  public postError(post: ErrorVO): Observable<any> {
    return this.restService.post(`ErrorService/add`, post).pipe(
      map((response) => {
        response.getData();
      })
    );
  }

  public affiliateSignUp(ecomId: string, tid: string): Observable<any> {
    const params = {
      ecomId: ecomId,
      tid: tid,
    };

    return this.restService.post(`AffiliateService/signUp`, params).pipe(
      map((response) => {
        response.getData();
      })
    );
  }

  private handleIfUserIsDeleted(): void {}
}

export class ErrorVO {
  code: string;
  message: any;
  requestParams: any;
  userMessage?: string;
}

export interface ShippingAndBillingInfo {
  alibabaOrderSettings: number;
  billingData: BillingInfo;
  ecomId: number;
  orderSyncType: number;
  shippingData: ShippingInfo;
}

interface ShippingInfo {
  address1: string;
  address2: string;
  city: string;
  companyName: string;
  country: string;
  defaultAddress: boolean;
  ecom_id: string;
  id: string;
  phone: string;
  province: string;
  zip: string;
}

interface BillingInfo {
  address1: string;
  address2: string;
  city: string;
  companyName: string;
  country: string;
  euVat: string;
  province: string;
  tax: string;
  zip: string;
}
