<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column" fxLayoutGap="4px">
    <div>{{ 'PRODUCT_DETAILS.PRODUCT_CATEGORY' | translate }}: {{ details.category }}</div>
    <div *ngIf="details.brand">{{ 'PRODUCT_DETAILS.BRAND' | translate }}: {{ details.brand }}</div>
    <div *ngIf="details.tags">{{ 'PRODUCT_DETAILS.TAGS' | translate }}: {{ details.tags }}</div>
    <div *ngIf="details.language">
      {{ 'PRODUCT_DETAILS.LANGUAGE' | translate }}:
      {{ 'GENERAL.LANGUAGES.' + details.language.toUpperCase() | translate }}
    </div>
    <div *ngIf="details.approval">
      {{ 'PRODUCT_DETAILS.APPROVE_NEEDED' | translate }}: {{ 'PRODUCT_DETAILS.ASK_SUPPLIER' | translate }}
    </div>
  </div>
  <div fxLayout="column" fxLayoutGap="8px">
    <div fxLayout="column" fxLayoutGap="4px">
      <div>Product variants:</div>
      <mat-divider></mat-divider>
      <div class="variant-columns-container" fxLayout="row">
        <div class="product-variant-column sku">
          <h5>SKU</h5>
          <ng-container *ngFor="let variant of details.variants">
            <div>{{ variant.SKU }}</div>
          </ng-container>
        </div>
        <ng-container *ngFor="let optionColumn of variantOptionColumns">
          <div class="product-variant-column">
            <h5>{{ optionColumn.title }}</h5>
            <ng-container *ngFor="let valueField of optionColumn.valueFields">
              <div>{{ valueField.value }}</div>
            </ng-container>
          </div>
        </ng-container>
        <div class="product-variant-column price">
          <h5>{{ 'PRODUCT_DETAILS.PRICE' | translate }}</h5>
          <ng-container *ngFor="let variant of details.variants">
            <div>
              {{
                getPrice(variant)
                  | currencyExchangeTo : orderInfoData.supplierCurrency : details.ecomCurrency
                  | async
                  | currencyTransform : details.ecomCurrency
                  | async
              }}
            </div>
          </ng-container>
        </div>
        <div class="product-variant-column price">
          <h5>{{ 'PRODUCT_DETAILS.QUANTITY' | translate }}</h5>
          <ng-container *ngFor="let variant of details.variants">
            <div>
              {{
                isNullOrUndefined(variant.QTY)
                  ? ('PRODUCT_DETAILS.MANY_IN_STOCK' | translate)
                  : variant.QTY === 0
                  ? ('PRODUCT_DETAILS.OUT_OF_STOCK' | translate)
                  : variant.QTY === 1
                  ? ('PRODUCT_DETAILS.ONE_PIECE_LEFT' | translate)
                  : ('PRODUCT_DETAILS.PIECES_LEFT' | translate : { number: variant.QTY })
              }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</div>
