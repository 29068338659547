import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CategoryState } from '../../../store/category/category.reducer';
import { select, Store } from '@ngrx/store';
import { BootstrapService } from '../../../service/bootstrap/bootstrap.service';
import { ProductSearchState } from '../../../store/product-search/product-search.reducer';
import { CategoryService } from '../../../service/product-search/category.service';
import { AppState } from '../../../app.state';
import { AddFilterWithRedirectAction, SetHelperAction } from '../../../store/product-search/product-search.action';
import {
  MarketplaceFilter,
  MarketplaceFilterSort,
  ProductSearchSortField,
  ProductSearchSortOrder,
} from '../../../vo/search-product-vo';
import { debounce } from 'lodash';
import { Cancelable, Utils } from '../../../utils/utils';
import { CategoryVo } from '../../../vo/category-vo';
import { TranslateService } from '@ngx-translate/core';
import { FilterSelectorGtmService } from '../../../main/marketplace/marketplace-shared-components/service/filter-selector-gtm-service';

@Component({
  selector: 'app-category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.scss'],
})
export class CategorySelectorComponent implements AfterViewInit, OnDestroy {
  @Input() needRedirect: boolean;

  categories: CategoryVo;
  hoveredMainCategory: CategoryVo = null;
  tabSelectedIndex = 0;
  debouncedHandleMainCategoriesMouseEnter: (() => void) & Cancelable;

  private readonly currentLanguage: string;
  private categoriesStore$: Observable<CategoryState>;
  private productSearchStore$: Observable<ProductSearchState>;
  private unsubscribeAll: Subject<void>;
  private selectedFilterCategoryId: number;
  private selectedSort: MarketplaceFilterSort;
  private isMainCategorySelectorHovered = false;
  private isSubmenuOverlayHovered = false;

  constructor(
    private categoryService: CategoryService,
    private store: Store<AppState>,
    private bootstrapService: BootstrapService,
    private translateService: TranslateService,
    private gtmFilterService: FilterSelectorGtmService
  ) {
    this.categoriesStore$ = this.store.pipe(select((state) => state.categories));
    this.productSearchStore$ = this.store.pipe(select((state) => state.productSearch));
    this.unsubscribeAll = new Subject<void>();
    this.debouncedHandleMainCategoriesMouseEnter = debounce(this.handleMainCategoriesMouseEnter, 200);
    this.currentLanguage = this.translateService.currentLang;
  }

  ngAfterViewInit(): void {
    this.subscribeToBootstrapCategories();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  handleCategoryHovered(id: number): void {
    this.isMainCategorySelectorHovered = true;
    this.hoveredMainCategory = this.categories.children.find((category) => category.id === id);
    this.setTabIndex(this.hoveredMainCategory);
  }

  handleMainCategoryClicked(category: CategoryVo): void {
    if (this.selectedFilterCategoryId !== Number(category.id)) {
      this.store.dispatch(new SetHelperAction({ category: category }));
      this.gtmFilterService.setFilter('category', category);
      this.emitFilterSelected({
        category: Number(category.id),
        sortField: null,
        sortOrder: null,
      });
    }
  }

  onAllProductsClick(): void {
    if (
      this.selectedFilterCategoryId !== 1 ||
      (!!this.selectedSort && this.selectedSort.sortField === 'CREATED' && this.selectedSort.sortOrder === 'Desc')
    ) {
      this.store.dispatch(new SetHelperAction({ category: this.categories }));
      this.emitFilterSelected({
        category: 1,
        sortField: null,
        sortOrder: null,
      });
    }
    this.tabSelectedIndex = 2;
  }

  onAllProductsHover(): void {
    this.isMainCategorySelectorHovered = false;
    this.tabSelectedIndex = 2;
    this.hoveredMainCategory = null;
  }

  onNewProductsClick(): void {
    if (
      this.selectedFilterCategoryId !== 1 ||
      !this.selectedSort ||
      (this.selectedSort.sortField !== 'CREATED' && this.selectedSort.sortOrder !== 'Desc')
    ) {
      this.emitFilterSelected({
        sortField: ProductSearchSortField.CREATED,
        sortOrder: ProductSearchSortOrder.DESC,
        category: 1,
      });
    }
    this.tabSelectedIndex = 1;
  }

  onNewProductsHover(): void {
    this.isMainCategorySelectorHovered = false;
    this.tabSelectedIndex = 1;
    this.hoveredMainCategory = null;
  }

  handleMainCategoriesMouseEnter(): void {
    this.isMainCategorySelectorHovered = true;
  }

  handleMainCategoriesMouseLeave(): void {
    this.debouncedHandleMainCategoriesMouseEnter.cancel();
    this.isMainCategorySelectorHovered = false;
    this.setSelectedLevel1Category();
  }

  handleSubmenuOverlayMouseLeave(): void {
    this.isSubmenuOverlayHovered = false;
    this.setSelectedLevel1Category();
  }

  handleSubmenuOverlayMouseEnter(): void {
    this.isSubmenuOverlayHovered = true;
    this.setTabIndex(this.categories.children.find((category) => category.id === this.hoveredMainCategory.id));
  }

  emitFilterSelected(filter: MarketplaceFilter): void {
    this.store.dispatch(new AddFilterWithRedirectAction({ filter: filter, needRedirect: this.needRedirect }));
    this.isSubmenuOverlayHovered = false;
    this.isMainCategorySelectorHovered = false;
  }

  private setTabIndex(category: CategoryVo): void {
    this.tabSelectedIndex = this.categories.children.findIndex((cat) => cat.id === category.id) + 3;
  }

  private subscribeToProductSearchState(): void {
    this.productSearchStore$
      .pipe(
        select((state) => state.filter),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe((filters: MarketplaceFilter) => {
        if (!Utils.isNullOrUndefined(filters) && !Utils.isNullOrUndefined(filters.category)) {
          this.selectedFilterCategoryId = Number(filters.category);
          this.setSelectedLevel1Category();
        } else {
          this.selectedFilterCategoryId = null;
          this.tabSelectedIndex = null;
        }
        this.selectedSort = {
          sortField: filters?.sortField,
          sortOrder: filters?.sortOrder,
        };
      });
  }

  private getCategories(): void {
    this.categoriesStore$
      .pipe(select((state) => state.categories))
      .pipe(take(1))
      .subscribe((categories: CategoryVo) => {
        this.categories = { ...categories };
      });
  }

  private setSelectedLevel1Category(): void {
    if (Utils.isNullOrUndefined(this.selectedFilterCategoryId)) {
      this.tabSelectedIndex = null;
      return;
    }
    const selectedCategory = this.categoryService.searchInCategories(this.categories, this.selectedFilterCategoryId);
    if (Utils.isNullOrUndefined(selectedCategory)) {
      this.tabSelectedIndex = null;
    } else if (
      selectedCategory.id === 1 &&
      this.selectedSort &&
      this.selectedSort.sortField === 'CREATED' &&
      this.selectedSort.sortOrder === ProductSearchSortOrder.DESC
    ) {
      this.tabSelectedIndex = 1;
    } else if (selectedCategory.id === 1) {
      this.tabSelectedIndex = 2;
    } else {
      this.setTabIndex(this.getLevel1Category(selectedCategory));
    }
  }

  private getLevel1Category(category: CategoryVo): CategoryVo {
    if (category.parent.toString() === '1' || category.id === 1) {
      return category;
    } else {
      return this.getLevel1Category(this.categoryService.searchInCategories(this.categories, Number(category.parent)));
    }
  }

  private subscribeToBootstrapCategories(): void {
    this.bootstrapService.categoriesStored.pipe(takeUntil(this.unsubscribeAll)).subscribe((isStored) => {
      if (isStored) {
        this.getCategories();
        this.subscribeToProductSearchState();
      }
    });
  }

  get isSubmenuOverlayOpen(): boolean {
    return this.isSubmenuOverlayHovered || this.isMainCategorySelectorHovered;
  }
}
