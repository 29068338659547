<div
  class="container"
  (mouseenter)="handleMouseEnter()"
  (mouseleave)="handleMouseLeave()"
  (click)="handleClick($event)"
>
  <div class="content">
    <ng-content></ng-content>
  </div>
  <div class="before-icon" *ngIf="!!beforeIcon">
    <ng-container *ngTemplateOutlet="beforeIcon"></ng-container>
  </div>
  <mat-icon class="s-20 hover-icon" *ngIf="show; else empty" @fadeInOut>
    {{ icon }}
  </mat-icon>
</div>

<ng-template #empty>
  <span class="empty"></span>
</ng-template>
