import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { WizardCategoryMappingListService } from './service/wizard-category-mapping-list.service';
import {
  WizardCategoryHeaderService,
  WizardCategoryMappingTitleConfig,
} from './service/wizard-category-header.service';
import { CategoryData, CategoryMappingData, CategoryMappingPageable } from './model/category-mapping-data';
import { PaginateWithSubject } from '../../../utils/paginate-with-subject';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RemoveMappingData } from './model/remove-mapping-data';

@Component({
  selector: 'app-wizard-category-mapping',
  templateUrl: './wizard-category-mapping.component.html',
  styleUrls: ['./wizard-category-mapping.component.scss'],
  providers: [WizardCategoryMappingListService, WizardCategoryHeaderService],
})
export class WizardCategoryMappingComponent implements OnInit, OnChanges {
  @Input() categoryItems: CategoryMappingData[];
  @Input() customCategoriesTitle: string;
  @Input() customCategoriesTooltip: string;
  @Input() originalCategoriesTitle: string;
  @Input() originalCategoriesTooltip: string;
  @Output() addSingleMapping = new EventEmitter<CategoryMappingData>();
  @Output() showProducts = new EventEmitter<CategoryData>();
  @Output() addSelectedMapping = new EventEmitter<CategoryMappingData[]>();
  @Output() removeSingleMapping = new EventEmitter<RemoveMappingData>();
  @Output() removeInBulk = new EventEmitter<CategoryMappingData[]>();
  @Output() clearSelectedMapping = new EventEmitter<string[]>();
  public pageSizeOptions: number[] = [10, 25, 50, 100];
  public paginator: PaginateWithSubject;
  public displayedItems$: Observable<CategoryMappingPageable>;

  constructor(public headerService: WizardCategoryHeaderService, public listService: WizardCategoryMappingListService) {
    this.paginator = new PaginateWithSubject(0, 10);
    this.displayedItems$ = this.getItems();
  }

  ngOnInit(): void {
    this.subscribeToBulkAddClick();
    this.subscribeToBulkRemoveClick();
    this.subscribeToClearSelectedClick();
    this.subScribeToBulkEditOff();
    this.setTitleConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.categoryItems.firstChange) {
      this.listService.setItems(this.categoryItems);
    }
  }

  private getItems(): Observable<CategoryMappingPageable> {
    return combineLatest([
      this.headerService.categoryFilter,
      this.headerService.mapToSearchTerm,
      this.headerService.mappedSearchTerm,
      this.paginator.pagination,
      this.listService.allItems,
    ]).pipe(
      map(([categoryFilter, mapToTerm, mappedTerm, pagination, items]) => {
        return this.listService.filterAndPaginate(
          items,
          mapToTerm,
          mappedTerm,
          categoryFilter,
          pagination.page,
          pagination.size
        );
      }),
      tap((result) => {
        this.paginator.allItemsCount = result.allItemsCount;
      })
    );
  }

  subscribeToBulkAddClick(): void {
    this.headerService.addInBulkClicked.subscribe(() => {
      this.addSelectedMapping.emit(this.listService.checkedItems);
    });
  }

  subscribeToBulkRemoveClick(): void {
    this.headerService.removeInBulkClicked.subscribe(() => {
      this.removeInBulk.emit(this.listService.checkedItems);
    });
  }

  subScribeToBulkEditOff(): void {
    this.headerService.isBulkEditOn.subscribe((value) => {
      if (value === false) {
        this.listService.clearSelection();
      }
    });
  }

  subscribeToClearSelectedClick(): void {
    this.headerService.clearSelectedClicked.subscribe(() => {
      const selectedCategoryIds = this.listService.checkedItems.map((item) => item.id);
      this.clearSelectedMapping.emit(selectedCategoryIds);
    });
  }

  private setTitleConfig(): void {
    const baseConfig = { ...this.headerService.titleConfig };
    const config: WizardCategoryMappingTitleConfig = {
      originalCategoriesTooltip: this.originalCategoriesTooltip ?? baseConfig.originalCategoriesTooltip,
      originalCategoriesTitle: this.originalCategoriesTitle ?? baseConfig.originalCategoriesTitle,
      customCategoriesTooltip: this.customCategoriesTooltip ?? baseConfig.customCategoriesTooltip,
      customCategoriesTitle: this.customCategoriesTitle ?? baseConfig.customCategoriesTitle,
    };
    this.headerService.setTitleConfig(config);
  }

  get bulkState(): Observable<boolean> {
    return this.headerService.isBulkEditOn;
  }
}
