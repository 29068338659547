import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaskVoExtended } from '../tasks.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { Observable } from 'rxjs';
import { EcomVO } from '../../../service/ecom/ecom.service';
import { retailerEcomsSelector, supplierEcomsSelector } from '../../../store/ecom/ecom.selector';
import { map } from 'rxjs/operators';
import { TaskMenuItemClickedEvent } from '../tasks-menu/tasks-menu.component';

import { ROLE_TYPE, RolesEnum } from '../../../vo/roles/roles';

@Component({
  selector: 'app-tasks-mobile-list-item',
  templateUrl: './tasks-mobile-list-item.component.html',
  styleUrls: ['./tasks-mobile-list-item.component.scss'],
})
export class TasksMobileListItemComponent implements OnInit {
  @Input() role: ROLE_TYPE;
  @Input() task: TaskVoExtended;
  @Input() menus: any[] = [];
  @Output() syncClicked = new EventEmitter<void>();
  @Output() menuItemClicked = new EventEmitter<TaskMenuItemClickedEvent>();
  ecom: Observable<EcomVO>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.ecom = this.store
      .select(this.role === RolesEnum.RETAILER ? retailerEcomsSelector : supplierEcomsSelector)
      .pipe(map((ecoms) => ecoms.find((ecom) => ecom.id === this.task.ecomId)));
  }
}
