import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AppState } from 'app/app.state';
import { SuppliersService } from 'app/service/suppliers/suppliers.service';
import { getCurrentUserIdSelector } from 'app/store/user/user.selector';
import { switchMap } from 'rxjs';
import { SUPPLIER_PAYMENT_CURRENCIES } from './supplier-currency-constants';
import {
  SupplierCurrencySelectorConfirmDialogComponent,
  SupplierCurrencySelectorConfirmDialogData,
} from './supplier-currency-selector-confirm-dialog/supplier-currency-selector-confirm-dialog.component';

@Component({
  selector: 'app-supplier-currency-selector',
  templateUrl: './supplier-currency-selector.component.html',
  styleUrls: ['./supplier-currency-selector.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    FlexLayoutModule,
    TranslateModule,
  ],
  standalone: true,
})
export class SupplierCurrencySelectorComponent implements OnInit {
  @Input() confirmation = false;
  @Input() currency = 'USD';
  @Output() currencyChange: EventEmitter<string> = new EventEmitter<string>();

  currencies = SUPPLIER_PAYMENT_CURRENCIES;

  constructor(
    private _supplierService: SuppliersService,
    private _store: Store<AppState>,
    private dialogService: MatDialog,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._initSupplierCurrency();
  }

  private _initSupplierCurrency(): void {
    this._store
      .select(getCurrentUserIdSelector)
      .pipe(
        switchMap((userId: number) => {
          return this._supplierService.getSupplierData(userId);
        })
      )
      .subscribe(({ paymentCurrency }) => {
        this.currency = paymentCurrency;
      });
  }

  onChangeHandler(currency): void {
    if (!this.confirmation) {
      this.currency = currency;
      this._emitCurrencyChange();
      return;
    }

    this.dialogService
      .open<SupplierCurrencySelectorConfirmDialogComponent, SupplierCurrencySelectorConfirmDialogData>(
        SupplierCurrencySelectorConfirmDialogComponent,
        {
          data: { currency: currency } as SupplierCurrencySelectorConfirmDialogData,
          width: '600px',
        }
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.currency = currency;
          this._emitCurrencyChange();
        } else {
          this._resetCurrency();
        }
      });
  }

  private _resetCurrency(): void {
    const tempCurrency = this.currency;
    this.currency = null;
    this._changeDetector.detectChanges();
    this.currency = tempCurrency;
  }

  private _emitCurrencyChange(): void {
    this.currencyChange.emit(this.currency);
  }
}
